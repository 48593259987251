/* Libraries */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
/* Material UI */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
/* Components */
import FieldsComponent from './fieldsComponent';
import { LineContainer, ItemContainer } from '../styled';
/* CSS */
import styles from '../../forecast_forms-jss';
import fontStyles from 'styles/fonts-jss';
/* Context */
import { useModal } from 'Context/ModalContext';
import { getDailyReportCard, get12hReportCard } from 'services/mapsPortal/alerts';
import Loading from 'components/Loading';
import { useToast } from 'Context/ToastContext';
import 'moment/locale/pt-br'
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    divider: {
        background: '#848484',
        border: '1px solid',
    },
});

const ReportCard = (props) => {
    const fonts = fontStyles();
    const dividerStyle = useStyles();
    const moment = require('moment');
    moment.locale('pt-br');
    const {
        classes,
        alertsData,
        setAlertsData,
        setReloadSwitchSelected,
        allSelected,
        setAllSelected,
        setReloadErrorMessage,
        setStatusTargetSelected,
        statusTargetSelected,
        setReloadStatusTargetSelected,
        reloadTargets,
        setReloadTargets,
        reportCardErrors,
        setReportCardErrors
    } = props;
    const [targets, setTargets] = useState();
    const [reloadModal, setReloadModal] = useState(false);
    const [loadingDailyReportCard, setLoadingDailyReportCard] = useState(true);
    const [targetReportList, setTargetReportList] = useState();
    const [reloadData, setReloadData] = useState(false);
    const [reloadCheckbox, setReloadCheckbox] = useState(false);
    const [caractersRemaning, setCaractersRemaning] = useState(0);
    const { plans: planos, toShow } = useModal();
    const [allSelectedAux, setAllSelectedAux] = useState(false);
    const [dailyReportCard, setDailyReportCard] = useState([]);
    const [reloadHorizon, setReloadHorizon] = useState(false);
    const ref = useRef(null);
    const openToast = useToast();
    const reportCardContext = useSelector((state) => state.reportCardContextProvider);
    const [selectedHorizon, setSelectedHorizon] = useState(reportCardContext === null? moment().isBefore(moment("16:00", "HH:mm"))? "24 horas" : "12 horas" :  reportCardContext[1] + " horas");
    const [selectedStatus, setSelectedStatus] = useState(reportCardContext === null? "Não aprovados" :  reportCardContext[2] === 0? "Não aprovados" : "Aprovados");
    const [statusValue, setStatusValue] = useState(reportCardContext === null? 0 :  reportCardContext[2] === 0? 0 : 1);

    const [weatherCondition, setWeatherCondition] = useState([
        "Em branco",
        "Sem previsão de chuva",
        "Chuva no período da manhã",
        "Chuva no período da tarde",
        "Chuva no período da noite",
        "Chuva ao longo do dia",
        "Pancadas de chuva ao longo do dia"
    ]);
    const [windDirection, setWindDirection] = useState([
        'Em branco',
        'De Sul',
        'De Leste',
        'De Norte',
        'De Oeste',
        'De Sudeste',
        'De Sudoeste',
        'De Nordeste',
        'De Noroeste',
    ]); 
    const [windSprint, setWindSprint] = useState([
        'Em branco',
        'Sem informação',
        'Calmo, rajadas entre 0.01 km/h e 1.0 km/h',
        'Aragem, rajadas entre 1.01 km/h e 6.0 km/h ',
        'Brisa leve, rajadas entre 6.01 km/h e 12.0 km/h',
        'Brisa fraca, rajadas entre 12.01 km/h e 20.0 km/h',
        'Brisa moderada, rajadas entre 20.01 km/h e 29.0 km/h',
        'Brisa forte, rajadas entre 29.01 km/h e 39.0 km/h',
        'Vento fresco, rajadas entre 39.01 km/h e 50.0 km/h',
        'Vento forte, rajadas entre 50.01 km/h e 62.0 km/h',
        'Ventania, rajadas entre 62.01 km/h e 75.0 km/h',
        'Ventania forte, rajadas entre 75.01 km/h e 89.0 km/h',
        'Tempestade, rajadas entre 89.01 km/h e 103.0 km/h',
        'Tempestade violenta, rajadas entre 103.01 km/h e 117.0 km/h',
        'Tornado/Furacão, rajadas entre 117.01 km/h e 999.9 km/h',
    ]); 

    var gridClasses = [
        classes.gridColumnsIndexRain,
        classes.gridMarginLeftRain,
    ];

    const [preset, setPreset] = useState({
        preset: false,
        acumulado: '',
        intensidade: '',
        condicao_tempo: '',
        probabilidade: '',
        nivel: '',
        rajada_vento: '',
        direcao_vento: '',
        velocidade_vento: '',
        temperatura_min: '',
        temperatura_max: '',
        observacao: null,
        rajada_vento_original: '',
        velocidade_vento_original: '',
    });

    const loadMorningReportCard = async () => {
        let aux = [];
            await getDailyReportCard().then((response) => {
                if(response && response.length > 0){
                    aux = response;
                }
                loadAfternoonReportCard(aux)
            }).catch(() => {
                openToast('error', 'Não foi possível obter os Boletins Diários! Recarregue a página e tente novamente.')
            });
    }

    const loadAfternoonReportCard = async (reports) => {
            await get12hReportCard().then((response) => {
                if(response && response.length > 0){
                    setDailyReportCard(reports.concat(response))
                }
            }).catch(() => {
                openToast('error', 'Não foi possível obter os Boletins Diários! Recarregue a página e tente novamente.')
            });
            setLoadingDailyReportCard(false);
            setReloadTargets(false);
    };

    useEffect(() => {
        if(dailyReportCard){
            loadMorningReportCard();
        }
    }, []);

    useEffect(() => {
        setReloadModal(false);
    }, [reloadModal]);

    useEffect(() => {
        setReloadHorizon(true);
    }, [selectedHorizon]);

    useEffect(() => {
        if(reloadHorizon){
            setReloadHorizon(false);
        }
    }, [reloadHorizon]);

    // Atualiza a contagem de caracteres restantes com base no campo de observações
    useEffect(() => {
        setCaractersRemaning(preset.observacao != null? preset.observacao.length : 0);
    }, [preset.observacao]);

    useEffect(() => {
        if(reloadTargets){
            setLoadingDailyReportCard(true);
            loadMorningReportCard();
        }
    }, [reloadTargets]);

    function isInt(n) {
        return n % 1 === 0;
    }

    function isHundred(n) {
        return n >= 100;
    }

    if(loadingDailyReportCard || reloadTargets || reloadHorizon) { return ( <Loading />) }
    return (
        <div ref={ref} className={classes.container}>
            <div className={classes.allChangeContainer}>
                <div className={classes.inputLine}>
                    <LineContainer
                        style={{
                            width: '100%',
                            margin: '0',
                            height: 'fit-content',
                        }}
                    >
                        <Autocomplete
                            id="condition"
                            freeSolo
                            value={preset?.condicao_tempo}
                            inputValue={preset?.condicao_tempo}
                            disableClearable
                            onInputChange={(_, newValue) => setPreset({ ...preset, condicao_tempo: newValue })}
                            onChange={(_, newValue) => setPreset({ ...preset, condicao_tempo: newValue })}
                            className={classes.inputOne}
                            style={fonts.formsFieldHeight1}
                            size="small"
                            options={weatherCondition}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label="Condição do tempo"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                  if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                  }
                                }} />}
                        />

                        <Autocomplete
                            id="intensity"
                            freeSolo={false}
                            value={preset?.intensidade}
                            inputValue={preset?.intensidade}
                            disableClearable
                            onChange={(_, newValue) =>
                                setPreset({ ...preset, intensidade: newValue })
                            }
                            className={classes.inputTwo}
                            size="small"
                            options={[
                                'Em branco',
                                'Fraca',
                                'Fraca a moderada',
                                'Moderada',
                                'Moderada a forte',
                                'Forte',
                                'Muito forte',
                                'Extrema',
                            ]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Intensidade da chuva"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Backspace') {
                                            event.stopPropagation();
                                        }
                                    }}
                                />
                            )}
                        />
                    </LineContainer>
                </div>

                <div className={classes.inputLine}>
                    <InputMask
                        mask="999 - 999 mm"
                        value={preset?.acumulado}
                        onChange={(e) =>
                            setPreset({
                                ...preset,
                                acumulado: e.target.value,
                            })
                        }
                        disabled={false}
                        maskChar=" "
                    >
                        {() => (
                            <TextField
                                className={classes.inputThree}
                                id="acumuladoInit"
                                variant="outlined"
                                label="Acumulado"
                                inputProps={{
                                    style: fonts.formsFieldHeight1,
                                }}
                                InputLabelProps={{
                                    style: fonts.formsLabel,
                                    shrink: true,
                                }}
                            />
                        )}
                    </InputMask>

                    <Autocomplete
                        id="lightning_prob"
                        freeSolo={false}
                        value={preset?.probabilidade}
                        inputValue={preset?.probabilidade}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({
                                ...preset,
                                probabilidade: newValue,
                            })
                        }
                        className={classes.inputFour}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={['Em branco', 'Sem tempestades', 'Baixa', 'Média', 'Alta']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Prob. Tempestade"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />

                    <Autocomplete
                        id="level"
                        freeSolo={false}
                        value={preset?.nivel}
                        inputValue={preset?.nivel}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({ ...preset, nivel: newValue })
                        }
                        className={classes.inputFive}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={['T1', 'T2', 'T3', 'T4', 'Em branco']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Nível"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />

                    <TextField
                        id="temp_min"
                        className={classes.inputSix}
                        value={preset?.temperatura_min}
                        onChange={(e) =>
                            setPreset({
                                ...preset,
                                temperatura_min: e.target.value,
                            })
                        }
                        variant="outlined"
                        type="tel"
                        label="Temp. Mínima"
                        inputProps={{ style: fonts.formsFieldHeight1 }}
                        InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{ alignSelf: 'end' }}
                                >
                                    °C
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        id="temperatura_max"
                        className={classes.inputSeven}
                        variant="outlined"
                        value={preset?.temperatura_max}
                        onChange={(e) =>
                            setPreset({
                                ...preset,
                                temperatura_max: e.target.value,
                            })
                        }
                        label="Temp. Máxima"
                        type="tel"
                        inputProps={{ style: fonts.formsFieldHeight1 }}
                        InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{ alignSelf: 'end' }}
                                >
                                    °C
                                </InputAdornment>
                            ),
                        }}
                    />  
                </div>

                <div className={classes.inputLine}>
                    <Autocomplete
                        id="wind_sprint"
                        freeSolo
                        value={preset?.rajada_vento}
                        inputValue={preset?.rajada_vento}
                        disableClearable
                        onInputChange={(_, newValue) => setPreset({ ...preset, rajada_vento: newValue })}
                        onChange={(_, newValue) => setPreset({ ...preset, rajada_vento: newValue })}
                        className={classes.inputEight}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={windSprint}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            label="Rajada de vento"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                              if (event.key === 'Backspace') {
                                event.stopPropagation();
                              }
                            }} />}
                    />

                    <InputMask
                        mask={isInt(preset?.velocidade_vento)? 
                            isHundred(preset?.velocidade_vento)? 
                                "999 km/h" :
                                "99 km/h" :
                                    isHundred(preset?.velocidade_vento)?
                                        "999.9 km/h" :
                                        "99.9 km/h"
                        }
                        value={preset?.velocidade_vento}
                        onChange={(e) =>
                            setPreset({
                                ...preset,
                                velocidade_vento: e.target.value,
                            })
                        }
                        disabled={false}
                        maskChar=" "
                    >
                        {() => (
                            <TextField
                                id="velocidade_vento"
                                className={classes.inputNine}
                                variant="outlined"
                                label="Vel. do vento"
                                inputProps={{ style: fonts.formsFieldHeight1 }}
                                InputLabelProps={{
                                    style: fonts.formsLabel,
                                    shrink: true,
                                }}
                            />
                        )}
                    </InputMask>

                    <Autocomplete
                        id="wind"
                        freeSolo
                        value={preset?.direcao_vento}
                        inputValue={preset?.direcao_vento}
                        disableClearable
                        onInputChange={(_, newValue) => setPreset({ ...preset, direcao_vento: newValue })}
                        onChange={(_, newValue) => setPreset({ ...preset, direcao_vento: newValue })}
                        className={classes.inputTen}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={windDirection}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            label="Direção do vento"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                              if (event.key === 'Backspace') {
                                event.stopPropagation();
                              }
                            }} />}
                    />
                </div>
                        
                <div className={classes.inputLine}>
                    <TextField
                        id="comments"
                        label="Observações"
                        multiline
                        rows={2}
                        value={preset?.observacao}
                        onChange={(e) =>
                            setPreset({ ...preset, observacao: e.target.value })
                        }
                        className={classes.inputElevenMargin}
                        shrink={true}
                        variant="outlined"
                        color="primary"
                        inputProps={{
                            style: fonts.formsFieldHeight2,
                            maxLength: 200,
                        }}
                        InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                        InputProps={{
                            endAdornment:
                              <span className={classes.endAdornment}>{caractersRemaning + "/200"}</span>
                        }}
                    />

                    <Button
                        style={fonts.buttonDefault}
                        variant="contained"
                        className={classes.reportButton}
                        onClick={() =>
                            setPreset({ ...preset, preset: !preset?.preset })
                        }
                    >
                        Alterar todos
                    </Button>
                </div>

                <Divider
                    classes={{ root: dividerStyle.divider }}
                    style={{ margin: '0', padding: '0' }}
                />

                <div className={classes.inputLine}>
                    <Autocomplete
                        id="Horizontes"
                        freeSolo={false}
                        value={selectedHorizon}
                        inputValue={selectedHorizon}
                        disableClearable
                        onChange={(_, newValue) =>
                            setSelectedHorizon(newValue)
                        }
                        style={{marginTop: "25px"}}
                        className={classes.inputFour}
                        size="small"
                        options={["12 horas", "24 horas", "48 horas", "72 horas"]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Horizonte"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />

                    <Autocomplete
                        id="Status"
                        freeSolo={false}
                        value={selectedStatus}
                        inputValue={selectedStatus}
                        disableClearable
                        onChange={(_, newValue) => {
                            if(newValue === "Não aprovados"){
                                setSelectedStatus(newValue);
                                setStatusValue(0);
                            }
                            else{
                                setSelectedStatus(newValue);
                                setStatusValue(1);
                            }
                        }}
                        style={{marginTop: "25px"}}
                        className={classes.inputFour}
                        size="small"
                        options={["Não aprovados", "Aprovados"]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </div>
            </div>

            {!reloadTargets && planos?.map((company) =>
                company.planos.map((plan) => {
                    return plan.alvos.map((target) => {
                        if (!toShow.get(company.id, plan.id, target.id)){
                            return null;
                        }
                        else{
                            const aux = dailyReportCard.filter((report) => target.id_alvo === report.id_alvo);
                            return aux.map((report, index) => {
                                /*if(report.nome_alvo === "Alvo Uruguai"){
                                    //report.id_boletim == "76f347dc-0e36-473b-b317-cc2e723cb07b"
                                    console.log(report.horizontes === parseInt(selectedHorizon.slice(0, 2)), report.horizontes, parseInt(selectedHorizon.slice(0, 2)))
                                    console.log(report.status == statusValue, report.status, statusValue)
                                    console.log(reportCardContext, report.id_boletim)
                                    console.log(report)
                                }*/
                              
                                if(report.horizontes === parseInt(selectedHorizon.slice(0, 2)) && (report.status == statusValue) && (reportCardContext === null || reportCardContext[0].indexOf(report.id_boletim) != -1)){
                                    return (
                                        <div key={index}>
                                            <FieldsComponent
                                                target={target}
                                                preset={preset}
                                                alertsData={alertsData}
                                                setAlertsData={setAlertsData}
                                                planoId={plan.id_plano}
                                                empresaId={company.id_empresa_id}
                                                empresaNome={company.nome}
                                                planoNome={plan.nome}
                                                alvoNome={target.nome}
                                                setReloadModal={setReloadModal}
                                                setReloadSwitchSelected={
                                                    setReloadSwitchSelected
                                                }
                                                allSelected={allSelected}
                                                setAllSelected={setAllSelected}
                                                allSelectedAux={allSelectedAux}
                                                setAllSelectedAux={setAllSelectedAux}
                                                setReloadErrorMessage={
                                                    setReloadErrorMessage
                                                }
                                                setStatusTargetSelected={
                                                    setStatusTargetSelected
                                                }
                                                statusTargetSelected={statusTargetSelected}
                                                setReloadStatusTargetSelected={
                                                    setReloadStatusTargetSelected
                                                }
                                                reloadTargets={reloadTargets}
                                                setReloadTargets={setReloadTargets}
                                                reportCardErrors={reportCardErrors}
                                                setReportCardErrors={setReportCardErrors}
                                                reportCardData={report}
                                                selectedHorizon={selectedHorizon}
                                                loadedReportCard={reportCardContext? reportCardContext[0].indexOf(report.id_boletim) != -1 : selectedStatus == "Aprovados"? true : false}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    });
                }),
            )}
        </div>
    );
};

ReportCard.propTypes = {
    classes: PropTypes.object.isRequired,
    alertsData: PropTypes.any,
    setAlertsData: PropTypes.func.isRequired,
    setReloadPostData: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    setReloadSelected: PropTypes.func.isRequired,
    setCheckboxSelected: PropTypes.func.isRequired,
    checkboxSelected: PropTypes.object.isRequired,
    setReloadSwitchSelected: PropTypes.func.isRequired,
    allSelected: PropTypes.any,
    setAllSelected: PropTypes.func.isRequired,
    statusTargetSelected: PropTypes.any.isRequired,
    setStatusTargetSelected: PropTypes.func.isRequired,
    setReloadStatusTargetSelected: PropTypes.func.isRequired,
    loadingLeftSide: PropTypes.any.isRequired,
    reloadTargets: PropTypes.any.isRequired,
    setReloadTargets: PropTypes.func.isRequired,
    reportCardErrors: PropTypes.any.isRequired,
    setReportCardErrors: PropTypes.func.isRequired,
    setReloadErrorMessage: PropTypes.func.isRequired
}

export default withStyles(styles)(ReportCard);
