const fontFamily = ['Segoe UI', 'Roboto', 'sans-serif', 'El Messiri'];

const colors = {
    primary: '#1C1B1F',
    white: 'white',
    textBlack: '#1B1B1B',
    textGrey: '#787878',
    textLightGrey: '#3C3C3C',
    mediumGrey: '#848484',
    cellContent: '#4a4a4a',
    textRed: 'red',
    textLightPurple: '#B067E9',
    inputGrey: '#787878',
    rainAlertBlue: '#046DCA',
    weatherColor: '#000000',
    reportCardColor: '#C0A514',
    strongYellow: '#FFB703',
    orange: '#DF7600',
    link: "#0000EE"
};

const fontStyles = () => ({
    /**** Header ****/
    headerTitle: {
        fontFamily: fontFamily,
        fontSize: '24px',
        fontWeight: '700',
        color: colors.white,
    },
    /**** General ****/
    buttonDefault: {
        fontFamily: fontFamily,
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '16.41px',
    },
    buttonMini: {
        fontFamily: fontFamily,
        fontSize: '10px',
        fontWeight: '700',
    },
    noDataText: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textLightGrey,
        fontWeight: '600',
    },
    /* Toast */
    toastMessage: {
        fontFamily: fontFamily,
        fontSize: '16px',
        fontWeight: '500',
        color: colors.white,
    },
    /**** Login Page ****/
    loginTitle: {
        fontFamily: fontFamily,
        fontWeight: '700',
        textAlign: 'center',
        color: colors.white,
    },
    loginSubtitle: {
        fontFamily: fontFamily,
        fontWeight: '700',
        textAlign: 'center',
        color: colors.white,
    },
    buttonRegister: {
        fontSize: '14px',
        fontFamily: fontFamily,
        fontWeight: '500',
        color: colors.textGrey,
    },
    loginFormsSubtitle: {
        fontFamily: fontFamily,
        fontWeight: '500',
        color: colors.textGrey,
    },
    loginStayConnected: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        width: '100%',
        justifyContent: 'center',
    },
    /**** ADM Portal ****/
    /* Home */
    homeTitle: {
        fontFamily: fontFamily,
        fontSize: '40px',
        fontWeight: '700',
        width: '80%',
        textAlign: 'center',
        textShadow: '0px 8px 8px rgba(0, 0, 0, 0.5)',
        lineHeight: '45px',
        color: colors.white,
    },
    optionTitle: {
        fontFamily: fontFamily,
        width: '127px',
        fontWeight: '700',
        fontSize: '20px',
        textAlign: 'center',
        color:'#023047',
        paddingTop:'10px',
        marginBottom: '3px',
    },
    divOptionTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        width: '238px',
        borderBottom:'2px solid #DF7600',
    },
    divOptionDescription: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        marginTop: '19px',
        fontSize:'18px'
        
    },
    optionDescription: {
        width: '80%',
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '18px',
        textAlign: 'center',
        color:'#023047'
    },
    chips: {
        fontFamily: fontFamily,
        fontWeight: '500',
        fontSize: '12px',
    },
    /*PapperBlock*/
    progressMenuText: {
        fontSize: '16px',
        fontFamily: fontFamily,
        lineHeight: '16.41px',
        fontWeight: '400',
    },
    /*LeftSidebar*/
    nucleoTitle: {
        fontSize: '15px',
        fontFamily: fontFamily,
        textTransform: 'uppercase',
        display: 'block',
        lineHeight: '28px',
        fontWeight: '700',
        color: colors.white,
        marginTop: '5px',
        paddingLeft: '6px',
    },
    title: {
        fontSize: '10px',
        fontFamily: fontFamily,
        textTransform: 'uppercase',
        marginTop: '5px',
        paddingLeft: 0,
        display: 'block',
        color: colors.white,
        lineHeight: '28px',
        fontWeight: '700',
    },
    sidebarPortalButtom: {
        fontSize: '12px',
        fontFamily: fontFamily,
        paddingLeft: 0,
        textTransform: 'uppercase',
        display: 'block',
        lineHeight: '25px',
        fontWeight: '700',
    },
    sidebarItem: {
        fontSize: '10px',
        fontFamily: fontFamily,
        display: 'block',
        lineHeight: '20px',
        fontWeight: '700',
    },
    /* UserMenu */
    menuText: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '400',
    },
    /****  Page/View ****/
    cellContentTitle: {
        fontSize: '18px',
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
    },
    /*ComplexTable*/
    footerText: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
    },
    filterMenu: {
        fontSize: '16px',
        fontFamily: fontFamily,
        fontWeight: '600',
    },
    buttonText: {
        fontSize: '12px',
        fontFamily: fontFamily,
        fontWeight: '700',
    },
    cellContent: {
        fontSize: '15px',
        fontFamily: fontFamily,
        color: colors.cellContent,
        fontWeight: '600',
    },
    cellContenLink: {
        fontSize: '15px',
        fontFamily: fontFamily,
        color: colors.link,
        fontWeight: '600',
    },
    exportText: {
        fontSize: '13px',
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '600',
        textDecoration: 'none',
    },
    /**** Page/Edit ****/
    formsTitle: {
        fontSize: '22px',
        fontWeight: '600',
        color: colors.primary,
        fontFamily: fontFamily,
    },
    formsField: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
    },

    toggleGroup: {
        height: '47px',
    },

    formsFieldHeight1: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        height: '13px',
    },
    formsFieldHeight1S: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        width:"70px",
        paddingBottom: "5px", 
        height:"7px",
    
    },
    formsFieldHeight2S: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        width:"85px",
        paddingBottom: "5px", 
        height:"7px",
    
    },
    formsFieldHeight2: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        height: '17px',
    },
    formsFieldHeight3: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
    },

    formsFieldHeight4: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        height: '19px',
    },

    formsFieldHeight5: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
    },

    formsLabelWidth: {
        fontFamily: fontFamily,
        fontWeight: '400',
        alignSelf: 'center',
        width: '20px',
    },
    formsLabel: {
        fontFamily: fontFamily,
        fontWeight: '400',
    },
    formsErrorMessage: {
        fontSize: '13px',
        fontFamily: fontFamily,
        color: colors.textRed,
        fontWeight: '400',
        marginTop: '10px',
    },
    formsErrorWarning: {
        fontSize: '13px',
        fontFamily: fontFamily,
        color: "#ffa000",
        fontWeight: '400',
        marginTop: '2px',
    },
    formsErrorMessage1: {
        fontSize: '13px',
        fontFamily: fontFamily,
        color: colors.textRed,
        fontWeight: '400',
        marginTop: '2px',
    },
    formsErrorMessage2: {
        fontSize: '13px',
        fontFamily: fontFamily,
        color: colors.textRed,
        fontWeight: '400',
    },
    formsSubTitle: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '400',
        lineHeight: '18.75px',
    },
    formsSubTitleMargin: {
        fontSize: '16px',
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '400',
        lineHeight: '18.75px',
        margin: '40px 0px -15px 0px',
    },
    formsSwitch: {
        fontSize: '18px',
        fontFamily: fontFamily,
        fontWeight: '400',
    },
    formsToggleText: {
        fontSize: '14px',
        fontFamily: fontFamily,
        fontWeight: '700',
    },
    hourText: {
        fontSize: '16px',
        fontFamily: fontFamily,
        fontWeight: '500',
        color: colors.textGrey,
    },
    /* Tooltip */
    infoText: {
        fontSize: '11px',
        fontFamily: fontFamily,
        color: colors.textRed,
        fontWeight: '500',
    },

    /**** Page/Register ****/
    /* ProgressMenu */
    stepText: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: colors.primary,
        fontFamily: fontFamily,
    },
    /**** MapsPortal ****/
    /* AlertsPanel */
    selectAll: {
        fontSize: '22px',
        fontWeight: '400',
        color: colors.primary,
        fontFamily: fontFamily,
    },
    /* LeftPanel */
    panelTitle: {
        fontSize: '32px',
        fontWeight: '700',
        color: colors.white,
        fontFamily: fontFamily,
        lineHeight: '53.03px',
        letterSpacing: '0.1em',
    },
    companyName: {
        fontSize: '18px',
        fontWeight: '600',
        color: colors.textBlack,
        fontFamily: fontFamily,
        lineHeight: '21px',
        letterSpacing: '0.05em',
    },
    planName: {
        fontSize: '16px',
        fontWeight: '500',
        color: colors.textBlack,
        fontFamily: fontFamily,
        lineHeight: '19px',
        letterSpacing: '0.05em',
    },
    targetNameNormal: {
        fontSize: '14px',
        fontWeight: '400',
        color: colors.textLightGrey,
        fontFamily: fontFamily,
        lineHeight: '16px',
        letterSpacing: '0.05em',
    },
    targetNameSelected: {
        fontSize: '14px',
        fontWeight: '400',
        color: colors.textLightPurple,
        fontFamily: fontFamily,
        lineHeight: '16px',
        letterSpacing: '0.05em',
    },
    expandInfosTopic: {
        fontFamily: fontFamily,
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    expandInfosItem: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    /* RightPanel */
    inputTitle: {
        fontSize: '14px',
        fontWeight: '400',
        color: colors.inputGrey,
        fontFamily: fontFamily,
        lineHeight: '16px',
        letterSpacing: '0.05em',
    },
    targetTitle: {
        fontSize: '16px',
        fontWeight: '600',
        color: colors.textBlack,
        fontFamily: fontFamily,
        lineHeight: '18.75px',
        letterSpacing: '0.05em',
    },
    targetTitleWarning: {
        fontSize: '16px',
        fontWeight: '600',
        color: colors.textRed,
        fontFamily: fontFamily,
        lineHeight: '18.75px',
        letterSpacing: '0.05em',
    },
    backgroundRainAlert: {
        backgroundColor: colors.rainAlertBlue,
    },
    backgroundLightningAlert: {
        backgroundColor: colors.textLightPurple,
    },
    backgroundWeatherWarning: {
        backgroundColor: colors.weatherColor,
    },
    backgroundReportCard: {
        backgroundColor: colors.reportCardColor,
    },
    confirmationModalTitle: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '34px',
        lineHeight: '46.88px',
    },
    deleteModalTitle: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '46.88px',
    },
    infosModalTitle: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '46.88px',
    },
    warningMessage: {
        fontFamily: fontFamily,
        color: colors.textRed,
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28.13px',
    },
    topic: {
        fontFamily: fontFamily,
        color: colors.primary,
        fontWeight: '700',
        fontSize: '10px',
        lineHeight: '19px',
        letterSpacing: '0.05em',
    },
    item: {
        fontFamily: fontFamily,
        color: colors.primary,
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.05em',
        marginLeft: '5px',
    },
    checkboxColorGrey: {
        color: colors.textLightGrey,
    },
    /* TargetPopUp */
    popUpHeader: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '37.5px',
        letterSpacing: '5%',
        height: '25px',
    },
    popUpHeaderSmall: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '37.5px',
        letterSpacing: '5%',
        height: '25px',
    },
    popUpHeaderDischarges: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '37.5px',
        letterSpacing: '5%',
        height: '25px',
    },
    popUpHeaderDischargesSmall: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '37.5px',
        letterSpacing: '5%',
        height: '25px',
    },
    popUpContentTitle: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpContentTopic: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpContentTopicSmall: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '10px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpContentTopicMedium: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpContentTopicLarge: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpContentItem: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpContentItemSmall: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '10px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpContentItemMedium: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpContentItemLarge: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpContentItemDischarges: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpContentItemDischargesSmall: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '400',
        fontSize: '10px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        marginLeft: '3px',
    },
    popUpSwitch: {
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    popUpButton: {
        fontFamily: fontFamily,
        color: colors.white,
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    mapSidebarTargetDescripitionItem:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '11px',
    },
    mapSidebarTargetDescripitionContent:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '500',
        fontSize: '11px',
    },
    mapSidebarCamtDescripitionItem:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '14px',
    },
    mapSidebarCamDescripitionContent:{
        fontFamily: fontFamily,
        color: colors.textGrey,
        fontWeight: '600',
        fontSize: '14px',
    },
    mapSidebarCamDescripitionLinkContent:{
        fontFamily: fontFamily,
        color: colors.link,
        fontWeight: '600',
        fontSize: '14px',
    },
    alertsPanelDescripitionItem:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '11px',
    },
    alertsPanelTargetDescripitionContent:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '500',
        fontSize: '11px',
    },
    alertsPanelLoadingTarget:{
        fontFamily: fontFamily,
        color: colors.textBlack,
        fontWeight: '700',
        fontSize: '14px',
    },
});

export default fontStyles;
