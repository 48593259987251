import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// Libs
import TooltipComponent from 'components/Modal/Tooltip.js';
// Components
import {
  DividerStyled,
  PlansTabStyled,
  SearchDiv,
  SectionTitle,
  PlanItem,
  TargetList,
  ItemContent,
  CompanyItem,
  Span,
  SearchButton
} from './styled';
import {Checkbox} from '@material-ui/core';
import {Column, Row} from 'components/Flex';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../TargetInfo';
// Icons
import {MdExpandMore} from 'react-icons/md';
// Hooks
import {useTarget} from 'Context/TargetContext';
import {useMapContext} from 'Context/MapContext';
import useToggle from 'hooks/useToggle';
import {useTargetState} from 'Context/TargetStateContext';
import { getTargetList } from 'services/mapsPortal/initialLoad';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      position="top-end"
      text={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}

const PlansTab = (props) => {
  const { toggleHotkeys} =
      useMapContext();
  const planRef = useRef(null);
  const { setPlanTabWidth } = props;
  const { clientTargets, toShow: checkboxes } = useClientTargetsState();
  const { targetState } = useTargetState();
  const [ searchValue, setSearchValue ] = useState('');
  const [ sortAscending, setSortAscending ] = useState(true);
  const [collapse, setCollapse] = useState([]);
  const [collapseAlerts, setCollapseAlerts] = useState([]);
  const [showCompanies, toggleShowCompanies] = useToggle(false);
  const [showInAlert, toggleShowInAlert] = useToggle(false);

  useEffect(() => {
    const p = () => {
      const data = [{
        value: false,
        plans: clientTargets[0]?.planos.map((plano) => false)
      }]

      return data;
    }

    setCollapse(p())
    setCollapseAlerts(p())
  }, [JSON.stringify(clientTargets)])

  const collapseAlertCompany = (id) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapseAlertPlan = (cId, pId) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }


  const collapseCompany = (id) => {
    setCollapse(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapsePlan = (cId, pId) => {
    setCollapse(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }

  const handleFilter = (text) => {
    return text
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  }
  
  const plansLinesToShow = clientTargets.filter(empresa => {
    const a = empresa.planos.filter(plan => {
        const targets = plan.alvos.filter(target => handleFilter(target.nome))
        return targets.length || handleFilter(plan.nome)
    })
    return a.length || handleFilter(empresa.nome)
  });
  console.log("🚀 ~ plansLinesToShow ~ plansLinesToShow:", plansLinesToShow)
  const filterPlanInAlert = (plan) => {
    return plan.alvos.some(target => targetState[target.id_alvo]);
  }

  const filterCompanyInAlert = (company) => {
    return company.planos.some(plan => filterPlanInAlert(plan))
  }

  useEffect(() => {
    if(planRef){
      setPlanTabWidth(planRef?.current? planRef.current.getBoundingClientRect().width : null);
    }
  }, [planRef])

    return (
        <PlansTabStyled ref={planRef}>
            <SearchDiv>
              <Input
                style={{
                  border: '1px solid #032A4A',
                  paddingLeft: '6px',
                  backgroundColor: 'white',
                  flex: 1
                }}
                id="searchTargets"
                startAdornment={(
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                )}
                placeholder='Buscar'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onFocus={() => toggleHotkeys(false)}
                onBlur={() => toggleHotkeys(true)}
              />
               <OptionsTooltip title={!sortAscending? "Organizar A-Z" : "Organizar Z-A"}>
                <SearchButton onClick={()=> setSortAscending(p => !p)}>
                  {!sortAscending?
                    <FaArrowDown style={{fontSize: "16px"}}/> :
                    <FaArrowUp style={{fontSize: "16px"}}/>
                  }
                </SearchButton>
              </OptionsTooltip>
            </SearchDiv>
          <DividerStyled />
          <TargetList>
            {!plansLinesToShow.length
              ? <SectionTitle>Nenhum Plano ou Alvo encontrado.</SectionTitle>
              : (
              <>
                <Collapsible isOpen={showInAlert} main={(
                    <>
                      <CompanyItem isOpen={showInAlert}>
                        <Checkbox
                          label={'Todos'}
                          onChange={checkboxes.toggleAll}
                          checked={checkboxes.getAll()}
                          style={{color: "#1B1B1B"}}
                        />
                        <ItemContent onClick={toggleShowInAlert}>
                          <h2>EM ALERTA</h2>
                          <MdExpandMore style={{fontSize: '32px'}}/>
                        </ItemContent>
                      </CompanyItem>
                      <DividerStyled />
                    </>
                )}>
                  {plansLinesToShow.sort((a, b) => sortAscending
                    ? a.nome.localeCompare(b.nome)
                    : b.nome.localeCompare(a.nome))
                  .filter((company) => filterCompanyInAlert(company))
                  .map((company) => 
                        {return company.planos
                          .filter(plan => filterPlanInAlert(plan))
                          .filter(plan => {
                              const targets = plan.alvos.filter(target => handleFilter(target.nome))
                              return targets.length || handleFilter(plan.nome)
                          })
                          .map((plan) => 
                              {return plan.alvos
                                ?.filter(
                                  target => targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome))
                                )
                                .sort((a, b) => sortAscending
                                  ? a.nome.localeCompare(b.nome)
                                  : b.nome.localeCompare(a.nome))
                                .map((target) => {
                                  return(
                                    <Column key={`sidebarAlvoAlert-${plan.id}-${target.id}`}>
                                      <TargetInfo
                                        target={target}
                                        plan={plan}
                                        company={company}
                                        checkboxes={checkboxes}
                                        checked={true} //Need Change
                                      />
                                    </Column>
                                )})})})}

                            
                     
                      
                
                </Collapsible>

                {/* --------------- EMPRESAS  ----------------------- */}

                <Collapsible isOpen={showCompanies} main={(
                    <>
                      <CompanyItem isOpen={showCompanies}>
                        <Checkbox
                          label={'Todos'}
                          onChange={checkboxes.toggleAll}
                          checked={checkboxes.getAll()}
                          style={{color: "#1B1B1B"}}
                        />
                        <ItemContent onClick={toggleShowCompanies}>
                          <h2>TODOS</h2>
                          <MdExpandMore style={{fontSize: '32px'}}/>
                        </ItemContent>
                      </CompanyItem>
                      <DividerStyled />
                    </>
                )}>
                {plansLinesToShow.sort((a, b) => sortAscending
                ? a.nome.localeCompare(b.nome)
                : b.nome.localeCompare(a.nome))
              .map((company) => 
                  {return company.planos
                    .filter(plan => {
                        const targets = plan.alvos.filter(target => handleFilter(target.nome))
                        return targets.length || handleFilter(plan.nome)
                    })
                    .map((plan) => 
                        {return plan.alvos
                          ?.filter(
                            target => handleFilter(target.nome) || handleFilter(plan.nome)
                          )
                          .sort((a, b) => sortAscending
                            ? a.nome.localeCompare(b.nome)
                            : b.nome.localeCompare(a.nome))
                          .map((target) => {return(
                          <Column key={`sidebarAlvo-${plan.id}-${target.id}`}>
                            <TargetInfo
                              target={target}
                              plan={plan}
                              company={company}
                              checkboxes={checkboxes}
                              checked={targetState[target.id_alvo]} //Need Change
                            />
                          </Column>
                        )})}
                    )}
              
                )}
                </Collapsible>
              </>
              )}
          </TargetList>
        </PlansTabStyled>
    );
};

OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

PlansTab.propTypes = {
  setPlanTabWidth: PropTypes.func.isRequired,
};

export default PlansTab;
