/* Libraries */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
/* Components */
import { useToast } from 'Context/ToastContext';
import { FaNewspaper } from 'react-icons/fa'
/* CSS */
import styles from "./confirmation_modal-jss";
import fontStyles from "styles/fonts-jss";
import styled from 'styled-components';
/* Services */
import { getEmployeesInfosByCompanyID } from "services/adminDashboard/employees";
/* Context */
import { useModal } from 'Context/ModalContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { modalProvider } from 'redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { postReportCardEmail, updateReportCard } from 'services/mapsPortal/alerts';

const Body = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 75%;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.6);
`
const HeaderDiv = styled.div`
  height: 16%;
  width: 100%;
  border-radius: 13px 13px 0 0;
  align-items: center;
  justify-content: center;
  display: flex;
  `
const ContentDiv = styled.div`
  width: 100%;
  background-color: white;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  `

const TitleContentDiv = styled.div`
  height: 15%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid;
  `

const UsersContentDiv = styled.div`
  min-height: 40px;
  width: 80%;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`

const HeaderContentDiv = styled.div`
  width: 80%;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
`

const FooterDiv = styled.div`
  height: 12%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 13px 13px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
  border-top: 1px solid;
  `

const useStyles = makeStyles({
  divider: {
    background: "#848484",
    border: "0.7px solid",
    width: "100%",
    marginTop: "1.5%"
  },
});


const UsersModal = (props) => {
  const { classes, rightSideTabs, openUsersModal, setOpenUsersModal, statusTargetSelected, alertsData } = props;
  const fonts = fontStyles();
  const dividerStyle = useStyles();
  const [loading, setLoading] = useState(true);
  const openToast = useToast();
  const { plans: planos, toShow } = useModal();
  const [usersList, setUsersList] = useState([]);
  const [email, setEmail] = useState('');
  const [reload, setReload] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const { closeModal } = useModal();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenUsersModal(false);
  };

  const handlePatchReportCard = async () => {
      alertsData[0].status = 2;
      delete alertsData[0].rajada_vento_original;
      delete alertsData[0].velocidade_vento_original;

      await updateReportCard(alertsData[0].id_boletim, alertsData[0]).then((response) => {
        if(response.status == 200){
          handleSubmit();
        }
      
      })
      .catch((error) => {
        openToast("error", "Problema ao enviar boletim especial.");
      });
  }
    

  const handleSubmit = async () => {
    const users = usersList.filter((user) => user.checked)
      .map((user) => user.email);

    if(users?.length == 0){
      openToast("error", "Selecione um ou mais usuários antes de enviar.");
    }
    else{
      const data = {
        id_boletim: alertsData[0].id_boletim,
        emails: users
      }

      await postReportCardEmail(data).then((response) => {
        if(response.status == 200){
          setOpenUsersModal(false);
          closeModal();
          dispatch(modalProvider(false, null, null, null, null, null));
          openToast("success", "Boletim especial enviado com sucesso!");
        }
        else{
          openToast("error", "Problema ao enviar boletim especial."); 
        }
      })
      .catch((error) => {
        openToast("error", "Problema ao enviar boletim especial.");
      });
    }
  };

  const handleAddEmail = () => {
    if (!email.match(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`)) {
      openToast("error", "O formato do Email está incorreto!");
    }
    else{
      let aux = usersList;
      aux.push({nome: '', email: email, checked: true});
      setUsersList(aux);
      setEmail('');
    }
  }

  const handleCheckUser = (index) => {
    let aux = usersList;
    aux.map((user, id) => id == index? user.checked = !user.checked : user.checked);
    setUsersList(aux);
    setReload(true);
  }

  const handleAllChecked = () => {
    let aux = usersList;
    aux.map((user) => user.checked = !allChecked);
    setUsersList(aux);
    setAllChecked(!allChecked);
    setReload(true);
  }
  
  const getUsers = async () => {
    await getEmployeesInfosByCompanyID(planos[0].id_empresa_id).then((response) => {
      if(response && response[0] && response[0][0]){
        console.log(response[0][0])
        const users = response[0][0].filter((user) =>
          alertsData.find((report) => user.alvos?.filter((target) => target?.id_alvo == report?.id_alvo)?.length > 0)
        )
        .map((user) => {
          return {nome: user.usuario.nome, email: user.usuario.email, checked: true};
        })
        setUsersList(users);
        setLoading(false);
      }
    }).
    catch((error) => {
      console.log(error);
      setLoading(false);
      openToast("error", "Problema ao carregar usuários!");
    });
  } 

  useEffect(() => {
    if (planos[0]) {
      getUsers();
    }
  }, [planos]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  if (!loading) {
    return (
      <Dialog
        open={openUsersModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <Body>
          <HeaderDiv style={fonts.backgroundReportCard}>
            <span style={fonts.confirmationModalTitle}>ENVIAR BOLETIM</span>
          </HeaderDiv>

          <ContentDiv>
            <TitleContentDiv>
              <span style={fonts.popUpContentTitle}>Selecionar usuários que receberão o Boletim Especial</span>
            </TitleContentDiv>

            <HeaderContentDiv>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={allChecked}
                    onChange={()=> handleAllChecked()}
                    value={false}
                    color="primary"
                  /> )}
                  label="Selecionar todos"
                  //style={fonts.formsField}
                  //className={classes.checkboxModalDown}
              />
              <div className={classes.emailDiv}>
                <TextField
                  id="email"
                  label="Adicionar novo email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.emailInput}
                  shrink={true}
                  variant="outlined"
                  color="primary"
                />

                <Button style={fonts.buttonDefault} variant="contained" className={classes.usersButton} onClick={() => handleAddEmail()}>
                  ADICIONAR
                </Button>  
              </div>
            </HeaderContentDiv>

            {usersList && usersList.length > 0?
              usersList.map((user, index) => (
                <UsersContentDiv key={index}>
                  {user.nome?
                    <div className={classes.usersDiv}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={user.checked}
                            onChange={()=> handleCheckUser(index)}
                            value={false}
                            color="primary"
                          /> )}
                        label={user.nome}
                       // style={fonts.formsField}
                        //className={classes.checkboxModalDown}
                      />
                      <span>{user.email}</span>
                    </div>
                    :
                    <FormControlLabel
                        control={(
                          <Checkbox
                            checked={user.checked}
                            onChange={()=> handleCheckUser(index)}
                            value={true}
                            color="primary"
                          /> )}
                        label={user.email}
                       // style={fonts.formsField}
                        //className={classes.checkboxModalDown}
                      />
                  }

                  {index == usersList.length - 1?
                    <Divider classes={{ root: dividerStyle.divider }} style={{marginBottom: '25px'}} /> :
                    <Divider classes={{ root: dividerStyle.divider }}/>
                  }
                </UsersContentDiv>
            ))
            :
            <div style={{height: "200px", width: "200px", alignItems: "center", justifyContent: "center", display: "flex"}}>
              <span>Sem dados</span>
            </div>
          }
          </ContentDiv>

          <FooterDiv>
            <Button style={fonts.buttonDefault} variant="contained" className={classes.usersButton} onClick={() => handlePatchReportCard()}>
              Confirmar envio
              <div className={classes.iconContainer}>
                <FaNewspaper style={{ width: "16px", height: "16px", color: "#C0A514" }} />
              </div>
            </Button>
          </FooterDiv>
        </Body>
      </Dialog>
    );
  }
}

UsersModal.propTypes = {
  classes: PropTypes.object,
  rightSideTabs: PropTypes.any,
  openUsersModal: PropTypes.any,
  setOpenUsersModal: PropTypes.func,
  statusTargetSelected: PropTypes.any,
  alertsData: PropTypes.any,
};

export default withStyles(styles)(UsersModal);



