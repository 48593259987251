import styled, {keyframes, css} from 'styled-components';
import {colors} from 'pages/MapsPortal/styled';

const translatePosition = css`translate(-50px, -50px)`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 1;}
  100% { transform: scale(2); opacity: 0;}
`

const changePositionAnimation = keyframes`
0% { transform: translate(0); }
25% { transform: translate(0) scale(0.5);}
75% { transform: ${translatePosition} scale(0.5)}
100% {
  transform: ${translatePosition} scale(1);
}
`

// Mesma animação de cima, porém reversa, isso deve ser feito
// pois usando a animação de cima no reverse, as animações de ir
// e vir do botão não rodam;
const changePositionAnimation2 = keyframes`
0% {
  transform: ${translatePosition} scale(1);
}
25% { transform: ${translatePosition} scale(0.5)}
75% { transform: translate(0) scale(0.5);}
100% { transform: translate(0); }
`

const pulseBombAnimation = keyframes`
0% { transform: scale(1); opacity: 1; }
70% { transform: scale(2.5); opacity: 0;}
100% { transform: scale(1); opacity: 0;}
`
const pulseCircleAnimation = keyframes`
  0% { transform: ${translatePosition} scale(1)}
  70% { transform: ${translatePosition} scale(1.8)}
  100% { transform: ${translatePosition} scale(1)}
`


export const Alert = styled.div`
  z-index: 2;
  --color: ${(props) => {
    switch(props.level){
      case 1:
        return '#43a047'
      case 2:
        return '#ffa000'
      case 3:
        return '#d32f2f'
      default:
        return 'black'
    }
  }};
  position: fixed;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  background-color: var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease filter;
  animation: ${(props) =>
    props.run
        ? css`
          ${changePositionAnimation} 400ms normal 0ms 1 linear,
          ${pulseCircleAnimation} 700ms normal 400ms infinite ease`
        : css`
          ${changePositionAnimation2} 400ms normal 0ms 1 linear`
  };
  /* animation-fill-mode: forwards, forwards, forwards; */
  &:hover{
    filter: brightness(60%);
    animation-play-state: ${props => props.run ? 'paused' : ''};
    &::before{
      filter: brightness(60%);
      animation-play-state: ${props => props.run ? 'paused' : ''};
    }
  }

  & > span{
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 24px;
    user-select: none;
  }

  &::before{
    position: absolute;
    transition: 0.3s ease filter;
    pointer-events: none;
    z-index: -1;
    background-color: transparent;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    content: '';
    /* border: 4px solid var(--color); */
    box-shadow: 0 0 12px 12px var(--color) inset;
    animation: ${(props) => props.run
      ? css`${pulseBombAnimation} 700ms normal 400ms infinite ease`
      : css`${pulseAnimation} 2s ease-out 400ms infinite`} ;

}

`

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 0 13px 13px 13px;
  background-color: ${(props) => {
    switch(props.level){
      case 1:
        return '#43a047'
      case 2:
        return '#ffa000'
      case 3:
        return '#d32f2f'
      default:
        return 'black'
    }
  }};
  padding: 8px;
  position: fixed;
  bottom: 70px;
  right: 0;
  transition: transform ease-in-out 0.3s;
  transform-origin: right;
  transform: ${props => props.open ? 'scaleX(1)' : 'scaleX(0)'};
`

export const CloseButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  transition: 0.2s ease;
    svg {
      font-size: 20px;
      path{
        transition: fill 0.2s ease;
        fill: white;
      }
    }

    &:hover{
      svg {
        path{
          fill: red;
        }
      }
    }
`

export const Button = styled.button`
  background-color: transparent;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  transition: 0.2s ease;
  width: 70%;
    svg {
      path{
        transition: fill 0.2 ease;
        fill: ${props => props.color ? props.color : 'black'};
      }
    }

    &:hover{
      background-color: black;
      span{
        color: white
      }
      svg {
        path{
          fill: white;
        }
      }
    }
`

export const TabButton = styled.button`
    background-color: transparent;
    border: 2px solid black;
    display: flex;
    color: black;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    svg {
      font-size: 20px;
      path{
        transition: fill 0.2s ease;
        fill: black;
      }
    }
    &:hover{
      background-color: black;
      svg {
        path{
          fill: white;
        }
      }
    }
`

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  /* button {
    background-color: #FFFFFF88;
    display: flex;
    color: black;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    svg {
      path{
        fill: white;
      }
    } */
`

export const ColumnDiv = styled.div`
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  span{
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`

export const HeaderNotif = styled.h2`
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 700;
  margin: 0 4px;
  padding: 0;
`

const notifWidth = '280px'
export const NotifDiv = styled.div`
  --bottom-distance: ${props => props.animationRunning ? '160px' : '80px'};
  --notif-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  transition: transform 0.6s cubic-bezier(.22,0,.2,1.24);
  transform-origin: bottom;
  transform: ${props => props.open
    ?
    'translateX(-100%)'
    :
    'translateX(0)'
  };
  z-index: 5;
  position: fixed;
  bottom: var(--bottom-distance);
  right:  -${notifWidth};
  height: calc(100vh - var(--bottom-distance));
  width: ${notifWidth};
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.4);
  border-radius: 13px;
  overflow: hidden;
  background-color: white;
  font-family: 'Roboto';
`

export const NotifTitle = styled.div`
  background-color: ${colors.background};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.4);
  h2{
    padding: 10px 0;
    line-height: 1rem;
    margin: 0;
    font-weight: 700;
    font-size: 20px;
  }
`

export const NotifScroll = styled.div`

  padding-top: 12px;
  height: calc(100% - 36px);
  width: 100%;
  gap: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.dark};
      border: 1px solid ${colors.dark};
    }
  }
`

export const NotifBackground = styled.div`
  background-color: ${(props) => {
    switch(props.level){
      case 1:
        return '#43a047'
      case 2:
        return '#FB8213'
      case 3:
        return '#EA2f2f'
      default:
        return 'black'
    }
  }};
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow:  0px 4px 6px 3px rgba(0, 0, 0, 0.4);
`

export const ReleaseBackground = styled(NotifBackground)`
  background-color: #67E164;
`

export const RainAlertBackground = styled(NotifBackground)`
  background-color: rgba(4, 109, 202, 0.7);
`

export const BoletimBackground = styled(NotifBackground)`
  background-color: rgba(192, 165, 20, 0.7);
`

export const NotifCard = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 85%;
  div{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span{
    color: black;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    strong{
      font-weight: 700;
    }
  }
`
