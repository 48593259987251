/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';


import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import { SiAlpinelinux } from "react-icons/si";

const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
  })
  

const AlertsElement = (props) => {
    const {classes, rightSideTabs, indexPage, company, value, setReloadRightSide, setAllValues, allValues} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const dividerStyle = useStyles();
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState(null);
    const [reload, setReload] = useState(false);
    const [invitePrincipal, setInvitePrincipal] = useState(false);
    const [postSwitchs, setPostSwitchs] = useState([]);
    const defaultProps = {
      options: ["T1", "T2", "T3", "T4"],
    };

    const tools = ["Descargas", "Radar", "Satélite", "TSC"]

    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    useEffect(() => {
      setReload(false);
    }, [reload])

    const initialSets = () => {
      let aux = []
      aux = {nome: value.nome, nivel: value.nivel, duracao: value.dur_est, risco: value.risco, id_empresa_id: value.id_empresa_id, contato: value.contato, observacao: value.observacao, ferramenta: value.ferramenta?.includes(",")? value.ferramenta?.split(",")  : value.ferramenta==""? [] : [value.ferramenta], datahora_liberacao: value.datahora_liberacao, liberado: value.liberado, id_alerta: value.id, meteorologista: value.meteorologista, errata: value.errata, campos_modificados: [], intensidade: value.intensidade, acumulado: value.acumulado, id_og: value.id_og}
      setCompanyValues(aux);
    };

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
      setAllValues({nivel: companyValues.nivel, observacao: companyValues.observacao, ferramenta: companyValues.ferramenta, duracao: companyValues.duracao, risco: companyValues.risco, liberado: companyValues.liberado!=null&&companyValues.liberado!=""? companyValues.liberado : false, contato: companyValues.contato, datahora_liberacao: companyValues.datahora_liberacao, meteorologista: companyValues.meteorologista, id_alerta: companyValues.id_alerta, errata: companyValues.errata, campos_modificados: companyValues.campos_modificados, intensidade: companyValues.intensidade, acumulado: companyValues.acumulado, id_og: companyValues.id_og});
      setChecked(!checked);
      setReloadRightSide(true);
    };

    const modifyAllValues = () => {
      setAllValues(companyValues);
    };
    
    const getPostSwitchs = (element) => {
      const finder = postSwitchs.find((filteredButton) => filteredButton == element);
      let aux = false
      if(finder === undefined || finder == ""){
        aux = false
      }else{
        aux = true
      }
      return aux
    };
    
    useEffect(() => {
      initialSets();
    }, [])

    const addModifiedValue = (input, newValue) =>{
      let aux = companyValues

      console.log(input);
      console.log(newValue);

      if(aux.campos_modificados.length > 0){
        if(!aux.campos_modificados.includes(input)){
          aux.campos_modificados.push(input)
        }
      }else{
        aux.campos_modificados.push(input)
      }

      if(input=="risco"){
        aux.risco = newValue
      }
      if(input=="nivel"){
        aux.nivel = newValue
      }
      if(input=="contato"){
        aux.contato = newValue
      }
      if(input=="duracao"){
        aux.duracao = newValue
      }
      if(input=="ferramenta"){
        aux.ferramenta = newValue
      }
      if(input=="observacao"){
        aux.observacao = newValue
      }

      setCompanyValues(aux);
      modifyAllValues();
    };

    if(companyValues!=null) {
      if(companyValues.contato=="null"){addModifiedValue("contato", "Não foi possível o contato telefônico.")}
    return (
        <>
          <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">
              <Grid item xs={12} className={classes.gridContainerComponent}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft:"20px"}}>
                  <Switch
                      checked={checked}
                      onChange={handleChange}
                      size="small"
                      color={"primary"} />
                    <div style={{ marginLeft: "15px" }}>
                      <Typography variant="subtitle1">
                      {value.nome}
                      </Typography>
                    </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "10px", marginLeft:"12px"}} >
              <Grid item xs={1.5} className={classes.gridContainerComponent}>
                <div className={classes.selectContainer} style={{ width: "100px"}}>
                    <Autocomplete
                    {...defaultProps}
                    id="level"
                    freeSolo={false}
                    isRequired
                    disableClearable
                    value={companyValues?.nivel}
                    inputValue={companyValues?.nivel}
                    onChange={(_, newValue) => addModifiedValue("nivel", newValue)}
                    className={classes.selectFieldSmallLightAlert}
                    style={fonts.formsFieldHeight1}
                    size="small"     
                    renderInput={(params) =>
                        <TextField
                        {...params}
                        label="Nível"
                        variant="outlined"
                        InputLabelProps={{ shrink: "true" }}
                          />}
                    />
                </div>
              </Grid>
              <Grid item xs={1.5} className={gridClasses}>
                <div className={classes.selectContainer} style={{marginLeft:"5px", width:"180px"}}>
                  <Autocomplete
                  id="risk"
                  freeSolo={false}
                  isRequired
                  disableClearable
                  value={companyValues?.risco}
                  inputValue={companyValues?.risco}
                  onChange={(_, newValue) => addModifiedValue("risco", newValue)}
                  className={classes.selectFieldLarge}
                  style={fonts.formsFieldHeight1}
                  size="small"
                  options={["Médio", "Alto", "Muito alto"]}
                  renderInput={(params) =>
                      <TextField
                      {...params}
                      label="Risco"
                      variant="outlined"
                      InputLabelProps={{ shrink: "true" }}
                      onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                          event.stopPropagation();
                          }
                      }} />} />
                </div>
              </Grid>
              <Grid item xs={1.5} direction="row" alignItems="flex-start" justifyContent="flex-start" className={classes.gridColumnsComponent}>
                <div className={classes.selectContainer} style={{marginLeft: "15px", width:"580px"}}>
                  <Autocomplete
                  id="contacts"
                  freeSolo
                  isRequired
                  disableClearable
                  value={companyValues?.contato}
                  inputValue={companyValues?.contato}
                  className={classes.selectFieldLightAlertComponent}
                  onInputChange={(_, newValue) => addModifiedValue("contato", newValue)}
                  onChange={(_, newValue) => addModifiedValue("contato", newValue)}
                  style={fonts.formsFieldHeight1}
                  size="small"
                  options={["Não foi possível o contato telefônico.", "Não foi realizado o contato", "Não há contato telefônico das xx:xx até às xx:xx"]}
                  renderInput={(params) =>
                      <TextField
                      {...params}
                      label="Contato"
                      variant="outlined"
                      InputLabelProps={{ shrink: "true" }}
                      onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                          event.stopPropagation();
                          }
                      }} />} />


                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" alignItems="flex-start" justifyContent="flex-start" style={{marginTop: "20px", marginLeft:"12px"}}>
              <Grid item xs={1.5} className={classes.gridContainerComponent}>
                <div className={classes.dateHourContainer} style={{marginTop:"4px"}}>
                    <InputMask
                    mask="99:99"
                    value={companyValues?.duracao}
                    inputValue={companyValues?.duracao}
                    onChange={(e) => addModifiedValue("duracao", e.target.value)}
                    disabled={false}
                    isRequired
                    maskChar=" "
                    >{(inputProps) =>
                    <TextField
                        id="duration"
                        {...inputProps}
                        className={classes.textFieldSmallLightAlert}
                        margin="normal"
                        label="Duração"
                        variant="outlined"
                        inputProps={{ style: fonts.formsFieldHeight2S }}
                        InputLabelProps={{ style: fonts.formsLabel, shrink: "true" }}
                    />}
                    </InputMask>
                </div>
              </Grid>
              <Grid item xs={1.5} className={gridClasses} >
                <div className={classes.gridContainerComponent} style={{ marginTop: "20px", marginLeft:"5px", width:"180px"}}>
                  <Autocomplete
                  id="tool"
                  className={classes.selectFieldLargeLightiningContent}
                  multiple={true}
                  isRequired
                  disableClearable
                  getOptionLabel={(option) => option}
                  style={fonts.formsFieldHeight1}
                  size="small"
                  value={companyValues?.ferramenta}
                  onChange={(_, newValue) => addModifiedValue("ferramenta", newValue)}
                  options={tools}
                  renderInput={(params) =>
                      <TextField
                      {...params}
                      label="Ferramenta"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: "true"}}
                      onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                          event.stopPropagation();
                          }
                      }} />} />
                </div>
              </Grid>
              <Grid item xs={1.5} className={classes.gridColumnsComponent}>
                <div className={classes.obsLightingAlertContent}  style={{ marginTop: "20px", paddingLeft:"15px", height:"50px", width:"590px"}}>
                    <TextField
                    id="observations"
                    label="Observações"
                    multiline
                    fullWidth
                    isRequired
                    value={companyValues?.observacao}
                    onChange={(e) => addModifiedValue("observacao", e.target.value)}
                    className={classes.textFieldLightAlertContent}
                    variant="outlined"
                    color="primary"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: {overflow:"none", height:"50px"}, maxLength: 100 }} />

                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="flex-start" style={{ marginTop: "45px", marginBottom:"20px" }}>
                <Grid item xs={12}>
                    <Divider classes={{ root: dividerStyle.divider }} />
                </Grid>
            </Grid>
          </Grid>
        </>
    )
  }
};

AlertsElement.propTypes = {
    classes: PropTypes.object.isRequired,
    setReloadRightSide: PropTypes.func,
    setAllValues: PropTypes.func,
    allValues: PropTypes.array.isRequired,
    indexPage: PropTypes.any,
    company: PropTypes.object.isRequired,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsElement);