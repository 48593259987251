import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getHistoryActives, getActives } from 'services/historyDischarges';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';
import useToggle from 'hooks/useToggle';
import { useSelector } from 'react-redux';
import {useTools} from 'Context/ToolsContext';

export const ActivesContext = createContext();

export const ActivesContextProvider = (props) => {

  // const{ data: descargas, loading: loadingDescargas, update: updateDescargas} = useLoad(getDischargeList, {});
  const { polygon } = useTools();
  const polygonSelected = useSelector(state => state.polygonProvider);
  const polygonData = polygonSelected && polygon.finishPolygon && polygon.polygonLine.length > 3? {
    "geometria": {
      "coordinates": [
        [
          [polygonSelected[0][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[0][1]]
        ]
      ],
    "type": "Polygon"
    }
  } : null  

  const{ data: actives, loading: loadingActives} = useLoadWhenActiveIsActive(getActives, !polygonData);
  const{ data: historyActives, loading: loadingHistoryActives} = useLoadWhenActiveIsActive(() => getHistoryActives(121, polygonData), polygonData != null);

  const loading = loadingActives || loadingHistoryActives;

    return (
        <ActivesContext.Provider
            value={{
              actives,
              historyActives,
              loading,
            }}
        >
            {props.children}
        </ActivesContext.Provider>
    );
};

ActivesContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ActivesContextProvider;

const useLoadWhenActiveIsActive = (func, active) => {
    const callback = useCallback(() => ({}), [])
    const{ data, loading, update, set} = useLoad(callback, {});

    useEffect(()=> {
      if(active) set(func)
        
      else set(callback)
    }, [active])

    useInterval(update, active ? 60000 : null);

    return {data, loading, update, set}
}

export const useActives = () => {
  const context = useContext(ActivesContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
