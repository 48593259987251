/* Libraries */
import React, { useEffect, useState, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/pt-br';
/* Material UI */
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
/* Components */
import TabPanel from 'components/TabPanel';
import { useToast } from 'Context/ToastContext';
import TargetTab from './component/TargetTab';
import { useModal } from 'Context/ModalContext';
import WeatherWarning from './component/MonitoringForms/WeatherWarning';
import DailyReportCard from './component/MonitoringForms/ReportCard/DailyReportCard';
import NowCastingReportCard from './component/MonitoringForms/ReportCard/NowCastingReportCard';
import SpecialReportCard from './component/MonitoringForms/ReportCard/SpecialReportCard';
import ConfirmationModal from './component/ConfirmationModal';
import UsersModal from './component/UsersModal'
import { FaNewspaper, FaCloudSun } from 'react-icons/fa';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReportTabs from './component/MonitoringForms/ReportCard/reportTabs';
import PdfViewer from './component/PdfModal/PdfViewer';
import ModalPdf from 'components/Modal/index';
import {uploadOnBucket} from 'utils/uploadOnBucket';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import styles from './forecast_panel-jss';
import styled from 'styled-components';
/* Services */
import { updateReportCard, postWeatherWarning } from '../../../../services/mapsPortal/alerts';
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
import AWS from 'aws-sdk';

const S3_BUCKET = 'aviso-meteorologico'
const REGION = 'us-east-1';

AWS.config.update({
    accessKeyId: "AKIA2UC266X2Z5HTAQUP",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const Body = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: white;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
`;
const LeftSide = styled.div`
    background-color: white;
    border-radius: 13px 0 0 13px;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        width: 45%;
    }
    @media (min-width: 1024px) {
        width: 40%;
    }
    @media (min-width: 1280px) {
        width: 40%;
    }
    @media (min-width: 1366px) {
        width: 40%;
    }
    @media (min-width: 1440px) {
        width: 40%;
    } ;
`;
const LeftSide_TitleDiv = styled.div`
    height: 13%;
    width: 100%;
    background-color: #1b1b1b;
    border-radius: 13px 0 0 0;
    text-align: center;
    justify-content: center;
    display: flex;
`;
const LeftSide_ContentDiv = styled.div`
    height: 78%;
    width: 100%;
    background-color: white;
    border-radius: 0 0 0 13px;
    position: relative;
    box-shadow: 4px 0px 10px 3px rgba(0, 0, 0, 0.1);
`;

const LeftSide_FooterDiv = styled.div`
    height: 9%;
    width: 100%;
    background-color: white;
    border-radius: 0 0 0 13px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    position: relative;
`;

const RightSide = styled.div`
    background-color: white;
    border-radius: 0 13px 13px 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    @media (min-width: 768px) {
        width: 55%;
    }
    @media (min-width: 1024px) {
        width: 60%;
    }
    @media (min-width: 1280px) {
        width: 60%;
    }
    @media (min-width: 1366px) {
        width: 60%;
    }
    @media (min-width: 1440px) {
        width: 60%;
    } ;
`;

const RightSide_TabsDiv = styled.div`
    height: 13%;
    width: 100%;
    background-color: white;
    border-radius: 0 13px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
    position: relative;
`;
const RightSide_ContentDiv = styled.div`
    height: 78%;
    width: 100%;
    background-color: white;
    border-radius: 0 0 0 13px;
    overflow-y: auto;
`;

const RightSide_FooterDiv = styled.div`
    height: 9%;
    width: 100%;
    background-color: white;
    border-radius: 0 0 13px 0;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.1);
    position: relative;
`;

const useStyles = makeStyles({
    weatherWarning: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#000000',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#000000',
        },
    },
    reportCard: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#C0A514',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#C0A514',
        },
    },
    switch_primary: {
        '&.Mui-checked': {
            color: '#1B1B1B',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#1B1B1B',
        },
    },
});

const ForecastPanel = (props) => {
    const theme = useTheme();
    const fonts = fontStyles();
    const openToast = useToast();
    const moment = require('moment');
    //moment.locale('pt-br');
    const tabStyle = useStyles();
    const { classes } = props;
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { open, closeModal, plans } = useModal();
    const [alertsData, setAlertsData] = useState([]);
    const [rightSideTabs, setRightSideTabs] = useState(props.tab);
    const [reportTabsIndex, setReportTabsIndex] = useState(0);
    const [reloadInfo, setReloadInfo] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [checkboxSelected, setCheckboxSelected] = useState();
    const [statusTargetSelected, setStatusTargetSelected] = useState([]);
    const [reloadStatusTargetSelected, setReloadStatusTargetSelected] =
        useState(false);
    const [reloadPostData, setReloadPostData] = useState(false);
    const [reloadSelected, setReloadSelected] = useState(false);
    const [reloadSwitchSelected, setReloadSwitchSelected] = useState(false);
    const [reloadSelectAll, setReloadSelectAll] = useState(false);
    const [reloadTargets, setReloadTargets] = useState(false);
    const [reloadTargetsList, setReloadTargetsList] = useState(false);
    const [confirmTargetsList, setConfirmTargetsList] = useState();
    const [selected, setSelected] = useState('');
    const [weatherAlertErrors, setWeatherAlertErrors] = useState([]);
    const [titleErrorWeather, setTitleErrorWeather] = useState("");
    const [subjectErrorWeather, setSubjectErrorWeather] = useState("");
    const [reportCardErrors, setReportCardErrors] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [reloadErrorMessage, setReloadErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reloadLoading, setReloadLoading] = useState(false);
    const [loadingLeftSide, setLoadingLeftSide] = useState([]);
    const openModal = useSelector((state) => state.modalProvider);
    const [intensityErrorReport, setIntensityErrorReport] = useState([]);
    const [conditionErrorReport, setConditionErrorReport] = useState([]);
    const [acumulatedErrorReport, setAcumulatedErrorReport] = useState([]);
    const [prob_lightningErrorReport, setProb_lightningErrorReport] = useState([]);
    const [windErrorReport, setWindErrorReport] = useState([]);
    const [maxTempErrorReport, setMaxTempErrorReport] = useState([]);
    const [minTempErrorReport, setMinTempErrorReport] = useState([]);
    const [levelErrorReport, setLevelErrorReport] = useState([]);
    const [windVelErrorReport, setWindVelErrorReport] = useState([]);
    const [windSprintErrorReport, setWindSprintErrorReport] = useState([]);
    const [openUsersModal, setOpenUsersModal] = useState(false);

    const handleChangeRightTabs = (event, newValue) => {
        setRightSideTabs(newValue);
        setReloadTargets(true);
    };

    const handleChangeIndexRightTabs = (index) => {
        setRightSideTabs(index);
        setReloadTargets(true);
    };

    const handleChangeIndexTabs = (index) => {
        setReportTabsIndex(index);
    };

    const handleClose = () => {
        closeModal();
        dispatch(modalProvider(false, null, null, null, null, null));
    };
    
    const postAlert = async () => {
        if (rightSideTabs === 1) {
            let aux = false;

            if(weatherAlertErrors && weatherAlertErrors.length > 0){
              let errorIndex = weatherAlertErrors.map((item, index) => item.titulo != null || item.assunto != null || item.file != null?  index : -1)
              for(let i = 0; i < errorIndex.length; i++){
                if (errorIndex[i] != -1) {
                  aux = true;
                  openToast("error", weatherAlertErrors[errorIndex[i]].titulo != null? weatherAlertErrors[errorIndex[i]].titulo : weatherAlertErrors[errorIndex[i]].assunto != null? weatherAlertErrors[errorIndex[i]].assunto : weatherAlertErrors[errorIndex[i]].file != null? weatherAlertErrors[errorIndex[i]].file : null);
                }
              }
            }
          
            if(!aux){
                if (alertsData && alertsData.length === 0) {
                  openToast("warning", "Nenhum alvo selecionado!");
                }
                else {
                    openToast("warning", "Enviando Aviso Meteorológico ...!");
                    for (let i = 0; i < alertsData.length; i++) {
                        if (alertsData[i]) {
                            handleFileUpload({
                                titulo: alertsData[i].titulo,
                                assunto: alertsData[i].assunto,
                                file: alertsData[i].file,
                                id_alvo: alertsData[i].id_alvo,
                            })
                        } 
                    }        
                }
            } 
        }
        else {
            let aux = false;
            if(reportTabsIndex === 2){
                setOpenUsersModal(true);
                aux = true;
            }
            else if(reportCardErrors && reportCardErrors.length > 0 && reportTabsIndex != 2){
                let errorIndex = reportCardErrors.map((item, index) => 
                    item.acumulado != null ||
                    item.condicao_tempo != null ||
                    item.probabilidade != null ||
                    item.intensidade != null ||
                    item.nivel != null ||
                    item.rajada_vento != null ||
                    item.direcao_vento != null ||
                    item.velocidade_vento != null ||
                    item.temperatura_min != null ||
                    item.temperatura_max != null?
                    index : -1
                )

              for(let i = 0; i < errorIndex.length; i++){
                if (errorIndex[i] != -1) {
                  aux = true;
                  openToast("error", reportCardErrors[errorIndex[i]].acumulado != null?
                    reportCardErrors[errorIndex[i]].acumulado :
                    reportCardErrors[errorIndex[i]].condicao_tempo != null?
                    reportCardErrors[errorIndex[i]].condicao_tempo : 
                    reportCardErrors[errorIndex[i]].probabilidade != null?
                    reportCardErrors[errorIndex[i]].probabilidade :
                    reportCardErrors[errorIndex[i]].intensidade != null?
                    reportCardErrors[errorIndex[i]].intensidade :
                    reportCardErrors[errorIndex[i]].nivel != null?
                    reportCardErrors[errorIndex[i]].nivel :
                    reportCardErrors[errorIndex[i]].rajada_vento != null?
                    reportCardErrors[errorIndex[i]].rajada_vento :
                    reportCardErrors[errorIndex[i]].direcao_vento != null?
                    reportCardErrors[errorIndex[i]].direcao_vento :
                    reportCardErrors[errorIndex[i]].velocidade_vento != null?
                    reportCardErrors[errorIndex[i]].velocidade_vento :
                    reportCardErrors[errorIndex[i]].temperatura_min != null?
                    reportCardErrors[errorIndex[i]].temperatura_min :
                    reportCardErrors[errorIndex[i]].temperatura_max != null?
                    reportCardErrors[errorIndex[i]].temperatura_max :
                    null);
                }
              }
            }
          
            if(!aux){
                if (alertsData && alertsData.length === 0) {
                  openToast("warning", "Nenhum alvo selecionado!");
                }
                else {
                    openToast('warning', 'Aprovando Boletim Meteorológico...!');
                    let targetNames = [];
                    let horizonsError = [];

                    for (let i = 0; i < alertsData.length; i++) {
                      let targetName = alertsData[i].nome_alvo;
                      if (alertsData[i]) {

                        let idBoletim = alertsData[i].id_boletim;
                        alertsData[i].observacao != null? alertsData[i].observacao === ""? alertsData[i].observacao = null : alertsData[i].observacao = alertsData[i].observacao?.trim() : alertsData[i].observacao;
                        alertsData[i].condicao_tempo = alertsData[i].condicao_tempo === "Em branco"? null : alertsData[i].condicao_tempo;
                        alertsData[i].intensidade = alertsData[i].intensidade === "Em branco"? null : alertsData[i].intensidade;
                        alertsData[i].acumulado = alertsData[i].acumulado === ""? null : alertsData[i].acumulado;
                        alertsData[i].probabilidade = alertsData[i].probabilidade === "Em branco"? null : alertsData[i].probabilidade;
                        alertsData[i].nivel = alertsData[i].nivel === "Em branco"? null : alertsData[i].nivel;
                        alertsData[i].datahora_modificacao = moment().utcOffset('+0000').format();
                        alertsData[i].status = 1;
                        alertsData[i].temperatura_max = alertsData[i].temperatura_max === ""? null : alertsData[i].temperatura_max + " °C";
                        alertsData[i].temperatura_min = alertsData[i].temperatura_min === ""? null : alertsData[i].temperatura_min + " °C";
                        alertsData[i].rajada_vento = alertsData[i].rajada_vento === "Em branco"? null : alertsData[i].rajada_vento;
                        alertsData[i].velocidade_vento = alertsData[i].velocidade_vento === ""? null : alertsData[i].velocidade_vento;
                        alertsData[i].direcao_vento = alertsData[i].direcao_vento === "Em branco"? null : alertsData[i].direcao_vento;
                        alertsData[i].datahora_modificacao = null;
                        alertsData[i].modificacao = false;
                        
                        delete alertsData[i].rajada_vento_original;
                        delete alertsData[i].velocidade_vento_original;
                        delete alertsData[i].id_boletim;

                        await updateReportCard(idBoletim, alertsData[i]).then((response) => {
                            if(response.status != 200){
                                targetNames.push(targetName)
                            }
                        }).catch((error) => { 
                            targetNames.push(targetName);
                            console.log(error)
                            if(error?.response?.data?.error){
                                horizonsError.push(error?.response?.data?.horizontes[0]);
                            }
                        })
                      }
                    }
                    if (!targetNames.length) {
                      openToast("success", "Boletim(s) aprovado(s) com sucesso!");
                      setReloadTargets(true);
                    }
                    else {
                        if(horizonsError.length > 0){
                            openToast("error", `Aprove primeiramente o(s) horizonte(s) ${horizonsError}, para o(s) alvo(s) ${targetNames}`);
                        }
                        else{
                            openToast("error", `Problema ao aprovar Boletim(s) no(s) alvo(s) ${targetNames}`);
                        }
                      setReloadTargets(true);
                    }
                }
            }
        }   
    };

    const handleFileUpload = async (data) => {
            let aux = await uploadOnBucket(data.file, data.id_alvo + '-' + data.titulo.replaceAll(' ', '_').replace('.pdf', '') + '.pdf', 'keep-games/jogos/imagens/');
            persistWeatherWarning(aux, data);
            /*
        const params = {
            ACL: 'public-read',
            Body: data.file,
            Bucket: S3_BUCKET,
            Key: data.id_alvo + '-' + data.titulo.replaceAll(' ', '_').replace('.pdf', '') + '.pdf'
        };

        const response = myBucket.putObject(params).send((err) => {
            if (err) {
                openToast(
                    'error',
                    'Problema ao enviar PDF! Tente novamente mais tarde.',
                );
            } else {
                if (response.httpResponse.statusCode === 200) {
                    var path =
                        'https://' +
                        response.request.httpRequest.endpoint.hostname +
                        response.request.httpRequest.path;
                    persistWeatherWarning(path, data);
                } else {
                    openToast(
                        'error',
                        'Problema ao enviar PDF! Tente novamente mais tarde.',
                    );
                }
            }
        });*/
    };

    const persistWeatherWarning = async (path, data) => {
        const postData ={
            titulo: data.titulo,
            texto: data.assunto,
            caminho_pdf: path,
            modificacao: false,
            id_alvo: data.id_alvo
        }

        await postWeatherWarning(postData).then((response) => {
            if (response.status === 201) {
                openToast('success', 'Aviso Meteorológico enviado com sucesso!');
            }
            else{
                openToast('error', 'Problema ao enviar Aviso Meteorológico!');       
            }
        }).catch((error) => {
            openToast('error', 'Problema ao enviar Aviso Meteorológico!');       
        });
    }

    const toggleAllSelected = () => {
        setAllSelected(!allSelected);
        if (checkboxSelected && checkboxSelected.length > 0) {
            for (let i = 0; i < checkboxSelected.length; i++) {
                checkboxSelected[i] = !allSelected;
            }
        }
        setSelected(checkboxSelected);
        setReloadSelectAll(true);
    };

    const onCloseModalPdf = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
    };

    useEffect(() => {
        setReloadInfo(false);
    }, [reloadInfo]);

    useEffect(() => {
        setReloadPostData(false);
    }, [reloadPostData]);

    useEffect(() => {
        setReloadStatusTargetSelected(false);
    }, [reloadStatusTargetSelected]);

    useEffect(() => {
        if (checkboxSelected && checkboxSelected.length > 0) {
            for (let i = 0; i < checkboxSelected.length; i++) {
                if (checkboxSelected[i] === false) {
                    setAllSelected(false);
                }
            }
        }
        setReloadSelectAll(true);
        setReloadSelected(false);
    }, [reloadSelected]);

    useEffect(() => {
        setReloadErrorMessage(false);
    }, [reloadErrorMessage]);

    useEffect(() => {
        setAllSelected(false);
    }, [rightSideTabs]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            ref={ref}
        >
            <Body>
                {openModal[0] && openModal[4] === 'PdfViewer' && (
                    <ModalPdf
                        height={
                            ref
                                ? ref.current?.getBoundingClientRect().height
                                : 857
                        }
                        onClose={onCloseModalPdf}
                    >
                        <PdfViewer pdfData={openModal[1]} />
                    </ModalPdf>
                )}
                <LeftSide>
                    <LeftSide_TitleDiv>
                        <AppBar
                            position="static"
                            color="transparent"
                            style={{ alignSelf: 'center' }}
                        >
                            <span style={fonts.panelTitle}>PREVISÃO</span>
                        </AppBar>
                    </LeftSide_TitleDiv>
                    <LeftSide_ContentDiv>
                        <TargetTab
                            tab={rightSideTabs}
                            loadingLeftSide={loadingLeftSide}
                            setLoadingLeftSide={setLoadingLeftSide}
                        />
                    </LeftSide_ContentDiv>
                    <LeftSide_FooterDiv>
                        <Button
                            onClick={() => handleClose()}
                            style={{ marginLeft: '25px' }}
                        >
                            <ArrowBackIcon
                                style={{
                                    width: '30px',
                                    height: '30px ',
                                    color: '#1B1B1B',
                                }}
                            />
                        </Button>
                    </LeftSide_FooterDiv>
                </LeftSide>

                <RightSide>
                    <RightSide_TabsDiv>
                        <Tabs
                            value={rightSideTabs}
                            onChange={handleChangeRightTabs}
                            className={
                                rightSideTabs === 1
                                    ? tabStyle.weatherWarning
                                    : tabStyle.reportCard
                            }
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            centered={true}
                        >
                            <Tab
                                icon={
                                    <FaNewspaper
                                        style={{
                                            width: '22px',
                                            height: '22px',
                                        }}
                                    />
                                }
                                label="Boletim"
                            />
                            <Tab
                                icon={
                                    <FaCloudSun
                                        style={{
                                            width: '22px',
                                            height: '22px',
                                        }}
                                    />
                                }
                                label="Aviso Meteorológico"
                            />
                        </Tabs>
                    </RightSide_TabsDiv>

                    <RightSide_ContentDiv>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={rightSideTabs}
                            onChangeIndex={handleChangeIndexRightTabs}
                        >
                            <TabPanel
                                value={rightSideTabs}
                                index={0}
                                dir={theme.direction}
                            >
                                <ReportTabs
                                    reportTabsIndex={reportTabsIndex}
                                    setReportTabsIndex={setReportTabsIndex}
                                />
                                <SwipeableViews
                                    axis={
                                        theme.direction === 'rtl'
                                            ? 'x-reverse'
                                            : 'x'
                                    }
                                    index={reportTabsIndex}
                                    onChangeIndex={handleChangeIndexTabs}
                                >
                                    <TabPanel
                                        value={reportTabsIndex}
                                        index={0}
                                        dir={theme.direction}
                                    >
                                        <DailyReportCard
                                            alertsData={alertsData}
                                            setAlertsData={setAlertsData}
                                            setReloadPostData={
                                                setReloadPostData
                                            }
                                            setSelected={setSelected}
                                            setReloadSelected={
                                                setReloadSelected
                                            }
                                            setCheckboxSelected={
                                                setCheckboxSelected
                                            }
                                            checkboxSelected={checkboxSelected}
                                            confirmTargetsList={
                                                confirmTargetsList
                                            }
                                            setReloadSwitchSelected={
                                                setReloadSwitchSelected
                                            }
                                            allSelected={allSelected}
                                            setAllSelected={setAllSelected}
                                            setReloadErrorMessage={
                                                setReloadErrorMessage
                                            }
                                            statusTargetSelected={
                                                statusTargetSelected
                                            }
                                            setStatusTargetSelected={
                                                setStatusTargetSelected
                                            }
                                            setReloadStatusTargetSelected={
                                                setReloadStatusTargetSelected
                                            }
                                            reloadTargets={reloadTargets}
                                            setReloadTargets={setReloadTargets}
                                            reportCardErrors={reportCardErrors}
                                            setReportCardErrors={setReportCardErrors}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value={reportTabsIndex}
                                        index={1}
                                        dir={theme.direction}
                                    >
                                        <NowCastingReportCard
                                           alertsData={alertsData}
                                           setAlertsData={setAlertsData}
                                           setReloadPostData={
                                               setReloadPostData
                                           }
                                           setSelected={setSelected}
                                           setReloadSelected={
                                               setReloadSelected
                                           }
                                           setCheckboxSelected={
                                               setCheckboxSelected
                                           }
                                           checkboxSelected={checkboxSelected}
                                           confirmTargetsList={
                                               confirmTargetsList
                                           }
                                           setReloadSwitchSelected={
                                               setReloadSwitchSelected
                                           }
                                           allSelected={allSelected}
                                           setAllSelected={setAllSelected}
                                           setReloadErrorMessage={
                                               setReloadErrorMessage
                                           }
                                           statusTargetSelected={
                                               statusTargetSelected
                                           }
                                           setStatusTargetSelected={
                                               setStatusTargetSelected
                                           }
                                           setReloadStatusTargetSelected={
                                               setReloadStatusTargetSelected
                                           }
                                           reloadTargets={reloadTargets}
                                           setReloadTargets={setReloadTargets}
                                           reportCardErrors={reportCardErrors}
                                           setReportCardErrors={setReportCardErrors}
                                        />
                                        {/*data={plans} AlertsData={setAlertsData} setReloadPostData={setReloadPostData} setSelected={setSelected} setReloadSelected={setReloadSelected} setCheckboxSelected={setCheckboxSelected} checkboxSelected={checkboxSelected} setIntensityErrorReport={setIntensityErrorReport} setReloadErrorMessage={setReloadErrorMessage} setConditionErrorReport={setConditionErrorReport} setAcumulatedErrorReport={setAcumulatedErrorReport} setProb_lightningErrorReport={setProb_lightningErrorReport} setWindErrorReport={setWindErrorReport} setMaxTempErrorReport={setMaxTempErrorReport} setMinTempErrorReport={setMinTempErrorReport} setLevelErrorReport={setLevelErrorReport} setWindVelErrorReport={setWindVelErrorReport} setWindSprintErrorReport={setWindSprintErrorReport} statusTargetSelected={statusTargetSelected} setStatusTargetSelected={setStatusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected}*/}
                                    </TabPanel>
                                    <TabPanel
                                        value={reportTabsIndex}
                                        index={2}
                                        dir={theme.direction}
                                    >
                                        <SpecialReportCard
                                            alertsData={alertsData}
                                            setAlertsData={setAlertsData}
                                            setReloadPostData={
                                                setReloadPostData
                                            }
                                            setSelected={setSelected}
                                            setReloadSelected={
                                                setReloadSelected
                                            }
                                            setCheckboxSelected={
                                                setCheckboxSelected
                                            }
                                            checkboxSelected={checkboxSelected}
                                            confirmTargetsList={
                                                confirmTargetsList
                                            }
                                            setReloadSwitchSelected={
                                                setReloadSwitchSelected
                                            }
                                            allSelected={allSelected}
                                            setAllSelected={setAllSelected}
                                            setReloadErrorMessage={
                                                setReloadErrorMessage
                                            }
                                            statusTargetSelected={
                                                statusTargetSelected
                                            }
                                            setStatusTargetSelected={
                                                setStatusTargetSelected
                                            }
                                            setReloadStatusTargetSelected={
                                                setReloadStatusTargetSelected
                                            }
                                            reloadTargets={reloadTargets}
                                            setReloadTargets={setReloadTargets}
                                            reportCardErrors={reportCardErrors}
                                            setReportCardErrors={setReportCardErrors}
                                        />
                                    </TabPanel>
                                </SwipeableViews>
                            </TabPanel>
                            <TabPanel
                                value={rightSideTabs}
                                index={1}
                                dir={theme.direction}
                            >
                                <WeatherWarning
                                    alertsData={alertsData}
                                    setAlertsData={setAlertsData}
                                    setReloadPostData={setReloadPostData}
                                    setSelected={setSelected}
                                    setReloadSelected={setReloadSelected}
                                    confirmTargetsList={confirmTargetsList}
                                    setReloadSwitchSelected={
                                        setReloadSwitchSelected
                                    }
                                    allSelected={allSelected}
                                    setAllSelected={setAllSelected}
                                    setReloadErrorMessage={
                                        setReloadErrorMessage
                                    }
                                    statusTargetSelected={statusTargetSelected}
                                    setStatusTargetSelected={
                                        setStatusTargetSelected
                                    }
                                    setReloadStatusTargetSelected={
                                        setReloadStatusTargetSelected
                                    }
                                    reloadTargets={reloadTargets}
                                    setReloadTargets={setReloadTargets}
                                    loadingLeftSide={loadingLeftSide}
                                    weatherAlertErrors={weatherAlertErrors}
                                    setWeatherAlertErrors={
                                        setWeatherAlertErrors
                                    }
                                />
                            </TabPanel>
                        </SwipeableViews>
                    </RightSide_ContentDiv>

                    <RightSide_FooterDiv>
                        <Grid item xs={0.5} className={classes.gridContainer}>
                            <Switch
                                checked={allSelected}
                                onChange={() => toggleAllSelected()}
                                value={allSelected}
                                size="small"
                                classes={{
                                    colorPrimary: tabStyle.switch_primary,
                                }}
                                color={'primary'}
                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '8px' }}>
                            <Typography
                                variant="subtitle1"
                                style={fonts.planName}
                            >
                                Selecionar todos
                            </Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.gridButton}>
                            <Button
                                style={fonts.buttonDefault}
                                variant="contained"
                                className={
                                    rightSideTabs === 1
                                        ? classes.buttonWeather
                                        : classes.buttonReport
                                }
                                onClick={() => postAlert()}
                            >
                                Aprovar selecionados
                                <div className={classes.iconContainer}>
                                    {rightSideTabs === 1 ? (
                                        <FaCloudSun
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                color: '#000000',
                                            }}
                                        />
                                    ) : (
                                        <FaNewspaper
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                color: '#C0A514',
                                            }}
                                        />
                                    )}
                                </div>
                            </Button>
                        </Grid>
                    </RightSide_FooterDiv>
                </RightSide>

                {openConfirmationModal && (
                    <ConfirmationModal
                        rightSideTabs={rightSideTabs}
                        statusTargetSelected={statusTargetSelected}
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        alertsData={alertsData}
                        setAlertsData={setAlertsData}
                    />
                )}

                {openUsersModal && (
                    <UsersModal
                        rightSideTabs={rightSideTabs}
                        statusTargetSelected={statusTargetSelected}
                        openUsersModal={openUsersModal}
                        setOpenUsersModal={setOpenUsersModal}
                        alertsData={alertsData}
                        setAlertsData={setAlertsData}
                    />
                )}
            </Body>
        </Modal>
    );
};

ForecastPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    tab: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForecastPanel);
