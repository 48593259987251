import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useLoad from 'hooks/useLoad';
import useTogglePlans from 'hooks/useTogglePlans';
import useToggle from 'hooks/useToggle';
import { useTarget } from 'Context/TargetContext';

export const ClientTargetStateContext = createContext();

export const ClientTargetStateContextProvider = (props) => {
    const { planosCliente: clientTargets} = useTarget();
    const toShow = useTogglePlans(clientTargets, false);
    const [ mergeTargetsOption, toggleTargetMerge ] = useToggle(false);
    const [ showTargets, toggleTargets ] = useToggle(true)

    useEffect(() => {
      if(clientTargets){
        toShow.newData(clientTargets, true)
      }
    }, [JSON.stringify(clientTargets)])

    return (
        <ClientTargetStateContext.Provider
            value={{
                clientTargets,
                toShow,
                mergeTargetsOption,
                toggleTargetMerge,
                showTargets,
                toggleTargets,
            }}
        >
            {props.children}
        </ClientTargetStateContext.Provider>
    );
};

ClientTargetStateContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ClientTargetStateContextProvider;

export const useClientTargetsState = () => {
  const context = useContext(ClientTargetStateContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
