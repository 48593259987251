import React, { useEffect, memo, useState } from 'react';
import {useTarget} from 'Context/TargetContext';
import {useHistoryTarget} from 'Context/HistoryTargetContext';
import {IntersectionLayer} from './IntersectionLayer';
import {useTargetState} from 'Context/TargetStateContext';
import {Target} from './Target';
import moment from 'moment'
import useLoad from 'hooks/useLoad'
import { getHolidays } from "services/adminDashboard/holidays";
import { getTargets } from "services/historyDischarges";
import {useTools} from 'Context/ToolsContext';
import { useDispatch, useSelector } from 'react-redux';
import { historyDischargesDataProvider } from 'redux/actions/historyDischargesDataAction';
import { historyDischargesControlProvider } from 'redux/actions/historyDischargesControlAction';
import { historyDischargesBuffersProvider } from 'redux/actions/historyDischargesBuffersAction';
import { historyDischargesBuffersControlProvider } from 'redux/actions/historyDischargesBuffersControlAction';
import { useActives } from 'Context/ActivesContext';

const TargetLayerComponent = () => {
    const { planos, toShow, showTargets} = useTarget();
    const { historyTargets } = useHistoryTarget();
    const { historyActives } = useActives();
    const { targetState } = useTargetState();
    const { polygon } = useTools();
    const dispatch = useDispatch();
    const { data } = useLoad(() => getHolidays(moment().format("YYYY")), [])
    const polygonSelected = useSelector(state => state.polygonProvider);
    const dischargeHistory = polygonSelected && polygon.finishPolygon && polygon.polygonLine.length > 3;
    const activedTarget = useSelector(state => state.historyDischargesControlProvider);
    const activedBuffer = useSelector(state => state.historyDischargesBuffersControlProvider);
    const [reload, setReload] = useState(false);

    useEffect(() => {
      if(dischargeHistory && Object.keys(historyTargets).length > 0){
        dispatch(historyDischargesDataProvider(historyTargets.features, historyActives?.features));
        let temp1 = [];
        let temp2 = [];
        let temp3 = [];
        historyTargets?.features?.map((target) => {temp1.push(true)});
        dispatch(historyDischargesControlProvider(temp1, !activedTarget[1]));
        historyTargets?.features?.map((target) => {temp2.push({id_alvo: target.properties.id_alvo, features: target, buffers: [4, 10, 20], colors: ["#FB1C29", "#FDA731", "#118014"]})});
        dispatch(historyDischargesBuffersProvider(temp2));
        historyTargets?.features?.map((target) => {temp3.push([true, true, true])});
        dispatch(historyDischargesBuffersControlProvider(temp3, !activedBuffer[1]));
        setReload(true);
      }
    }, [historyTargets])

    useEffect(() => {
        setReload(true);
    }, [])

    useEffect(() => {
      if(reload){
        setReload(false);
      }
    }, [reload])

    const filterHolidays = () => {
      const today = moment().format('YYYY-MM-DD')
      return [...data].filter(holiday => holiday.date === today).length
    }

    const getFunc = (time, data, check) => {
      const mapDays = [
        'Dom',
        'Seg',
        'Ter',
        'Qua',
        'Qui',
        'Sex',
        'Sab',
      ]

      const dayOfWeek = Number(time.format('d'));
      var result;

      if(check === 'Plan'){
        data?.some((mon) => {
          if(!mon[0].includes(mapDays[dayOfWeek])) return false;
          return time.isBetween(
            moment(mon[0].slice(mon[0].indexOf(`_inicio":"`) + 10, mon[0].indexOf(`","datafunc`)), 'hh:mm:ss'),
            moment(mon[0].slice(mon[0].indexOf(`hora_fim`) + 11, mon[0].indexOf(`}"`) -1), 'hh:mm:ss')
          )
        })
        
        result = result != undefined ? result : false;
      }
      else if(check === true){
        /*result = data.some((service) => {
          if(!service.monitoramento.includes(mapDays[dayOfWeek])) return false;
          return time.isBetween(
            moment(service.monitoramento.slice(service.monitoramento.indexOf(`_inicio":"`) + 10, service.monitoramento.indexOf(`","datafunc`)), 'hh:mm:ss'),
            moment(service.monitoramento.slice(service.monitoramento.indexOf(`hora_fim`) + 11, service.monitoramento.indexOf(`}"`) -1), 'hh:mm:ss')
          ) ?
            service.hora_extra?
              time.isBetween(moment(service.datafunc_dia_inicio), moment(service.datafunc_dia_fim))
                : true
          : false
       })
       result = result != undefined ? result : false;*/
       result = true;
      }
      return true;
    }

    const isHoliday = filterHolidays();

    if (!showTargets && !dischargeHistory) return <></>;
    if ((planos === undefined || !planos || planos.length === 0) && !dischargeHistory) return <></>;
    return (
      <>
        {!dischargeHistory &&
          <>
            <IntersectionLayer />
            {planos?.map(company => company.planos.map((plan) => {
              const onTimePlan = !plan.feriados && isHoliday > 0 ? false : getFunc(moment(), plan.monitoramento, "Plan");
              return plan.alvos.map((target) => {
                if(!toShow.get(company.id, plan.id, target.id)) return null
                  const onTimeService = getFunc(moment(), target, onTimePlan);
              
                  return (
                    <div key={target.id}>
                      <Target target={target} targetState={targetState[target.id_alvo]} onTime={onTimePlan} type={"discharges"}/>
                    </div>
                  )
                }
              )}
            ))}
          </>
        }

        {dischargeHistory && Object.keys(historyTargets).length > 0 &&
          historyTargets?.features?.map((target, index) => {
            if(activedTarget[0][index]){
              return (
                <div key={index}>
                  <Target target={target} type={"historyTargets"} index={index} />
                </div>
              )
           }
          })    
        }
      </>
    )
};

export const TargetLayer = memo(TargetLayerComponent);
