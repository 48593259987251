const styles = (theme) => ({
    formsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },

    lineContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: '5px',
        height: '50px',
    },

    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
    },

    reportCardField: {
        width: '100%',
    },

    circularProgress: {
        top: 'calc(50%)',
        left: 'calc(50%)',
    },

    wheaterButton: {
        width: '144px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '11px 11px',
        gap: '10px',
        backgroundColor: '#000000',
        borderRadius: '5px',
        color: '#FFFFFF',
    },

    reportButton: {
        display: 'flex',
        flexDirection: 'row',
        height: "40px",
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#C0A514',
        borderRadius: '5px',
        color: '#FFFFFF',
    },

    reportButtonSpecial: {
        display: 'flex',
        flexDirection: 'row',
        height: "40px",
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#C0A514',
        borderRadius: '5px',
        color: '#FFFFFF',
        marginTop: "20px"
    },

    dateField: {
        width: '140px',
    },

    selectFieldSmall: {
        width: `62px`,
        marginTop: `0px`,
    },

    textFieldWeatherIndex: {
        width: '100%',
    },

    endAdornment: {
        color: theme.palette.menu.mediumGrey,
    },

    filePresentContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '144px',
    },

    filePresentClose: {
        width: '16px',
        height: '16px',
        marginLeft: '80%',
        position: 'static',
    },

    fileRemoveContainer: {
        cursor: 'pointer',
        width: '144px',
        maxHeight: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    fileNameText: {
        fontSize: '12px',
    },
    overflowedFileNameText: {
        fontSize: '12px',
    },

    errorMessage: {
        fontSize: `11px`,
        color: 'red',
        margin: '5px 0 0 5px',
    },

    viewButton: {
        cursor: 'pointer',
        border: '2px solid',
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $text': {
            color: theme.palette.menu.strongBlue,
        },
        '& $icon': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $text': {
                color: theme.palette.menu.white,
            },
            '& $icon': {
                color: theme.palette.menu.white,
            },
        },
    },

    disabledViewButton: {
        cursor: 'not-allowed',
        border: '2px solid',
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $text': {
            color: theme.palette.menu.strongBlue,
        },
        '& $icon': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $text': {
                color: theme.palette.menu.white,
            },
            '& $icon': {
                color: theme.palette.menu.white,
            },
        },
    },

    text: {
        color: theme.palette.text.strongBlue,
    },
    icon: {
        color: theme.palette.text.strongBlue,
    },

    //Boletim

    container: {
        width: '100%',
    },

    allChangeContainer: {
        backgroundColor: '#f5f2f2',
        width: '100%',
        padding: "8px 8px 18px 8px",
        borderRadius: "8px"
    },

    inputLine: {
        display: 'flex',
        width: '100%',
        height: '50px',
        marginBottom: '5px'
    },

    inputOne: {
        width: '70%',
        marginRight: '10px',
    },

    inputTwo: {
        width: '30%',
    },

    inputThree: {
        width: '22%',
        marginRight: '10px',
    },

    inputFour: {
        width: '27%',
        marginRight: '10px',
    },

    inputFive: {
        width: '15%',
        marginRight: '10px',
    },

    inputSix: {
        width: '18%',
        marginRight: '10px',
    },

    inputSeven: {
        width: '18%',
    },

    inputEight: {
        width: '65%',
        marginRight: '10px',
    },

    inputNine: {
        width: '15%',
        marginRight: '10px',
    },

    inputTen: {
        width: '20%',
    },

    inputEleven: {
        width: '100%',
    },
    inputElevenMargin: {
        width: '100%',
        marginRight: '10px',
    },
    //fieldsComponent

    switchComponent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '15px 0 ',
    },
});

export default styles;
