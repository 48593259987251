import React, { useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider } from '@material-ui/core';
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    weatherWarning: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#000000",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#000000'
      }
    },
    reportCard1: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#C0A514",
      },
      "& .MuiTabs-root":{
        backgroundColor: "#C0A514",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#C0A514',
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
    divider: {
      background: "#848484",
      border: "1px solid"
    },
  })


const ReportTabs = (props) => {
    const { reportTabsIndex, setReportTabsIndex } = props;
    const tabStyle = useStyles();

    const handleChangeTabs = (event, newValue) => {
        setReportTabsIndex(newValue);
    };

    return (
        <>
            <Tabs
                value={reportTabsIndex}
                onChange={handleChangeTabs}
                className={tabStyle.reportCard1}
                variant="fullWidth"
                aria-label="reportTabs"
                style={{ height: "100%", display: "flex", alignItems: "center" }}
                centered={true}
            >
                <Tab label="Boletim Diário" />
                <Tab label="Boletim Nowcasting" />
                <Tab label="Boletim Especial" />
            </Tabs>

            <Divider classes={{ root: tabStyle.divider }} />
        </>
    )

}

ReportTabs.propTypes = {
    //classes: PropTypes.object.isRequired,
    reportTabsIndex: PropTypes.any,
    setReportTabsIndex: PropTypes.func,
  };

export default ReportTabs;
