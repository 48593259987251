// Libs
import PropTypes from 'prop-types';
// Components
import {Close} from '@material-ui/icons';
import {Column} from 'components/Flex';
import {CloseButton, ControlHeader, InfosPanelDiv, StyledRow} from './styled';
import './ControlPanel.css';
// Hooks
import {useControlPanel} from 'Context/ClientControlPanelContext';
import fontStyles from "styles/fonts-jss";



const InfosPanel = (props) => {
  const { showInfosPanel, toggleInfosPanel} = useControlPanel();
  const fonts = fontStyles();

  return !showInfosPanel ? undefined : (
    <InfosPanelDiv>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>LEGENDA</span>
        </div>
        <CloseButton onClick={toggleInfosPanel}><Close /></CloseButton>
      </ControlHeader>
      <Column w='90%' gap='6px' margin='0 0 8px 0'>
          <span style={fonts.popUpContentTopic}>
              {`DESCARGAS`} 
          </span>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#A8050E"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"10 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#ED5A35"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"20 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#18A91C"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"30 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#00FFFF"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"40 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#0000FF"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"50 minutos"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#AAAAAA"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"60 minutos"} 
            </span>
          </StyledRow>
          <span style={fonts.popUpContentTopic}>
              {`ALVOS`} 
          </span>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#B067E9"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"Buffer de 4 km em alerta"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#FB1C29"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"Buffer de 10 km"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#FDA731"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"Buffer de 20 km"} 
            </span>
          </StyledRow>
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <input type="color" value={"#118014"} disabled={true}></input>
            <span style={fonts.popUpContentItem}>
              {"Buffer de 30 km"} 
            </span>
          </StyledRow>
      </Column>
    </InfosPanelDiv>
  )
}

InfosPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};
  
export default InfosPanel;
