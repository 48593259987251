import {useState, useEffect, useRef} from 'react'

// Libs
import p from 'prop-types';
import { bbox } from '@turf/turf';

// Components
import {Column, Row, ItemContent} from 'components/Flex';
import {TargetItem, Span} from '../PlansTab/styled';
import {Checkbox} from '@material-ui/core';
import {InfoDiv, Property} from '../styled';
import Collapsible from 'components/Collapsible';
import fontStyles from "styles/fonts-jss";
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import * as turf from '@turf/turf'

// Icons
import {MdLocationSearching} from 'react-icons/md';
import {MdExpandMore} from 'react-icons/md';

// Hooks
import {useMapContext} from 'Context/MapContext';
import useToggle from 'hooks/useToggle';
import { useToast } from 'Context/ToastContext';

// Services
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';
import { patchTarget } from 'services/adminDashboard/target';

const useStyles = makeStyles(theme => ({
    root: {
      width: 36,
      height: 19
    },
    track: {
      height: 8,
    },
    thumb: {
      "& .MuiSwitch-thumb": {
        width: 14,
        height: 14,
        border: "0.5px solid #dedede",
      },
    }
  }));
  

const TargetInfo = ({company, plan, target, checkboxes, checked, setReloadData }) => {
  const [show, toggleShow] = useToggle(false);
  const {setGoTo} = useMapContext();
  const [targetData, setTargetData] = useState([]);
  const [status, setStatus] = useState(target.status);
  const [automaticAlert, setAutomaticAlert] = useState(target.alerta_automatico);
  const [activeStatus, setActiveStatus] = useState(target.ativos_ativo);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const openToast = useToast();
  const ref = useRef(null);
  const styles = useStyles();
  const moment = require('moment');
  moment.locale('pt-br');
  const fonts = fontStyles();
  const [putData, setPutData] = useState({
    nome: target.nome,
    apelido: target.apelido,
    coordenadas: target.coordenadas,
    qntd_sms: target.qntd_sms,
    qntd_email: target.qntd_email,
    qntd_telegram: target.qntd_telegram,
    qntd_wpp: target.qntd_wpp,
    alerta_automatico: target.alerta_automatico,
    status: target.status,
    tipo: target.tipo,
    antecedencia: target.antecedencia,
    unidade_medida: target.unidade_medida,
    ativos: target.ativos,
    ativos_ativo: target.ativos_ativo,
    buffers_padrao: target.buffers_padrao,
    buffers: target.buffers,
    cor: target.cor,
  })

  const toggleAutomaticAlert = async () => {
    putData.alerta_automatico = !automaticAlert;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = putData.coordenadas.features? typeof putData.coordenadas.features[0].geometry != "object"? JSON.parse(putData.coordenadas.features[0].geometry) : putData.coordenadas.features[0].geometry : putData.coordenadas;

    await patchTarget(target.id_alvo, putData).then((response) => {
      if(response.status === 200) {
        setReloadData(true);
        setAutomaticAlert(!automaticAlert);
        openToast("success", `Alerta automático ${!automaticAlert === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar alerta automático, recarregue a página e tente novamente!");
    });
  }

  const toggleStatusActive = async () => {
    putData.ativos_ativo = !activeStatus;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = putData.coordenadas.features? typeof putData.coordenadas.features[0].geometry != "object"? JSON.parse(putData.coordenadas.features[0].geometry) : putData.coordenadas.features[0].geometry : putData.coordenadas;

    await patchTarget(target.id_alvo, putData).then((response) => {
      if(response.status === 200) {
        setReloadData(true);
        setActiveStatus(!activeStatus);
        openToast("success", `Status dos ativos ${!activeStatus === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar o status dos ativos, recarregue a página e tente novamente!");
    });
  }

  const toggleStatus = async (company_id, plan_id, target_id) => {
    putData.status = !status;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = putData.coordenadas.features? typeof putData.coordenadas.features[0].geometry != "object"? JSON.parse(putData.coordenadas.features[0].geometry) : putData.coordenadas.features[0].geometry : putData.coordenadas;

    await patchTarget(target.id_alvo, putData).then((response) => {
      if(response.status === 200) {
        if(!status === true){
          if(!checkboxes.get(company_id, plan_id, target_id)){
            checkboxes.toggle(company_id, plan_id, target_id)
          }
        }
        else if(!status === false){
          if(checkboxes.get(company_id, plan_id, target_id)){
            checkboxes.toggle(company_id, plan_id, target_id)
          }
        }
        setStatus(!status);
        openToast("success", `Status do alvo ${!status === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar o status do alvo, recarregue a página e tente novamente!");
    });
  }

  const handleGoToTarget = (feature) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
    setGoTo([
      [minLng, minLat],
      [maxLng, maxLat]
    ]);
  }

  useEffect(() => {
    if(show && loadingTargetData === 0)  loadTargetData();
  }, [show, loadingTargetData])

  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const setServices = (data) => {
    var today = moment().format('dddd');
    today = today === "sábado"? "sabado" : today;
    var now = moment();
    var stringfyLightningAlert = data? data.filter((alert) => alert.tipo === "Alerta Raio" && alert.status === true) : [];
    var parsedLightningAlert = [];
    stringfyLightningAlert?.length > 0? stringfyLightningAlert.map((alert) => parsedLightningAlert.push({id_service : alert.id_servico, monitoring: alert.monitoramento})) : "";
    parsedLightningAlert?.length > 0?  parsedLightningAlert.map((alert) => {
      alert.monitoring.map((days, index) => {
        var aux = [];
        if(typeof days[0] === "string"){
          aux.push(JSON.parse(days))
          alert.monitoring[index] = aux;
        }
      });
    }) : "";

    var test = parsedLightningAlert?.length > 0? parsedLightningAlert.map((alert) => 
      alert.monitoring.some((days) => 
        days[0].datafunc_dias.includes((today[0].toUpperCase() + today.slice(1, 3) + ", ")) && 
        now.isBetween(
          moment(days[0].datafunc_hora_inicio, 'hh:mm:ss'),
          moment(days[0].datafunc_hora_fim, 'hh:mm:ss')
        )
      )
    ) : [];

    var lightningAlert = test.length > 0 && test.includes(true)? data.filter((alert) => alert.id_servico === parsedLightningAlert[test.indexOf(true)].id_service) : "";

    const setDays = (data) => {
      var output = "";

      for(let i = 0; i < data.length; i++) {
        if(i === data.length - 1 && i != 0){
          output = output + data[i].slice(0, 3);
        }
        else if(i != data.length - 1){
          output = output + data[i];
        }
        else{
          output = data[i].slice(0, 3);
        }
      }
      return output;
    }

    if(data && data[0]){
      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {lightningAlert != ""?
            lightningAlert[0].monitoramento.map((days, index) =>  
              <div key={index} style={{display: "flex", flexDirection: "column"}}>
                <span style={fonts.mapSidebarTargetDescripitionContent}>
                  {`${setDays(days[0].datafunc_dias)} - ${days[0].datafunc_hora_inicio} às ${days[0].datafunc_hora_fim}`}
                </span>
              </div>
            ) :
            parsedLightningAlert.length > 0?
              parsedLightningAlert[0].monitoring.map((days, index) =>
                <div key={index} style={{display: "flex", flexDirection: "column"}}>
                  <span className='overflowText' style={fonts.mapSidebarTargetDescripitionContent}>
                    {`${setDays(days[0].datafunc_dias)} - ${days[0].datafunc_hora_inicio} às ${days[0].datafunc_hora_fim}`}
                  </span>
                </div>
              ) :
                <span style={fonts.mapSidebarTargetDescripitionContent}>
                  Não contemplado
                </span>
          }
        </div>
      return output;
    }
    else{
      return  <span style={fonts.mapSidebarTargetDescripitionContent}>
                Não contemplado
              </span>
    }
  }

  const setManagers = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0 && data[i].id_permissao === 4){
          output = output + ", " + data[i].nome;
        }
        else if(i === 0 && data[i].id_permissao === 4){
          output = data[i].nome;
        }
      }
    }
    return output;
  }

  const setContacts = (data) => {
    if(data && data[0]){
      var contactData = [];
      data.map((user) => user.prioridade? contactData.push(user) : contactData);

      const sortData = ( a, b ) => {
        if ( a.prioridade.prioridade < b.prioridade.prioridade ){
          return -1;
        }
        if ( a.prioridade.prioridade > b.prioridade.prioridade ){
          return 1;
        }
        return 0;
      }

      contactData.sort(sortData);

      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {contactData.map((user, index) => {
            var phone = "";
            user.prioridade.telefone.map((number, index) => index != user.prioridade.telefone.length -1? phone = phone + number + ", " : phone = phone + number);
           
            if(user.prioridade){
              return ( 
                <div key={index} style={{display: "flex", flexDirection: "column"}}>
                  <span style={fonts.mapSidebarTargetDescripitionItem}>{`${user.prioridade.prioridade} - ${user.nome}`}</span>
                  <div style={{height: "15px", alignItems: "center", display: "flex"}}>
                    <span style={fonts.mapSidebarTargetDescripitionItem}>Horário:&nbsp;</span>
                    <span style={fonts.mapSidebarTargetDescripitionContent}>{`${user.prioridade.datafunc_hora_inicio} às ${user.prioridade.datafunc_hora_fim}`}</span>
                  </div>
                  <div style={{height: "15px", alignItems: "center", display: "flex"}}>
                    <span style={fonts.mapSidebarTargetDescripitionItem}>Telefone:&nbsp;</span>
                    <span style={fonts.mapSidebarTargetDescripitionContent}>{`${phone}`}</span>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                    <span style={fonts.mapSidebarTargetDescripitionItem}>Observação:</span>
                      <div style={{padding: "0 0 0 10px", display: "flex"}}>
                        <span style={fonts.mapSidebarTargetDescripitionContent}>{`${user.prioridade.observacao}`}</span>
                      </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      return output;
    }
    else{
      return "";
    }
  }


  return (
    <Collapsible
      key={`sidebaralvo-${target.id}`}
      isOpen={show}
      main={(
        <TargetItem ml='32px' checked={checked} ref={ref}>
          <Checkbox
            label={target.nome}
            onChange={() => checkboxes.toggle(company.id, plan.id, target.id)}
            checked={checkboxes.get(company.id, plan.id, target.id)}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent>
            {/* --------------- NOME E ÍCONE DE ALVO  ----------------------- */}
            <Row>
              <Span ml='32px'>
                {target.nome}
              </Span>
              <MdLocationSearching style={{ fontSize: 20, marginLeft: "10px"}} onClick={() => handleGoToTarget(target.coordenadas)} />
            </Row>
            {/* --------------- SETA / DROPDOWN  ----------------------- */}
            <Row isOpen={show}>
              {show?
                <MdExpandMore style={{fontSize: '32px'}} onClick={toggleShow}/> :
                <MdExpandMore style={{fontSize: '32px', transform: 'rotateZ(-90deg)', transition: "0.3s"}} onClick={toggleShow}/> 
              }
            </Row>
          </ItemContent>
        </TargetItem>
      )}
    >
      {/* --------------- IFORMAÇÕES DO PLANO  ----------------------- */}
      <Column align='flex-start' margin='0px 0px 0px 32px'>
        {loadingTargetData === 0?
          <span style={fonts.mapSidebarTargetDescripitionItem}>Carregando</span> :
            loadingTargetData === 2?
              <span style={fonts.mapSidebarTargetDescripitionItem}>Problema ao carregar informações, recarregue a página e tente novamente!</span> :
                (
                  <InfoDiv checked={checked}>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Status plano: </span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{targetData.plano[0].status === 1? 'Ativo' : targetData.plano[0] === 0? 'Inativo' :  'Supenso'}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Início monitoramento: </span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{targetData.plano[0].inicio_contrato.split('-').reverse().join('/')}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Feriados: </span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{targetData.plano[0].feriados? "Sim" : "Não"}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Status alvo: </span>
                      <Switch
                        checked={status}
                        onChange={() => toggleStatus(company.id, plan.id, target.id)}
                        value={status}
                        classes={{
                          root: styles.root,
                          track: styles.track,
                        }}
                        className={styles.thumb}
                        size="small"
                        color={"primary"}
                      />
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Alerta automatico: </span>
                      <Switch
                        checked={automaticAlert}
                        onChange={() => toggleAutomaticAlert()}
                        value={automaticAlert}
                        size="small"
                        classes={{
                          root: styles.root,
                          track: styles.track,
                        }}
                        className={styles.thumb}
                        color={"primary"} 
                      />
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Status ativo: </span>
                      <Switch
                        checked={activeStatus}
                        onChange={() => toggleStatusActive()}
                        value={activeStatus}
                        size="small"
                        classes={{
                          root: styles.root,
                          track: styles.track,
                        }}
                        className={styles.thumb}
                        color={"primary"} 
                      />
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Antecedência:</span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{target.antecedencia}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Funcionamento: </span>
                      <div>{setServices(targetData.servicos)}</div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Gestores:</span>
                      <div>{<span style={fonts.mapSidebarTargetDescripitionContent}>{setManagers(targetData.usuarios)}</span>}</div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Contatos:</span>
                      <div>{setContacts(targetData.usuarios)}</div>
                    </Property>
                  </InfoDiv>
                )
        }
      </Column>
    </Collapsible>
  )
}

TargetInfo.propTypes = {
  target: p.any,
  plan: p.any,
  company: p.any,
  checkboxes: p.any,
  checked: p.bool,
  setReloadData: p.func,
  mapRef: p.any,
}
export default TargetInfo
