import {useState, useEffect, useRef} from 'react'
import { monitoringNotification, forecastNotification } from 'services/mapsPortal/initialLoad';
import useLoad from 'hooks/useLoad';
import {Alert, RowDiv, Button, TabButton, HeaderNotif, NotifDiv, NotifTitle, NotifScroll, NotifCard, NotifBackground, CloseButton, ReleaseBackground, RainAlertBackground, ReportCardBackground, ReportCard} from './styled';
import useToggle from 'hooks/useToggle';
import useTargetHelper from 'hooks/useTargetHelpers';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import { Close, MyLocation } from '@material-ui/icons';
import {useControlPanel} from 'Context/ControlPanelContext';
import { Howl } from 'howler';
import { openMapSidebarProvider } from 'redux/actions/openMapSidebar';
import { useDispatch } from 'react-redux';

const Alerts = () => {
  const prevAlerts = useRef([]);
  const [closedAlerts, setClosedAlerts] = useState({});
  const {data: monitoringData, update: updateMonitoring} = useLoad(monitoringNotification, []);
  const {data: forecastData, update: updateForecast} = useLoad(forecastNotification, []);
  const { showAlerts: open, toggleShowAlerts: toggleOpen} = useControlPanel();
  const [runAnimation, toggleAnimation] = useToggle(false);
  const [reload, setReload] = useState(false);
  const [closedForecasts, setClosedForecasts] = useState([]);
  const Helper = useTargetHelper();
  useInterval(updateMonitoring, 10000);
  useInterval(updateForecast, 10000);
  const dispatch = useDispatch();

  // Inicia animação to alerta, quando chega alerta com alvo novo;
  useEffect(() => {
    // Includes testa se todos os alvos já existiam nos alertas anteriores.
    // Caso algum alerta não passe no teste, ele será falso e portanto iniciará
    // a animação de alerta.
    // Também verificar se é necessária a emissão de um novo alerta, devido a
    // diminuição da distância;
    let includes = true
    prevAlerts.current = monitoringData.map((alerta) => {
      const index = prevAlerts.current.findIndex((a) => a.id_alvo === alerta.id_alvo && a.tipo === alerta.tipo)
      includes = includes
        ?  index >= 0 && (prevAlerts.current[index]?.distancia <= alerta?.distancia || alerta.tipo !== 'raio')
        : false;
      return {id_alvo: alerta.id_alvo, distancia: alerta?.distancia, tipo: alerta.tipo};
    });

    if (!includes && monitoringData.length > 0){
      toggleAnimation(true);
    }

    filterClosedUnused()
  }, [JSON.stringify(monitoringData), JSON.stringify(closedAlerts.current)])

  const filterClosedUnused = () => {
    setClosedAlerts(prev => {
      // const prev = {...p}
      Object.keys(prev).forEach(key => {
        if (monitoringData.findIndex(alert => prev[key].tipo === alert.tipo && prev[key].id_alvo === alert.id_alvo) === -1)
          delete prev[key]
      })
      return prev
    })
  }

  const handleClick = () => {
    if (runAnimation){
      toggleAnimation(false)
    }else{
      toggleOpen();
    }
  }
  const setReportCardData = (reportCardData) => {
    return reportCardData.map((reportCard, index) => <span key={index}>{reportCard.nome_alvo}</span>);
  }


  const handleCloseAlert = (alert) => {
    setClosedAlerts(p => {
      const closedRef = {...p}
      const key = `${alert.tipo} - ${alert.id_alvo}`;
      if(!closedRef[key]){
        closedRef[key] = {...alert, firstClose: moment(), closeTime: moment(), closedTimes: 1};
      }else{
        closedRef[key].closedTimes = closedRef[key].closedTimes + 1;
        closedRef[key].closeTime = moment();
      }
      return closedRef;
    })
  }

  const handleCloseReportCard = () => {
    forecastData.map((reportCard) => reportCard.closed = true);
    setClosedForecasts(forecastData.map((reportCard) => {return {...reportCard}}))
    setReload(true);
  }
  
  const deleteId = (id) => {
    setClosedAlerts(p => {
      const newAlerts = {...p};
      delete newAlerts[id];
      return newAlerts
    })
  }

  const playNotificationSound = (type) => {
    var src = type === 'Alerta Raio'? 'https://empresas.s3.amazonaws.com/sons_alertas/alerta_raio.mp3' :
    'https://empresas.s3.amazonaws.com/sons_alertas/liberacao_cliente.mp3';

    const sound = new Howl ({
        src,
        html5: true,
    })

      sound.play();
  }

  const testClosedAlerts = () => {
    return monitoringData.filter((alert) => {
      const key = `${alert.tipo} - ${alert.id_alvo}`;
      if (!closedAlerts[key]) return true;
      if (alert.tipo !== 'raio') return false;
      const time = moment().diff(closedAlerts[key].closeTime, 'seconds')
      if (alert.tipo === 'raio' && closedAlerts[key]?.distancia > alert?.distancia){
        deleteId(key)
        return true
      }
      if (closedAlerts[key].closedTimes >= 3) return false
      if (moment().diff(closedAlerts[key].firstClose, 'minutes') >= 10) return false
      if (alert?.distancia < 4) return time >= 30
      return time >= 60
    })
  }

  const alertsToShowAux = testClosedAlerts();
  const lightningAlerts = alertsToShowAux.filter((alert) => alert.tipo === 'raio');
  const releaseAlerts = alertsToShowAux.filter((alert) => alert.tipo === 'liberacao_raio');
  const alertsToShow = alertsToShowAux.concat(forecastData);

  useEffect(() => {
    if(lightningAlerts.length > 0){
      playNotificationSound("Alerta Raio");
    }
  }, [lightningAlerts.length])

  useEffect(() => {
    if(releaseAlerts.length > 0){
      playNotificationSound("Liberacao");
    }
  }, [releaseAlerts.length])

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload])

  useEffect(() => {
      setReload(false);
  }, [forecastData])

  useInterval(() => playNotificationSound(), 1800000);

  return (
    <>
      {/* {alertsToShow.length && ( */}
        <>
          <Alert
            onClick={handleClick}
            run={runAnimation}
            level={alertsToShowAux[0]?.distancia < 4 ? 3 : alertsToShowAux[0]?.distancia < 10 ? 2 : 1}>
            <span>{alertsToShowAux.length}</span>
          </Alert>
          {forecastData.length &&
            <ReportCard
              run={runAnimation}
              display={closedForecasts.length > 0? closedForecasts.filter((forecast) => forecast.closed === false)?.length > 0? true : false : forecastData.length > 0? true : false}
            >
              <span>{closedForecasts.length > 0? closedForecasts.filter((forecast) => forecast.closed === false)?.length : forecastData.length}</span>
            </ReportCard>
          }
        </>
      {/* )} */}
      {alertsToShow.length && (
        <NotifDiv open={open} animationRunning={runAnimation}>
          <NotifTitle>
            <CloseButton onClick={() => toggleOpen(false)}>
              <Close/>
            </CloseButton>
            <h2>NOTIFICAÇÕES</h2>
          </NotifTitle>
          
          <NotifScroll>
            {alertsToShow.map((alert) => {
              if (alert.tipo === 'raio')
                return(
                  <NotifBackground
                    level={alert?.distancia < 4 ? 3 : alert?.distancia < 10 ? 2 : 1}
                    key={'notifAlertaRaio-'+alert.id_alvo}
                  >
                    <NotifCard>
                      <div>
                        <div>
                          <TabButton onClick={() => {handleCloseAlert(alert)}}>
                            <Close />
                          </TabButton>
                          <TabButton onClick={() => Helper.goToTarget(alert.id_alvo)}>
                            <MyLocation />
                          </TabButton>
                          <Button onClick={() => Helper.sendToModal(alert.id_alvo)}>
                            <span>ALERTAR</span>
                          </Button>
                        </div>
                      </div>
                      <span><strong>RAIO: </strong>{alert?.distancia}Km</span>
                      <span><strong>EMPRESA: </strong>{Helper.getCompany(alert.id_alvo)}</span>
                      <span><strong>ALVO: </strong>{Helper.getTarget(alert.id_alvo)}</span>
                    </NotifCard>
                  </NotifBackground>
                )
              if(alert.tipo === 'liberacao_raio')
                return(
                  <ReleaseBackground
                    key={'notifLiberacaoRaio-'+alert.id_alvo}
                  >
                    <NotifCard>
                      <div>
                        <div>
                          <TabButton onClick={() => {handleCloseAlert(alert)}}>
                            <Close />
                          </TabButton>
                          <TabButton onClick={() => Helper.goToTarget(alert.id_alvo)}>
                            <MyLocation />
                          </TabButton>
                          <Button onClick={() => Helper.sendToModal(alert.id_alvo, 1)}>
                            <span>LIBERAR</span>
                          </Button>
                        </div>
                      </div>
                      <span><strong>30 MIN. SEM DESCARGAS</strong></span>
                      <span><strong>EMPRESA: </strong>{Helper.getCompany(alert.id_alvo)}</span>
                      <span><strong>ALVO: </strong>{Helper.getTarget(alert.id_alvo)}</span>
                    </NotifCard>
                  </ReleaseBackground>
                )
              if(alert.tipo === 'aviso_chuva')
                  return (
                    <RainAlertBackground
                    key={'notifAvisoChuva-'+alert.id_alvo}
                  >
                    <NotifCard>
                      <div>
                        <div>
                          <TabButton onClick={() => {handleCloseAlert(alert)}}>
                            <Close />
                          </TabButton>
                          <TabButton onClick={() => Helper.goToTarget(alert.id_alvo)}>
                            <MyLocation />
                          </TabButton>
                          <Button onClick={() => Helper.sendToModal(alert.id_alvo, 2)}>
                            <span>NOVO AVISO</span>
                          </Button>
                        </div>
                      </div>
                      <span><strong>ACABA EM: </strong>{alert.time_diff} minutos</span>
                      <span><strong>EMPRESA: </strong>{Helper.getCompany(alert.id_alvo)}</span>
                      <span><strong>ALVO: </strong>{Helper.getTarget(alert.id_alvo)}</span>
                    </NotifCard>
                  </RainAlertBackground>
                  )  
            })}
            {forecastData.filter((forecast1, index) => forecast1.tipo === "trinta_min_atraso" && forecastData.map((forecast2) => {return closedForecasts.filter((forecast3) => forecast2.tipo === forecast3.tipo && forecast2.nome_alvo === forecast3.nome_alvo && forecast3.closed === true)})[index]?.length === 0)?.length > 0?
              (
                <ReportCardBackground
                key={'notifBoletim-30_min_atraso'}
              >
                <NotifCard>
                  <div>
                    <div>
                      <TabButton onClick={() => handleCloseReportCard()}>
                        <Close />
                      </TabButton>
                      <Button onClick={() => dispatch(openMapSidebarProvider(true))}>
                        <span>VER BOLETINS</span>
                      </Button>
                    </div>
                  </div>
                  <span><strong>ATÉ 30 MIN. ATRASADO</strong></span>
                  <span><strong>ALVOS: </strong></span>
                  {setReportCardData(alertsToShow.filter((alert) => alert.tipo === "trinta_min_atraso" && alert.closed === false))}
                </NotifCard>
              </ReportCardBackground>
              )
              :
              ("")
            }
            {forecastData.filter((forecast1, index) => forecast1.tipo === "quinze_min_atraso" && forecastData.map((forecast2) => {return closedForecasts.filter((forecast3) => forecast2.tipo === forecast3.tipo && forecast2.nome_alvo === forecast3.nome_alvo && forecast3.closed === true)})[index]?.length === 0)?.length > 0?
              (
                <ReportCardBackground
                key={'notifBoletim-15_min_atraso'}
              >
                <NotifCard>
                  <div>
                    <div>
                      <TabButton onClick={() => handleCloseReportCard()}>
                        <Close />
                      </TabButton>
                      <Button onClick={() => dispatch(openMapSidebarProvider(true))}>
                        <span>VER BOLETINS</span>
                      </Button>
                    </div>
                  </div>
                  <span><strong>ATÉ 15 MIN. ATRASADO</strong></span>
                  <span><strong>ALVOS: </strong></span>
                  {setReportCardData(alertsToShow.filter((alert) => alert.tipo === "quinze_min_atraso" && alert.closed === false))}
                </NotifCard>
              </ReportCardBackground>
              )
              :
              ("")
            }
            {forecastData.filter((forecast1, index) => forecast1.tipo === "momento_envio" && forecastData.map((forecast2) => {return closedForecasts.filter((forecast3) => forecast2.tipo === forecast3.tipo && forecast2.nome_alvo === forecast3.nome_alvo && forecast3.closed === true)})[index]?.length === 0)?.length > 0?
              (
                <ReportCardBackground
                key={'notifBoletim-momento_envio'}
              >
                <NotifCard>
                  <div>
                    <div>
                      <TabButton onClick={() => handleCloseReportCard()}>
                        <Close />
                      </TabButton>
                      <Button onClick={() => dispatch(openMapSidebarProvider(true))}>
                        <span>VER BOLETINS</span>
                      </Button>
                    </div>
                  </div>
                  <span><strong>ENVIAR</strong></span>
                  <span><strong>ALVOS: </strong></span>
                  {setReportCardData(alertsToShow.filter((alert) => alert.tipo === "momento_envio" && alert.closed === false))}
                </NotifCard>
              </ReportCardBackground>
              )
              :
              ("")
            }
            {forecastData.filter((forecast1, index) => forecast1.tipo === "quinze_min_antecedencia" && forecastData.map((forecast2) => {return closedForecasts.filter((forecast3) => forecast2.tipo === forecast3.tipo && forecast2.nome_alvo === forecast3.nome_alvo && forecast3.closed === true)})[index]?.length === 0)?.length > 0?
              (
                <ReportCardBackground
                key={'notifBoletim-15_min_antecedencia'}
              >
                <NotifCard>
                  <div>
                    <div>
                      <TabButton onClick={() => handleCloseReportCard()}>
                        <Close />
                      </TabButton>
                      <Button onClick={() => dispatch(openMapSidebarProvider(true))}>
                        <span>VER BOLETINS</span>
                      </Button>
                    </div>
                  </div>
                  <span><strong>15 MIN. PARA O ENVIO</strong></span>
                  <span><strong>ALVOS: </strong></span>
                  {setReportCardData(alertsToShow.filter((alert) => alert.tipo === "quinze_min_antecedencia" && alert.closed === false))}
                </NotifCard>
              </ReportCardBackground>
              )
              :
              ("")
            }
            {forecastData.filter((forecast1, index) => forecast1.tipo === "trinta_min_antecedencia" && forecastData.map((forecast2) => {return closedForecasts.filter((forecast3) => forecast2.tipo === forecast3.tipo && forecast2.nome_alvo === forecast3.nome_alvo && forecast3.closed === true)})[index]?.length === 0)?.length > 0?
              (
                <ReportCardBackground
                key={'notifBoletim-30_min_antecedencia'}
              >
                <NotifCard>
                  <div>
                    <div>
                      <TabButton onClick={() => handleCloseReportCard()}>
                        <Close />
                      </TabButton>
                      <Button onClick={() => dispatch(openMapSidebarProvider(true))}>
                        <span>VER BOLETINS</span>
                      </Button>
                    </div>
                  </div>
                  <span><strong>30 MIN. PARA O ENVIO</strong></span>
                  <span><strong>ALVOS: </strong></span>
                  {setReportCardData(alertsToShow.filter((alert) => alert.tipo === "trinta_min_antecedencia" && alert.closed === false))}
                </NotifCard>
              </ReportCardBackground>
              )
              :
              ("")
            }
          </NotifScroll>
        </NotifDiv>
      )}
    </>
  )
}

export default Alerts
