import {useState, useEffect, useRef} from 'react'
import { getClientTargetList } from 'services/mapsPortal/initialLoad';
import useLoad from 'hooks/useLoad';
import {Alert, RowDiv, Button, TabButton, HeaderNotif, NotifDiv, NotifTitle, NotifScroll, NotifCard, NotifBackground, CloseButton, ReleaseBackground, RainAlertBackground} from './styled';
import useToggle from 'hooks/useToggle';
import useTargetHelper from 'hooks/useTargetHelpers';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import { Close, MyLocation, Check } from '@material-ui/icons';
import {useControlPanel} from 'Context/ClientControlPanelContext';
import {useTargetState} from 'Context/TargetStateContext';


const Alerts = () => {
  const prevAlerts = useRef([]);
  const [closedAlerts, setClosedAlerts] = useState({});
  const {data, update} = useLoad(getClientTargetList, []);
  useInterval(update, 5000);
  const { showAlerts: open, toggleShowAlerts: toggleOpen} = useControlPanel();
  const [runAnimation, toggleAnimation] = useToggle(false);
  const Helper = useTargetHelper();
  const { targetState } = useTargetState();
  const alertedTargets = data.length > 0? data[0]?.planos.map((plan) => plan.alvos.filter((target) => targetState[target.id_alvo])) : [];


  // Inicia animação to alerta, quando chega alerta com alvo novo;
  useEffect(() => {
    // Includes testa se todos os alvos já existiam nos alertas anteriores.
    // Caso algum alerta não passe no teste, ele será falso e portanto iniciará
    // a animação de alerta.
    // Também verificar se é necessária a emissão de um novo alerta, devido a
    // diminuição da distância;
    if(alertedTargets.length > 0){
      let includes = true
      prevAlerts.current = alertedTargets[0].map((alerta) => {
        const index = prevAlerts.current.findIndex((a) => a.id_alvo === alerta.id_alvo/* && a.tipo === alerta.tipo*/)
        includes = includes
          ?  index >= 0 /*&& (prevAlerts.current[index].distancia <= alerta.distancia || alerta.tipo !== 'raio')*/
          : false;
        return {id_alvo: alerta.id_alvo/*, distancia: alerta.distancia, tipo: alerta.tipo*/};
      });

      if (!includes && alertedTargets?.length > 0 && alertedTargets[0].length > 0){
        if(!open){
          toggleAnimation(true);
        }
      }

      filterClosedUnused()
    }
  }, [JSON.stringify(alertedTargets), JSON.stringify(closedAlerts.current)])

  useEffect(() => {
    toggleOpen(true);
  }, [])

  const filterClosedUnused = () => {
    setClosedAlerts(prev => {
      // const prev = {...p}
      Object.keys(prev).forEach(key => {
        if (alertedTargets?.length > 0 && alertedTargets[0].findIndex(alert => prev[key].id_alvo === alert.id_alvo) === -1)
          delete prev[key]
      })
      return prev
    })
  }

  const handleClick = () => {
    if (runAnimation){
      toggleAnimation(false)
    }else{
      toggleOpen();
    }
  }

  const handleCloseAlert = (alert) => {
    setClosedAlerts(p => {
      const closedRef = {...p}
      const key = `${alert.tipo} - ${alert.id_alvo}`;
      if(!closedRef[key]){
        closedRef[key] = {...alert, firstClose: moment(), closeTime: moment(), closedTimes: 1};
      }else{
        closedRef[key].closedTimes = closedRef[key].closedTimes + 1;
        closedRef[key].closeTime = moment();
      }
      return closedRef;
    })
  }

  const deleteId = (id) => {
    setClosedAlerts(p => {
      const newAlerts = {...p};
      delete newAlerts[id];
      return newAlerts
    })
  }

  const testClosedAlerts = () => {
    if(alertedTargets?.length > 0){
      return alertedTargets[0].filter((alert) => {
        const key = `${alert.id_alvo}`;
        if (!closedAlerts[key]) return true;
        const time = moment().diff(closedAlerts[key].closeTime, 'seconds')
        /*if (alert.tipo === 'raio' && closedAlerts[key].distancia > alert.distancia){
          deleteId(key)
          return true
        }*/
        // if (closedAlerts[key].closedTimes >= 3) return false
        if (moment().diff(closedAlerts[key].firstClose, 'minutes') >= 10) return false
        //if (alert.distancia < 4) return time >= 30
        return time >= 60
      })
    }
    else{
      return [];
    }
  }
  const alertsToShow = testClosedAlerts();
/* <div style={{width: "20%", justifyContent: "center", display: "flex"}}>
              <CloseButton onClick={() => toggleOpen(false)}>
                <Close/>
              </CloseButton>
            </div>
            <div style={{width: "80%"}}>
              <h2 style={{marginLeft: "20px"}}>EM ALERTA</h2>
            </div>*/
  return (
    <>
      {alertsToShow.length && (
        <Alert
          onClick={handleClick}
          run={runAnimation}
          level={3/* alertsToShow[0].distancia < 4 ? 3 : alertsToShow[0].distancia < 10 ? 2 : 1*/}>
          <span>{alertsToShow.length}</span>
        </Alert>
      )}
      {alertsToShow.length && (
        <NotifDiv open={open} animationRunning={runAnimation}>
          <NotifTitle>
              <CloseButton onClick={() => toggleOpen(false)} style={{position: 'fixed', left: "15px"}}>
                <Close style={{fontSize: "25px"}}/>
              </CloseButton>
              <h2 style={{marginLeft: "20px"}}>EM ALERTA</h2>
          </NotifTitle>
          <NotifScroll>
            {alertsToShow.map((alert) => {
              /*if (alert.tipo === 'raio'){*/
                return(
                  <NotifBackground
                    level={3/*alert.distancia < 4 ? 3 : alert.distancia < 10 ? 2 : 1*/}
                    key={'notifAlertaRaio-'+alert.id_alvo}
                  >
                    <NotifCard>
                      <div>
                        <div style={{justifyContent: "flex-end", gap: "10px"}}>
                          <TabButton onClick={() => Helper.goToTarget(alert.id_alvo)}>
                            <MyLocation />
                          </TabButton>
                          <TabButton onClick={() => {handleCloseAlert(alert)}}>
                            <Close />
                          </TabButton>
                        </div>
                      </div>
                      <span><strong>RAIO: </strong>4 km</span>
                      <span><strong>ALVO: </strong>{Helper.getTarget(alert.id_alvo)}</span>
                    </NotifCard>
                  </NotifBackground>
                )
              //}
            })}
          </NotifScroll>
        </NotifDiv>
      )}
    </>
  )
}
 {/*if(alert.tipo === 'aviso_chuva')
                  return (
                    <RainAlertBackground
                    key={'notifAvisoChuva-'+alert.id_alvo}
                  >
                    <NotifCard>
                      <div>
                        <div>
                          <TabButton onClick={() => {handleCloseAlert(alert)}}>
                            <Check />
                          </TabButton>
                          <TabButton onClick={() => Helper.goToTarget(alert.id_alvo)}>
                            <MyLocation />
                          </TabButton>
                          <Button onClick={() => Helper.sendToModal(alert.id_alvo, 2)}>
                            <span>ENVIAR AVISO</span>
                          </Button>
                        </div>
                      </div>
                      <span><strong>ACABA EM: </strong>{alert.time_diff} minutos</span>
                      <span><strong>EMPRESA: </strong>{Helper.getCompany(alert.id_alvo)}</span>
                      <span><strong>ALVO: </strong>{Helper.getTarget(alert.id_alvo)}</span>
                    </NotifCard>
                  </RainAlertBackground>
                )
              */}  
export default Alerts
