import React, {memo, useEffect, useState} from 'react'
import P, { object } from 'prop-types';
import {Layer, Source} from 'react-map-gl';
import {getActives} from 'services/mapsPortal/satellite'
import { useActiveStatus } from 'Context/ActiveStatusContext';

const ActiveComponent = () => {
  const [activeData, setActiveData] = useState();
  const [loading, setLoading] = useState(true);
  const {state} = useActiveStatus();

  const loadActive = async () => {
    await getActives().then((response) => {
      if(response){
        var featuresData = response[0].ativos_agrupados;
        if(featuresData){
          setActiveData({
            type: "FeatureCollection",
            features: featuresData
          });
          setLoading(false);
        }
      }
    });
  }

  useEffect(() => {
    if(loading){
      loadActive();
    }
  }, []);


  if(loading) return null;
  return (
    <>
      <Source
        key={`active`}
        id={`active`}
        type="geojson"
        data={activeData}
        generateId={true}
        >
        <Layer
            id={`active-line`}
            source={`active-line`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
              'line-color': "#000000",
              'line-width': 1,
            }}
        />
       <Layer
          id={`active-point`}
          source={`active-point`}
          type="circle"
          layout={{
            visibility: 'visible',
            // visibility: !showTargets
              // ? 'none'
              // : 'visible',
          }}
          paint={{
            'circle-color': '#000000',
            'circle-radius': {
              'base': 1.75,
              'stops': [
                [12, 2],
                [22, 180]
              ]
            },
          }}
        />
      </Source> 
    </>
  )
}  


ActiveComponent.propTypes = {
}

export const ActiveLayer = memo(ActiveComponent);
