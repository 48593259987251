import { djangoApi, djangoApiHist, lambdaApi , javaApi} from '../api';
import moment from 'moment'

export const getSentNotifications = async () => {
  const response = await djangoApi.get('notificacoes_nucleo/')
  const arr = []
  response.data.alerta_raios.forEach(raio => {
    const obj = {
      ...raio[0],
      datahora_criacao: moment(new Date(raio[0].datahora_criacao)),
      tipo: 'raio',
      meteorologista: raio[0].concat
    }
    delete obj.concat
    arr.push(obj)
  })
  response.data.alertas_liberados.forEach(liberacao => {
    const obj = {
      ...liberacao[0],
      datahora_criacao: moment(new Date(liberacao[0].datahora_criacao)),
      tipo: 'liberacao_raio',
      id_alerta: liberacao[0].id_alerta_liberado,
      meteorologista: liberacao[0].concat
    }
    delete obj.id_alerta_liberado
    delete obj.concat
    arr.push(obj)
  })
  response.data.avisos_chuva.forEach(aviso => {
    const obj = {
      ...aviso[0],
      datahora_criacao: moment(new Date(aviso[0].datahora_criacao)),
      tipo: 'aviso_chuva',
      id_alerta: aviso[0]?.datahora_criacao
    }
    arr.push(obj)
  })
  arr.sort((a,b) => b.datahora_criacao.diff(a.datahora_criacao))
  return arr
}

export const getTargetAlertState = async () => {
  try {
      const response = await djangoApi.get(`alerta_alvos/`);
      const resObject = {};
      response.data.forEach(a => resObject[a.alvo] = true)
      return resObject;
  }
  catch (error) {
      throw new Error('Não foi possível verificar os alvos em alerta.')
  }
}

export const getTargetCustomAlertState = async () => {
  try {
      const response = await lambdaApi.get(`alerta_amarelo`);
      const resObject = {};
      response.data? response.data.forEach(a => resObject[a.alvo] = true) : null;
      return resObject;
  }
  catch (error) {
      throw new Error('Não foi possível verificar os alvos em alerta customizado.')
  }
}

export const getAutoAlerts = async () => {
  try {
      const response = await djangoApi.get(`alertas_automaticos/`);
      const state = response.data.alerta_automatico_global !== 'desativado';
      const res = {responsavel: response.data.responsavel, alerta: state}
      return res;
  }
  catch (error) {
      throw new Error('Não foi possível verificar se o alerta automático está ativo.')
  }

}

export const postAutoAlerts = async (newValue) => {

  try {
      const response = await djangoApi.post(
        `alertas_automaticos/`,
        {ativo: newValue}
      );
      return response.data.alerta_automatico_global;
  }
  catch (error) {
      throw new Error('Não foi possível verificar se o alerta automático está ativo.')
  }

}

export const getAllAlerts = async () => {
  try {
      const response = await djangoApi.get(`alertas_liberados/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getAllUsersNucleo = async () => {
  try {
      const response = await djangoApi.get(`usuarios_nucleo/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getGraphicsData = async (idEmpresa, initialDate, finalDate) => {
  try {
      const response = await lambdaApi.get(`historico/alertas?data_inicial=${initialDate}&data_final=${finalDate}&id_empresa=${idEmpresa}`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};


export const postLightningAlert = (alertData) => {

    return djangoApi.post(`alerta_raios/`, alertData);
}

export const postLightningModifiedAlerts = (data) => {

  return djangoApi.post(`alerta_raios_modificados/`, data);
}

export const getLightningActiveAlertByTargetID = async (id_target) => {
    try {
        const response = await djangoApi.get(`alerta_alvos/${id_target}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const postLightningRelease = (releaseData) => {
    delete releaseData.datahora_criacao
    return djangoApi.post(`alertas_liberados/`, releaseData);
}

export const postModifiedLightningRelease = (releaseData) => {
  return djangoApi.post(`alertas_liberados_modificados/`, releaseData);
}

export const getRainAlertByTargetId = async (id_target) => {
    try {
        const response = await djangoApi.get(`avisoschuva/?id_alvo=${id_target}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const postRainAlert = (alertData) => {
    return djangoApi.post(`avisoschuva/`, alertData);
}

export const postModifiedRainAlert = (alertData) => {
  return djangoApi.post(`avisoschuva_modificados/`, alertData);
}

export const postReportCard = (alertData) => {
  return djangoApi.post(`boletim/`, alertData);
}

export const deleteReportCard = (id_boletim) => {
  return djangoApi.delete(`boletim/${id_boletim}/`);
}

export const postModifiedReportCard = (alertData) => {
  return djangoApi.post(`boletim_modificado/`, alertData);
}

export const updateReportCard = (id_boletim, reportCardData) => {
  return djangoApi.patch(`boletim/${id_boletim}/`, reportCardData);
}

export const postReportCardEmail = (reportCardData) => {
  return djangoApi.post(`envio-email-boletim/`, reportCardData);
}

export const postWeatherWarning = (alertData) => {
  return djangoApi.post(`avisometeorologico/`, alertData);
}

export const postModifiedWeatherWarning = (alertData) => {
  return djangoApi.post(`avisometeorologico_modificado/`, alertData);
}

export const postModifiedReport = (reportData) => {
  return djangoApi.post(`boletim_modificado/`, reportData);
}

export const getHistoryMonitoring = async () => {
  try {
      const response = await djangoApi.get(`historico_monitoramento/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getHistoryMonitoringSpecific = async (data) => {
  let val1 = data.data_inicio+"-"+data.hora_inicio.split(":")[0]+"-"+data.hora_inicio.split(":")[1];
  let val2 = data.data_final+"-"+data.hora_final.split(":")[0]+"-"+data.hora_final.split(":")[1];
  try {
      const response = await djangoApiHist.get(`historico_monitoramento/${val1}/${val2}/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getHistoryMonitoringSpecificClient = async (data, user) => {
  let val1 = data.data_inicio+"-"+data.hora_inicio.split(":")[0]+"-"+data.hora_inicio.split(":")[1];
  let val2 = data.data_final+"-"+data.hora_final.split(":")[0]+"-"+data.hora_final.split(":")[1];
  let idUser = user;
  
  try {
      const response = await javaApi.get(`historico_monitoramento/${val1}/${val2}/${idUser}`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}


export const getReportCard = async () => {
  try {
      const response = await djangoApi.get(`boletim/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const getDailyReportCard = async () => {
  try {
      const response = await djangoApi.get(`boletins/hoje/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const getSpecialReportCard = async () => {
  try {
      const response = await djangoApi.get(`boletins/especial/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const get12hReportCard = async () => {
  try {
      const response = await djangoApi.get(`boletins/hoje12h/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const getNowcastingReportCard = async () => {
  try {
      const response = await djangoApi.get(`boletins/hoje3h/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};


export const getPlansInfo = async () => {
  try {
      const response = await djangoApi.get(`planos_infos/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getPlansInfoClient = async (userID) => {
  try {
      const response = await djangoApi.get(`planos_infos_user/?id_user=${userID}`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getHistoryPrevision = async () => {
  try {
      const response = await djangoApi.get(`historico_previsao/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getConsolidations = async () => {
  try {
      const response = await djangoApi.get(`consolidacao/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getHistoryPrevisionSpecific = async (data) => {
  let val1 = data.data_inicio+"-"+data.hora_inicio.split(":")[0]+"-"+data.hora_inicio.split(":")[1];
  let val2 = data.data_final+"-"+data.hora_final.split(":")[0]+"-"+data.hora_final.split(":")[1];
  try {

    const response = await djangoApi.get(`historico_previsao/${val1}/${val2}/`);
    return response.data;
  }
  catch (error) {
      return [];
  }
}

export const getHistoryPrevisionSpecificClient = async (data, user) => {
  let val1 = data.data_inicio+"-"+data.hora_inicio.split(":")[0]+"-"+data.hora_inicio.split(":")[1];
  let val2 = data.data_final+"-"+data.hora_final.split(":")[0]+"-"+data.hora_final.split(":")[1];
  let idUser = user
  try {

    const response = await javaApi.get(`historico_previsao/${val1}/${val2}/${idUser}`);
    return response.data;
  }
  catch (error) {
      return [];
  }
}



export const postResend = (resendData) => {
  return djangoApi.post(`envio_avisos/`, resendData);
}


