import './style.css';
import Map from './components/Map';
import PropTypes from 'prop-types';
import Sidebar from './components/Sidebar';
import { ToolsContextProvider } from 'Context/ToolsContext';
import { MapContextProvider, useMapContext } from 'Context/MapContext';
import { SatelliteContextProvider } from 'Context/SatelliteContext';
import RightMapMenu from './components/RightMapMenu';
import {Icon} from '@material-ui/core';
import {OpenButton, ZoomsDiv, ZoomDiv, ZoomButton} from './styled';
import AlertsPanel from './components/AlertsPanel';
import ForecastPanel from './components/ForecastPanel';
import TargetContextProvider from 'Context/TargetContext';
import TargetStateContextProvider from 'Context/TargetStateContext';
import DischargesContextProvider from 'Context/DischargesContext';
import RadarContextProvider from 'Context/RadarContext';
import CameraContextProvider from 'Context/CameraContext';
import ControlPanelProvider from 'Context/ControlPanelContext';
import ModalContextProvider from 'Context/ModalContext';
import { useModal } from 'Context/ModalContext';
import useHotkeys from 'pages/MapsPortal/hotkeys/useHotkeys';
import {MdZoomOutMap} from 'react-icons/md';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import TopMenu from './components/TopMenu';
import SentNotifications from './components/SentNotifications';
import { useSelector } from 'react-redux';
import { TargetCustomAlertStateContextProvider } from 'Context/TargetCustomAlertStateContext';
import ActiveStatusContextProvider from 'Context/ActiveStatusContext';

function Hotkeys() {
  useHotkeys()
  return <></>
}

function Modal(){
  const { modalType, open, tab} = useModal();
  const {hotkeys} = useMapContext();
  const dischargesHistory = useSelector(state => state.polygonProvider);

  return(
    <>
      {hotkeys && !open && !dischargesHistory && <Hotkeys />}
      {modalType === 'monitoring' && open && <AlertsPanel tab={tab} />}
      {modalType === 'prediction' && open && <ForecastPanel tab={tab} />}
    </>
  )
}

function MapsZoom () {
  const {resetMapPosition, mapRef} = useMapContext();

  const mapZoomIn = () => {
    const map = mapRef.current;
    const zoom = map?.getZoom() + 1;
    map?.flyTo({
      zoom: zoom,
      essential: true
    })
  }
  const mapZoomOut = () => {
    const map = mapRef.current;
    const zoom = map?.getZoom() - 1;
    map?.flyTo({
      zoom: zoom,
      essential: true
    })
  }

  return (
    <ZoomsDiv >
      <ZoomButton onClick={resetMapPosition}>
        <MdZoomOutMap onClick={resetMapPosition}/>
      </ZoomButton>
      <ZoomDiv>
        <ZoomButton onClick={mapZoomIn}>
          <AiOutlinePlus />
        </ZoomButton>
        <ZoomButton onClick={mapZoomOut}>
          <AiOutlineMinus />
        </ZoomButton>
      </ZoomDiv>
    </ZoomsDiv>
  )
}


function MonitoringPage() {
  return (
          <MapContextProvider> 
            <ToolsContextProvider>
              <DischargesContextProvider>
                <RadarContextProvider>
                  <TargetStateContextProvider>
                    <TargetCustomAlertStateContextProvider>
                      <TargetContextProvider>
                        <SatelliteContextProvider>
                          <CameraContextProvider>
                            <ActiveStatusContextProvider>
                              <ControlPanelProvider>
                                <ModalContextProvider>
                                  <div className="MonitoringPage" >
                                    <Modal />
                                    <Sidebar />
                                    <MapsZoom />
                                    <div className="map">
                                        <Map  />
                                    </div>
                                    <TopMenu />
                                    <RightMapMenu />
                                    <SentNotifications />
                                  </div>
                                </ModalContextProvider>
                              </ControlPanelProvider>
                            </ActiveStatusContextProvider>
                          </CameraContextProvider> 
                        </SatelliteContextProvider>
                      </TargetContextProvider>
                    </TargetCustomAlertStateContextProvider>
                  </TargetStateContextProvider>
                </RadarContextProvider>
              </DischargesContextProvider>
            </ToolsContextProvider>
          </MapContextProvider>
    );
}
export default MonitoringPage;
