import React, {memo, useEffect, useState} from 'react'
import P from 'prop-types';
import {Layer, Source} from 'react-map-gl';
import { useActives } from 'Context/ActivesContext';


const ActiveComponent = () => {
  const { actives, historyActives } = useActives();
  const [loading, setLoading] = useState(true);
  const [activeData, setActiveData] = useState();

  useEffect(() => {
    if(historyActives.type){
      setActiveData({
        type: "FeatureCollection",
        features: historyActives.features[0].ativos_por_poligono
      });
      setLoading(false);
    }
    else if(Object.keys(actives).length > 0){
      //var parsedData = JSON.parse(actives);
      var featuresData = actives[0].ativos_agrupados;
      if(featuresData){
        setActiveData({
          type: "FeatureCollection",
          features: featuresData
        });
      }
      setLoading(false);
    }
  }, [actives, historyActives]);


  if(loading) return null;
  return (
    <>
      <Source
        key={`active`}
        id={`active`}
        type="geojson"
        data={activeData}
        generateId={true}
        >
        <Layer
            id={`active-line`}
            source={`active-line`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
              'line-color': "#000000",
              'line-width': 1,
            }}
        />
       <Layer
          id={`active-point`}
          source={`active-point`}
          type="circle"
          layout={{
            visibility: 'visible',
            // visibility: !showTargets
              // ? 'none'
              // : 'visible',
          }}
          paint={{
            'circle-color': '#000000',
            'circle-radius': {
              'base': 1.75,
              'stops': [
                [12, 2],
                [22, 180]
              ]
            },
          }}
        />
      </Source> 
    </>
  )
}  


ActiveComponent.propTypes = {
}

export const ActiveLayer = memo(ActiveComponent);
