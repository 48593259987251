import { useEffect, useState } from 'react'
import useInterval from 'hooks/useInterval';
import useTargetHelper from 'hooks/useTargetHelpers';
import useLoad from 'hooks/useLoad'
import {getSentNotifications} from 'services/mapsPortal/alerts'
import P from 'prop-types';
import { SentNotifContainer, Container } from './styled';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { Row, Column } from 'components/Flex';
import {useControlPanel} from 'Context/ControlPanelContext';
import { useSelector } from 'react-redux';

const NotificationSnackbar = ({type, user, company, target, id, sound}) => {
  const [autoClose, setAutoClose] = useState(false);

  const title = type === 'raio'
    ? 'ALERTA ENVIADO'
    : type === 'liberacao_raio'
      ? 'ALERTA LIBERADO'
      : 'AVISO C. ENVIADO';

  
  useEffect(() => {
    setTimeout(() => setAutoClose(true), 6000)
  }, [])
  
  useEffect(() => {
    if(id >= 3) setAutoClose(true)
  }, [id])

  return(
      <Container type={type} id={id} close={autoClose} onClick={() => setAutoClose(true)}>
        <Column w='80%' gap='2px' align='flex-start'>
          <Row gap='6px'>
            <IoMdCheckboxOutline size={20} />
            <strong>{title}</strong>
          </Row>
          <span><strong>EMPRESA: </strong>{company}</span>
          <span><strong>ALVO: </strong>{target}</span>
          <span><strong>RESP: </strong>{user}</span>
        </Column>
      </Container>
  )
}

NotificationSnackbar.propTypes = {
  type: P.string.isRequired,
  user: P.string.isRequired,
  company: P.string.isRequired,
  target: P.string.isRequired,
  id: P.number.isRequired,
  sound: P.any
}

const SentNotifications = () => {
  const {data, update} = useLoad(getSentNotifications, []);
  useInterval(update, 5000);
  const Helper = useTargetHelper();
  const {showSidebar, toggleShowSidebar} = useControlPanel();
  const width = useSelector((state) => state.mapSidebarMenuProvider);
  const totalWidth = width && width[0]? width[0] + width[1] + "px" : width? width[1] + "px" : 0;

  return (
    <SentNotifContainer width={totalWidth} showSidebar={showSidebar}>
      {data.map((not, index) => (
        <NotificationSnackbar
          user={not.meteorologista}
          type={not.tipo}
          company={not.empresa}
          target={not.nome}
          id={index}
          key={not.id_alerta}
        />
      ))}
    </SentNotifContainer>
  )
}

export default SentNotifications
