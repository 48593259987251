/* Libraries */
import React, { useState, useEffect, useRef } from 'react';
import P from 'prop-types';
import { useDispatch } from 'react-redux';
/* Material UI */
import { Grid } from '@material-ui/core';
import {
    StyledTableDefault,
    StyledHeadDefault,
    StyledBodyDefault,
    StyledIconButtonDefault,
    StyledSmallCellDefault,
} from './Table-jss';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
/* Components */
import Loading from 'components/Loading';
import TableHead from 'containers/Pages/Table/TableHead';
import TableFooter from 'containers/Pages/Table/TableFooter';
import PermissionsIcons from './TableHeadContent/PermissionsIcons';
import CustomCheckboxHead from './TableHeadContent/CustomCheckboxHead';
import SummaryInfo from './TableBodyContent/SummaryInfo';
import DaysOfWeek from './TableBodyContent/DaysOfWeek';

import CustomCheckboxBody from './TableBodyContent/CustomCheckboxBody';
import Arrows from './TableHeadContent/Arrows';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import styles from './tablestyle-jss';
import { data } from 'autoprefixer';
import ModalContextProvider from 'Context/ModalContext';

const ComplexTable = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [filterButton, setFilterButton] = useState("");
    const moment = require('moment');
    moment.locale('pt-br');

    const {
        classes,
        openCheckboxModal,
        setOpenCheckboxModal,
        csvButton,
        historySelectButtons,
        historyClientSelectButtons,
        previsionButtons,
        ticketsButtons,
        dateTimeChoice,
        createButton,
        setDateSearch,
        csvButtonName,
        otherCsvButton,
        reloadHistory,
        otherCsvButtonName,
        otherDataSet,
        buttonsWarnings,
        buttonsPrevision,
        setResendUsers,
        width,
        reportCard,
        noOperation,
        doReloadComponent
    } = props;

    const type = props.type;
    const [allFilters, setFilters] = useState(type=="Monitoramento"? ["Alerta Raios", "Alertas Liberados", "Avisos Chuva"] : type=="Previsão"? ["Boletins", "Avisos Meteorologicos"] : undefined);
    const group_type = props;
    const fonts = fontStyles();
    const [columnsFilter, setColumnsFilter] = useState(() => props.columns);
    const [selected, setSelected] = useState([]);
    const [errataInfos, setErrataInfos] = useState([]);
    const [retificationInfos, setRetificationInfos] = useState([]);
    const [previsionInfos, setPrevisionInfos] = useState([]);
    const [ticketInfos, setTicketInfos] = useState([]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [loadPagination, setLoadPagination] = useState(false);
    const ref = useRef(null);
    
    const [sortBy, setSortBy] = useState({
        column:
            type === 'Planos' ||
            type === 'Usuários' ||
            type === 'Noticias' ||
            type === 'Previsoes' ||
            type === 'Reports' ||
            type === 'Alvos' ? 
                props.columns[2] : 
            type === 'UsuáriosAlvos'?
                props.columns[3] :
            type === 'Boletim' ?
                props.columns[6]
                : props.columns[1],
        growing: true,
    });

    const [pagination, setPagination] = useState({
        actualPage: 1,
        pageSize: 15,
        maxPages: Math.ceil(props.data.length / 15),
    });

    const [pageSizeInput, setPageSizeInput] = useState(15);

    const [filteredData, setFilteredData] = useState(props.data);


    const isObject = (value) => {
        return typeof value === 'object';
    };

    const getDimensions = (percent) => {
        return ref.current
            ? ref.current.getBoundingClientRect().width *
                  (parseInt(percent.slice(0, 2)) / 100 - 0.03) +
                  'px'
            : null;
    };
    
    function dateCheckConst (aToSort,bToSort){
        if(aToSort=="Checkbox" || bToSort=="Checkbox"){return "Invalid Date";}
        if(!aToSort.includes('h') || !bToSort.includes('h')){
            return "Invalid Date";
        }else{
            let aSplitedDateTime = aToSort.split(' ');
            let aSplitedDate = aSplitedDateTime[0].split('/');
            let aReplacedTime = aSplitedDateTime[2].replace('h', '');
            let aSplitedTime = aReplacedTime.split(':');
            let aDate = new Date(parseInt(aSplitedDate[2]), aSplitedDate[1] - 1, parseInt(aSplitedDate[0]));
            aDate.setHours(parseInt(aSplitedTime[0]), parseInt(aSplitedTime[1]), 0)
            let bSplitedDateTime = bToSort.split(' ');
            let bSplitedDate = bSplitedDateTime[0].split('/');
            let bReplacedTime = bSplitedDateTime[2].replace('h', '');
            let bSplitedTime = bReplacedTime.split(':');
            let bDate = new Date(parseInt(bSplitedDate[2]), bSplitedDate[1] - 1, parseInt(bSplitedDate[0]));
            bDate.setHours(parseInt(bSplitedTime[0]), parseInt(bSplitedTime[1]), 0)
            return [aDate,bDate]
        }

    };

    useEffect(() => {
        setFilteredData(props.data);
    }, [props.data]);
    
    useEffect(() => {
        if(filterButton!= ""){
            const finder = allFilters.find((filteredButton) => filteredButton === filterButton)
            if(finder != undefined){
                const newFilters = allFilters.filter((element)=>{
                    return element != finder
                })
                setFilters(newFilters)
            }else{
                allFilters.push(filterButton)
            }
            setFilterButton("")
        }
        if(sortBy.column!="Checkbox"){
            
        setFilteredData(
            props.data
                .filter((content) => {
                    return columnsFilter.some((column) => {
                        if (Array.isArray(content[column])) {
                            return;
                        } else if (isObject(content[column])){
                            if(content[column].content!=undefined){
                                return content[column].content
                                    .toString()
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase());
                            }
                        }
                        else
                            return content[column]
                                .toString()
                                .toLowerCase()
                                .includes(searchValue.toLowerCase());
                    });
                })
                .filter((filters)=>{
                    return allFilters? allFilters.includes(filters.tipo) : props.data
                })
                .sort((a, b) => {
                    const aToSort = isObject(a[sortBy.column])
                        ? a[sortBy.column].content
                        : a[sortBy.column];
                    const bToSort = isObject(b[sortBy.column])
                        ? b[sortBy.column].content
                        : b[sortBy.column];

                    if(type === "Boletim"){
                        let aToSortAux = aToSort === "Atrasado" ? 0 : 
                                        aToSort === "15 min atrasado" ? 1 : 
                                        aToSort === "30 min atrasado" ? 2 : 
                                        aToSort === "Enviar" ? 3 : 
                                        aToSort === "15 min para o envio" ? 4 : 
                                        aToSort === " 30 min para o envio" ? 5 : 
                                        aToSort === "No Prazo" ? 6 : 7;
                        let bToSortAux = bToSort === "Atrasado" ? 0 : 
                                        bToSort === "15 min atrasado" ? 1 : 
                                        bToSort === "30 min atrasado" ? 2 : 
                                        bToSort === "Enviar" ? 3 : 
                                        bToSort === "15 min para o envio" ? 4 : 
                                        bToSort === " 30 min para o envio" ? 5 : 
                                        bToSort === "No Prazo" ? 6 : 7;

                        if (sortBy.growing) return aToSortAux - bToSortAux;
                        else return bToSortAux - aToSortAux;
                    }
                    else if(type === "Monitoramento"  || type === "Previsão" || type === "MonitoramentoC"  || type === "PrevisãoC"  ){
                        
                        let dateChecked = dateCheckConst(aToSort, bToSort);

                        if(dateChecked=="Invalid Date"){
                            if (sortBy.growing)
                                return aToSort.localeCompare(bToSort);
                            else return bToSort.localeCompare(aToSort);
                        }else{

                            if(sortBy.growing){
                                return dateChecked[1]-dateChecked[0];
                            }
                            else{
                                return dateChecked[0]-dateChecked[1];
                            }
                        }
                    }
                    else if(type === "Noticias" || type === "Previsoes"){
                        if(sortBy.growing){
                            return new Date(bToSort.split("/")[2], bToSort.split("/")[1] - 1, bToSort.split("/")[0]) - new Date(aToSort.split("/")[2], aToSort.split("/")[1] - 1, aToSort.split("/")[0]);
                        }
                        else{
                            return new Date(aToSort.split("/")[2], aToSort.split("/")[1] - 1, aToSort.split("/")[0]) - new Date(bToSort.split("/")[2], bToSort.split("/")[1] - 1, bToSort.split("/")[0]);
                        }
                    }
                    else if (typeof aToSort === 'number') {
                        if (sortBy.growing) return aToSort - bToSort;
                        else return bToSort - aToSort;
                    } else if (aToSort.includes("/")) {
                        let slicesA = aToSort.split("/")
                        let slicesB = []
                        slicesA[2] = slicesA[2].split(" ")[0]

                        if(bToSort!=null){
                            if(bToSort.content==null){
                                slicesB = bToSort.split("/")
                                slicesB[2] = slicesB[2]?.split(" ")[0]
                            }else{
                                slicesB = bToSort.content.split("/")
                                slicesB[2] = slicesB[2]?.split(" ")[0]
                            }
                        }
                        if (sortBy.growing) return slicesA[0] - slicesB[0];
                        else return slicesB[0] - slicesA[0];
                    }else{
                        if (sortBy.growing)
                            return aToSort.localeCompare(bToSort);
                        else return bToSort.localeCompare(aToSort);
                    }

                })
        );
    }
    }, [
        sortBy.column,
        sortBy.growing,
        searchValue,
        filterButton,
        props.data,
        JSON.stringify(columnsFilter),
    ]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            actualPage: 1,
        }));
    }, [searchValue, pagination.pageSize]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            maxPages: Math.ceil(filteredData.length / prev.pageSize),
        }));
    }, [filteredData]);

    // Filter by props columns to keep the initial order;
    // So it filter the initial columns and test if it exists
    // in the new filteredColumns
    const handleColumns = (e, columns) => {
        setColumnsFilter(
            props.columns.filter((column) =>
                columns.some((col) => column === col),
            ),
        );
    };
    const handleSortBy = (event, columnName) => {
        if (event.target.name != 'Checkbox') {
            setSortBy(
                sortBy.column === columnName
                    ? { column: columnName, growing: !sortBy.growing }
                    : { column: columnName, growing: true },
            );
        }
    };

    useEffect(() => {
        setLoadPagination(false);
    }, [loadPagination]);

    const verifyChangePage = (page) => {
        const newPage = pagination.actualPage + page;
        return newPage > 0 && newPage <= pagination.maxPages;
    };
    
    const changePage = (page) => {
        if (verifyChangePage(page))
            setPagination((prev) => ({
                ...prev,
                actualPage: prev.actualPage + page,
            }));
    };

    const handleClickCheckbox = (event, index, name, info, rowIndex) => {

        const selectedIndex = selected.indexOf(index);
        const selectedRowIndexAux = selectedRowIndex.indexOf(rowIndex);
        const selectedName = selectedNames.indexOf(name);

        let newSelectedRowIndex = [];
        let newSelected = [];
        let newSelectedNames = [];
        if (info === 'AllSelected') {
            setAllSelected(!allSelected);
            if (!allSelected) {
                if(type !="Reenvio"){     
                    let aux1 = [];
                    let aux2 = [];
                    let aux3 = [];
                    let aux4 = [];
                    let aux5 = [];
                    let aux6 = [];
                    paginatedDate.map((data) => aux1.push(data.id));
                    paginatedDate.map((data) => aux2.push(data.nome));
                    paginatedDate.map((data) => aux3.push(data.Empresa+"_"+data.Plano+"_"+data.Alvo+"_"+data.tipo+"_"+data.id_og+"_"+data.alvo_id_og+"_"+data.nivel+"_"+data.d_e+"_"+data['Enviado/Atualizado']+"_"+data.risco+"_"+data.contato+"_"+data.ferramenta+"_"+data.observacao+"_"+data.intensidade+"_"+data.acumulado+"_"+data.liberado+"_"+data.datahora_liberacao_og +"_"+data.conteudo_modificacao+"_"+data.meteorologista+"_"+data.real_type));
                    paginatedDate.map((data) => aux4.push(data.id+"_"+data.Empresa+"_"+data.Plano+"_"+data.Alvo+"_"+data.tipo+"_"+data.nivel+"_"+data.d_e+"_"+data.risco+"_"+data.contato+"_"+data.ferramenta+"_"+data.observacao+"_"+data.datahora_liberacao_og+"_"+data.liberado+"_"+data.meteorologista+"_"+data.errata+"_"+data.intensidade+"_"+data.acumulado+"_"+data.id_og));
                    paginatedDate.map((data) => aux5.push(data.Alvo+"_"+data.Empresa+"_"+data.Plano+"_"+data["Responsável"]+"_"+data["Tipo de Boletim"]+"_"+data["Vigência(BRT)"]+"_"+data.id));
                    paginatedDate.map((data) => aux6.push(data.index));
                    setSelectedRowIndex(aux6);
                    setSelected(aux1);
                    setSelectedNames(aux2);
                    typeof setSelectedRowIndex === "function"? setErrataInfos(aux3) : "";
                    typeof setSelectedRowIndex === "function"? setRetificationInfos(aux4) : "";
                    typeof setSelectedRowIndex === "function"? setTicketInfos(aux5) : "";                    
                }else{       
                    let aux1 = [];
                    let aux2 = [];
                    let aux3 = [];
                    paginatedDate.map((data) => aux1.push(data.id));
                    paginatedDate.map((data) => aux2.push(data.nome));
                    paginatedDate.map((data) => aux3.push(data.id_usuario));
                    setSelected(aux1);
                    setSelectedNames(aux2);
                    typeof setSelectedRowIndex === "function"? setResendUsers(aux3) : "";
                }
            } else {
                setSelectedRowIndex([]);
                setSelected([]);
                setSelectedNames([]);
                typeof setSelectedRowIndex === "function"? setErrataInfos([]) : "";
                typeof setSelectedRowIndex === "function"? setTicketInfos([]) : "";
                typeof setSelectedRowIndex === "function"? setRetificationInfos([]) : "";
                typeof setSelectedRowIndex === "function"? setResendUsers([]) : "";
            }
        } else {
            if (selectedIndex === -1) {
                newSelectedRowIndex = newSelectedRowIndex.concat(selectedRowIndex, rowIndex);
                newSelected = newSelected.concat(selected, index);
                newSelectedNames = newSelectedNames.concat(selectedNames, name);
            } else if (selectedIndex === 0) {
                newSelectedRowIndex = newSelectedRowIndex.concat(selectedRowIndex.slice(1));
                newSelected = newSelected.concat(selected.slice(1));
                newSelectedNames = newSelectedNames.concat(
                    selectedNames.slice(1),
                );
            } else if (selectedIndex === selected.length - 1) {
                newSelectedRowIndex = newSelectedRowIndex.concat(selectedRowIndex.slice(0, -1));
                newSelected = newSelected.concat(selected.slice(0, -1));
                newSelectedNames = newSelectedNames.concat(
                    selectedNames.slice(0, -1),
                );
            } else if (selectedIndex > 0) {
                newSelectedRowIndex = newSelectedRowIndex.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
                newSelected = newSelected.concat(
                    selectedRowIndexAux.slice(0, selectedRowIndexAux),
                    selectedRowIndexAux.slice(selectedRowIndexAux + 1),
                );
                newSelectedNames = newSelected.concat(
                    selectedNames.slice(0, selectedName),
                    selectedNames.slice(selectedName + 1),
                );
            }
            if(historySelectButtons==true || historyClientSelectButtons==true){
                paginatedDate.map((data) => {
                  if(data.id == index){
                    let aux1 = data.Empresa+"_"+data.Plano+"_"+data.Alvo+"_"+data.tipo+"_"+data.id_og+"_"+data.alvo_id_og+"_"+data.nivel+"_"+data.d_e+"_"+data['Enviado/Atualizado']+"_"+data.risco+"_"+data.contato+"_"+data.ferramenta+"_"+data.observacao+"_"+data.intensidade+"_"+data.acumulado+"_"+data.liberado+"_"+data.datahora_liberacao_og +"_"+data.conteudo_modificacao+"_"+data.meteorologista+"_"+data.real_type
                    let aux2 = data.id+"_"+data.Empresa+"_"+data.Plano+"_"+data.Alvo+"_"+data.tipo+"_"+data.nivel+"_"+data.d_e+"_"+data.risco+"_"+data.contato+"_"+data.ferramenta+"_"+data.observacao+"_"+data.datahora_liberacao_og+"_"+data.liberado+"_"+data.meteorologista+"_"+data.errata+"_"+data.intensidade+"_"+data.acumulado+"_"+data.id_og
                    const finder1 = errataInfos.find((filteredButton) => filteredButton === aux1)
                    const finder2 = retificationInfos.find((filteredButton) => filteredButton === aux2)

                    if(finder1 != undefined){
                        const newValues = errataInfos.filter((element)=>{
                            return element != finder1
                        })
                        setErrataInfos(newValues)
                    }else{
                        errataInfos.push(aux1)
                    }
                    if(finder2 != undefined){
                        const newValues = retificationInfos.filter((element)=>{
                            return element != finder2
                        })
                        setRetificationInfos(newValues)
                    }else{
                        retificationInfos.push(aux2)
                        
                    }

                  }
                })   
            }else if(previsionButtons==true){
                paginatedDate.map((data) => {
                    if(data.id == index){
                      let aux = data.id+"_"+data.intensidade+"_"+data.condicao_tempo+"_"+data.plano+"_"+data.alvo+"_"+data.empresa+"_"+data.acumulado+"_"+data.probabilidade+"_"+data.nivel+"_"+data.rajada_vento+"_"+data.direcao_vento+"_"+data.velocidade_vento+"_"+data.temperatura_min+"_"+data.temperatura_max+"_"+data.obs+"_"+data.tipo+"_"+data.id_alvo_id+"_"+data.nome_meteorologista+"_"+data.meteorologista+"_"+data.horizontes+"_"+data.vigencia+"_"+data.especial+"_"+data.status+"_"+data.datahora_criacao+"_"+data.caminho_pdf+"_"+data.datahora_modificacao+"_"+data.titulo+"_"+data.texto+"_"+data.modificacao+"_"+data.errata+"_"+data.id_ogG
                      const finder = previsionInfos.find((filteredButton) => filteredButton === aux)
  
                      if(finder != undefined){
                          const newValues = previsionInfos.filter((element)=>{
                              return element != finder
                          })
                          setPrevisionInfos(newValues)
                      }else{
                          previsionInfos.push(aux)
                      }
  
                    }
                  })   
                
                
            }else if(ticketsButtons==true){
                paginatedDate.map((data) => {
                    if(data.id == index){
                      let aux1 = data.Alvo+"_"+data.Empresa+"_"+data.Plano+"_"+data["Responsável"]+"_"+data["Tipo de Boletim"]+"_"+data["Vigência(BRT)"]+"_"+data.id        
                      const finder = ticketInfos.find((filteredButton) => filteredButton === aux1)
                      if(finder != undefined){
                        const newValues = ticketInfos.filter((element)=>{
                            return element != finder
                        })
                        setTicketInfos(newValues)
                      }else{
                        let aux2 = ticketInfos
                        aux2.push(aux1)
                        setTicketInfos(aux2)
                      }
                     
                    }
                  })   
            }else if(type=="Reenvio"){
                setResendUsers(newSelected)
            }  
            setSelectedRowIndex(newSelectedRowIndex);
            setSelected(newSelected);
            setSelectedNames(newSelectedNames);
        }
    };

    // Filter lines using all table options
    const paginatedDate = filteredData.slice(
        (pagination.actualPage - 1) * pagination.pageSize,
        pagination.actualPage * pagination.pageSize,
    );
    
    return (
        <ModalContextProvider>
            <div>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    ref={ref}
                >
                    <TableHead
                        search={true}
                        createButton={createButton === undefined ? true : createButton}
                        createButtonName={`Cadastrar ${
                            type != 'TodosPlanos' ? type : 'Planos'
                        }`}
                        csvButton={csvButton === undefined ? true : csvButton}
                        searchValue={searchValue}
                        reloadHistory={reloadHistory}
                        errataInfos={historySelectButtons ===undefined ? null : historySelectButtons==true? errataInfos : null}
                        retificationInfos={historySelectButtons ===undefined ? null : historySelectButtons==true? retificationInfos : null}
                        ticketButtons={ticketsButtons === undefined? false : ticketsButtons}
                        ticketInfos={ticketsButtons === undefined? null : ticketsButtons==true? ticketInfos : null}
                        dateTimePicker={dateTimeChoice === undefined ? false : dateTimeChoice}
                        previsionButtons={previsionButtons === undefined ? false : previsionButtons}
                        previsionInfos={previsionButtons === undefined? null : previsionButtons==true? previsionInfos : null}
                        setSearchValue={setSearchValue}
                        noOperation={noOperation === undefined? false : noOperation}
                        setDateSearch={setDateSearch===undefined ? false : setDateSearch}
                        historySelectButtons={historySelectButtons === undefined ? false : historySelectButtons}
                        historyClientSelectButtons={historyClientSelectButtons === undefined ? false : historyClientSelectButtons}
                        setFilterButton={setFilterButton}
                        dataSet={props.dataSet}
                        buttonsWarnings={buttonsWarnings === undefined ? false : buttonsWarnings}
                        buttonsPrevision={buttonsPrevision === undefined ? false : buttonsPrevision}
                        type={props.type}
                        checkboxSelected={selected}
                        checkboxSelectedNames={selectedNames}
                        openModal={openCheckboxModal}
                        setOpenModal={setOpenCheckboxModal}
                        csvButtonName={csvButtonName ? csvButtonName : null}
                        otherCsvButton={otherCsvButton ? otherCsvButton : false}
                        otherCsvButtonName={
                            otherCsvButtonName ? otherCsvButtonName : null
                        }
                        otherDataSet={otherDataSet ? otherDataSet : null}
                    />
                </Grid>
                <StyledTableDefault>
                    {paginatedDate?.length > 0 ? (
                        <colgroup>
                            {width.length >= 1 ? (
                                <col style={{ width: width[0]}}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 2 ? (
                                <col style={{ width: width[1] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 3 ? (
                                <col style={{ width: width[2] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 4 ? (
                                <col style={{ width: width[3] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 5 ? (
                                <col style={{ width: width[4] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 6 ? (
                                <col style={{ width: width[5] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 7 ? (
                                <col style={{ width: width[6] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 8 ? (
                                <col style={{ width: width[7] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 9 ? (
                                <col style={{ width: width[8] }}></col>
                            ) : (
                                ''
                            )}
                            {width.length >= 10 ? (
                                <col style={{ width: width[9] }}></col>
                            ) : (
                                ''
                            )}
                        </colgroup>
                    ) : (
                        ''
                    )}
                    <StyledHeadDefault>
                        <TableRow>
                            {columnsFilter.map((column) => (
                                <StyledSmallCellDefault
                                    onClick={(e) => handleSortBy(e, column)}
                                    key={'head' + column}
                                    padding="normal"
                                    style={fonts.filterMenu}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {type === 'Alvos' &&
                                        (column === 'SMS' ||
                                            column === 'Email' ||
                                            column === 'Wpp' ||
                                            column === 'Telegram') ? (
                                            <PermissionsIcons column={column} />
                                        ) : column === 'Checkbox' ? (
                                            <CustomCheckboxHead
                                                column={column}
                                                allSelected={allSelected}
                                                handleClickCheckbox={
                                                    handleClickCheckbox
                                                }
                                            />
                                        ) : column != 'GT'&&(
                                            column
                                        )}
                                        <Arrows column={column} sortBy={sortBy} />
                                        
                                    </div>
                                </StyledSmallCellDefault>
                            ))}
                        </TableRow>
                    </StyledHeadDefault>
                    <StyledBodyDefault>
                        {paginatedDate?.length > 0 ? (
                            paginatedDate.map((content, indexRow) => {
                                return (
                                    <TableRow
                                        key={'body' + content.index}
                                        onClick={content.onClick}
                                        style={{backgroundColor:content.gt}}
                                    >
                                        {columnsFilter.map(
                                            (column, indexColumn) => (
                                                type=="Reenvio" && content[column].node? 
                                                content[column].node.props.className.split("-").includes("greenChipS")?
                                                <TableCell
                                                    key={column + content.index}
                                                    padding="normal"
                                                    style={{backgroundColor:"#67E16480"}}
                                                    className={classes.tableCell}
                                                >
                                                    {isObject(content[column]) ? (
                                                        Array.isArray(
                                                            content[column],
                                                        ) && content[column] &&
                                                        content[column][0] ===
                                                            'Button' ? (
                                                            <SummaryInfo
                                                                tableName={
                                                                    columnsFilter[
                                                                        indexColumn
                                                                    ]
                                                                }
                                                                index={indexRow}
                                                                data={
                                                                    content[
                                                                        column
                                                                    ][1]
                                                                }
                                                                columnName={
                                                                    paginatedDate
                                                                }
                                                                type={type}
                                                            />
                                                        ) : Array.isArray(
                                                              content[column].node,
                                                          ) ? (
                                                            <DaysOfWeek
                                                                content={
                                                                    content[column]
                                                                }
                                                            />
                                                            
                                                        ) : (
                                                            <div>
                                                                {
                                                                    content[column]
                                                                        .node


                                                                    }
                                                                </div>
                                                            )
                                                        ) : content[column] ===
                                                          'Checkbox' ? (
                                                            <CustomCheckboxBody
                                                                content={content}
                                                                selected={selected}
                                                                handleClickCheckbox={
                                                                    handleClickCheckbox
                                                                }
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >

                                                                {column!= "gt"? content[column] : ""}
                                                            </div>
                                                        )}
                                                    </TableCell>

                                                    :

                                                    content[column].node.props.className.split("-").includes("redChipS")?
                                                    <TableCell
                                                    key={column + content.index}
                                                    padding="normal"
                                                    style={{backgroundColor:"#FF000080"}}
                                                    className={classes.tableCell}
                                                    >
                                                    {isObject(content[column]) ? (
                                                        Array.isArray(
                                                            content[column],
                                                        ) && content[column] &&
                                                        content[column][0] ===
                                                            'Button' ? (
                                                            <SummaryInfo
                                                                tableName={
                                                                    columnsFilter[
                                                                        indexColumn
                                                                    ]
                                                                }
                                                                index={indexRow}
                                                                data={
                                                                    content[
                                                                        column
                                                                    ][1]
                                                                }
                                                                columnName={
                                                                    paginatedDate
                                                                }
                                                                type={type}
                                                            />
                                                        ) : Array.isArray(
                                                              content[column].node,
                                                          ) ? (
                                                            <DaysOfWeek
                                                                content={
                                                                    content[column]
                                                                }
                                                            />             
                                                        ) : (
                                                            <div>
                                                                {
                                                                    content[column]
                                                                        .node
                                                                }
                                                            </div>
                                                        )
                                                    ) : content[column] ===
                                                      'Checkbox' ? (
                                                        <CustomCheckboxBody
                                                            content={content}
                                                            selected={selected}
                                                            handleClickCheckbox={
                                                                handleClickCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textOverflow:
                                                                    'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >

                                                            {column!= "gt"? content[column] : ""}
                                                        </div>
                                                    )}
                                                </TableCell>

                                                    :

                                                    content[column].node.props.className.split("-").includes("yellowChipS")?
                                                    <TableCell
                                                    key={column + content.index}
                                                    padding="normal"
                                                    style={{backgroundColor:"#FFB703B2"}}
                                                    className={classes.tableCell}
                                                    >
                                                    {isObject(content[column]) ? (
                                                        Array.isArray(
                                                            content[column],
                                                        ) && content[column] &&
                                                        content[column][0] ===
                                                            'Button' ? (
                                                            <SummaryInfo
                                                                tableName={
                                                                    columnsFilter[
                                                                        indexColumn
                                                                    ]
                                                                }
                                                                index={indexRow}
                                                                data={
                                                                    content[
                                                                        column
                                                                    ][1]
                                                                }
                                                                columnName={
                                                                    paginatedDate
                                                                }
                                                                type={type}
                                                            />
                                                        ) : Array.isArray(
                                                              content[column].node,
                                                          ) ? (
                                                            <DaysOfWeek
                                                                content={
                                                                    content[column]
                                                                }
                                                            />             
                                                        ) : (
                                                            <div>
                                                                {
                                                                    content[column]
                                                                        .node
                                                                }
                                                            </div>
                                                        )
                                                    ) : content[column] ===
                                                      'Checkbox' ? (
                                                        <CustomCheckboxBody
                                                            content={content}
                                                            selected={selected}
                                                            handleClickCheckbox={
                                                                handleClickCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textOverflow:
                                                                    'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >

                                                            {column!= "gt"? content[column] : ""}
                                                        </div>
                                                    )}
                                                    </TableCell>

                                                    :   

                                                    <TableCell
                                                        key={column + content.index}
                                                        padding="normal"
                                                        style={fonts.cellContent}
                                                        className={classes.tableCell}
                                                    >
                                                    {isObject(content[column]) ? (
                                                        Array.isArray(
                                                            content[column],
                                                        ) && content[column] &&
                                                        content[column][0] ===
                                                            'Button' ? (
                                                            <SummaryInfo
                                                                tableName={
                                                                    columnsFilter[
                                                                        indexColumn
                                                                    ]
                                                                }
                                                                index={indexRow}
                                                                data={
                                                                    content[
                                                                        column
                                                                    ][1]
                                                                }
                                                                columnName={
                                                                    paginatedDate
                                                                }
                                                                type={type}
                                                            />
                                                        ) : Array.isArray(
                                                              content[column].node,
                                                          ) ? (
                                                            <DaysOfWeek
                                                                content={
                                                                    content[column]
                                                                }
                                                            />
                                                            
                                                        ) : (
                                                            <div>
                                                                {
                                                                    content[column]
                                                                        .node


                                                                }
                                                            </div>
                                                        )
                                                    ) : content[column] ===
                                                      'Checkbox' ? (
                                                        <CustomCheckboxBody
                                                            content={content}
                                                            selected={selected}
                                                            handleClickCheckbox={
                                                                handleClickCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textOverflow:
                                                                    'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >

                                                            {column!= "gt"? content[column] : ""}
                                                        </div>
                                                    )}
                                                </TableCell>
                                                    :
                                                    <TableCell
                                                    key={column + content.index}
                                                    padding="normal"
                                                    style={fonts.cellContent}
                                                    className={classes.tableCell}
                                                >
                                                    {isObject(content[column]) ? (
                                                        Array.isArray(
                                                            content[column],
                                                        ) && content[column] &&
                                                        content[column][0] ===
                                                            'Button' ? (
                                                            <SummaryInfo
                                                                tableName={
                                                                    columnsFilter[
                                                                        indexColumn
                                                                    ]
                                                                }
                                                                index={indexRow}
                                                                data={
                                                                    content[
                                                                        column
                                                                    ][1]
                                                                }
                                                                columnName={
                                                                    paginatedDate
                                                                }
                                                                type={type}
                                                            />
                                                        ) : Array.isArray(
                                                              content[column].node,
                                                          ) ? (
                                                            <DaysOfWeek
                                                                content={
                                                                    content[column]
                                                                }
                                                            />
                                                            
                                                        ) : (
                                                            <div>
                                                                {
                                                                    content[column]
                                                                        .node


                                                                }
                                                            </div>
                                                        )
                                                    ) : content[column] ===
                                                      'Checkbox' ? (
                                                        <CustomCheckboxBody
                                                            content={content}
                                                            selected={selected}
                                                            handleClickCheckbox={
                                                                handleClickCheckbox
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textOverflow:
                                                                    'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >

                                                            {column!= "gt"? content[column] : ""}
                                                        </div>
                                                    )}
                                                </TableCell>
                                                ),
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    {columnsFilter.map((column, indexColumn) => {
                                        let tableMiddle =
                                            Math.ceil(width.length / 2) - 1;
                                        return (
                                            <TableCell
                                                key={indexColumn}
                                                padding="normal"
                                                style={fonts.cellContent}
                                                className={
                                                    width.length % 2 === 0 &&
                                                    indexColumn === tableMiddle
                                                        ? classes.tableCellAlignRight
                                                        : classes.tableCell
                                                }
                                            >
                                                {indexColumn === tableMiddle && (
                                                    <span style={fonts.cellContent}>
                                                        N/A
                                                    </span>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            )}
                        </StyledBodyDefault>
                    </StyledTableDefault>
                    <Grid container alignItems="center">
                        <TableFooter
                            filteredData={filteredData}
                            pageSizeInput={pageSizeInput}
                            setPageSizeInput={setPageSizeInput}
                            setPagination={setPagination}
                            setLoadPagination={setLoadPagination}
                            changePage={changePage}
                            verifyChangePage={verifyChangePage}
                            pagination={pagination}
                        />  
                </Grid>
            </div>
        </ModalContextProvider>
    );  
};  

ComplexTable.propTypes = {
    columns: P.array.isRequired,
    data: P.arrayOf(P.object),
    dataSet: P.arrayOf(P.object),
    doReloadComponent: P.bool,
    reloadHistory: P.func,
    onClick: P.func,
    setResendUsers: P.func,
    setDateSearch: P.func,
    createButton: P.bool,
    dateTimeChoice: P.bool,
    buttonsWarnings: P.bool,
    historySelectButtons: P.bool,
    historyClientSelectButtons: P.bool,
    previsionButtons: P.bool,
    reportCard: P.bool,
    noOperation: P.bool,
    ticketsButtons: P.bool,
    buttonsPrevision: P.bool,
    type: P.string.isRequired,
    classes: P.object.isRequired,
    openCheckboxModal: P.bool,
    setOpenCheckboxModal: P.func,
    csvButton: P.bool,
    csvButtonName: P.string,
    otherCsvButton: P.bool,
    width: P.array.isRequired,
    otherCsvButtonName: P.string,
    otherDataSet: P.arrayOf(P.object),
};

ComplexTable.defaultProps = {
    data: [],
};

export default withStyles(styles)(ComplexTable);
