import React, { useContext, useState } from 'react';
import { ToolsContext } from 'Context/ToolsContext';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TooltipComponent from 'components/Modal/Tooltip.js';
import { useTarget } from 'Context/TargetContext';
import { Divider } from '@material-ui/core';
import { ImTarget } from 'react-icons/im'
import PropTypes from 'prop-types';
import {
    faDrawPolygon,
    faEraser,
    faHandPaper,
    faRulerHorizontal,
    faSatellite,
    faSatelliteDish,
    faBoltLightning,
    faBullseye,
    faRad
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    ExtraOptions,
    ExtraOptionsButton,
    Icon,
    MenuLineWrapper,
    HorizontalMenuLineWrapper,
    HorizontalExtraOptions,
    RightMenu,
} from './styled.js';
import {useDischarges} from 'Context/DischargesContext.js';
import {useSatellite} from 'Context/SatelliteContext.js';
import {useRadar} from 'Context/RadarContext.js';
import MaterialIcon from '@material-ui/core/Icon';
import {useMapContext} from 'Context/MapContext.js';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';
import { mapBuffersStateProvider } from 'redux/actions/mapBuffersState.js';
import { mapCamerasStateProvider } from 'redux/actions/mapCamerasState.js';
import { BsCameraFill } from 'react-icons/bs';

const useStyles = makeStyles(theme => ({
  divider: {
    background: "#000",
    width: "80%",
    height: "1px",
  },
}));

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      position="top-end"
      text={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}

const RightMapMenu = () => {
    const { showSatellite, loadingSatellite, toggleSatellite } = useSatellite();
    const {
      toolInUse,
      setToolInUse,
      setMeasureLine,
      setPointHelper,
    } = useContext(ToolsContext);
    const {
      descargas,
      showDischarges,
      loadingDescargas,
      toggleDischarges,
      dischargesToShow,
      toggleDischargeToShow,
      dischargesTypeToShow,
      toggleDischargeTypeToShow,
    } = useDischarges();

    const styles = useStyles();
    const { resetMapPosition } = useMapContext();
    const { showTargets, toggleTargets, mergeTargetsOption, toggleTargetMerge } = useTarget();
    const { showRadar, loadingRadar, toggleRadar, radarInUse, setRadarInUse } = useRadar();
    const buffersState = useSelector(state => state.mapBuffersStateProvider);
    const camerasState = useSelector(state => state.mapCamerasStateProvider);

    const dispatch = useDispatch();


    const toggleBufferState = () => {
      dispatch(mapBuffersStateProvider(!buffersState));
    }

    const toggleCamerasState = () => {
      dispatch(mapCamerasStateProvider(!camerasState));
    }

    return (
        <RightMenu>
          <OptionsTooltip title='Ferramenta de interação'>
            <Button
                onClick={() => {
                    setToolInUse('default');
                }}
                selected={toolInUse === 'default'}
            >
                <Icon icon={faHandPaper}  />
            </Button>
          </OptionsTooltip>
          <OptionsTooltip title={showSatellite ? 'Esconder imagens satélite' : 'Mostrar imagens satélite'}>
            <Button onClick={toggleSatellite} loading={loadingSatellite} selected={showSatellite}>
                <Icon icon={faSatellite} />
            </Button>
          </OptionsTooltip>
          <MenuLineWrapper>
            <OptionsTooltip title={showRadar ? 'Esconder imagens de radares' : 'Mostrar imagens de radares'}>
              <Button
                onClick={toggleRadar}
                loading={loadingRadar}
                selected={showRadar}
              >
                <Icon icon={faSatelliteDish}  />
              </Button>
            </OptionsTooltip>
            <ExtraOptions>
              <ExtraOptionsButton
                    selected={radarInUse === 0}
                    onClick={() => setRadarInUse(0)}
                  >
                    Max
              </ExtraOptionsButton>
              <ExtraOptionsButton
                    selected={radarInUse === 3}
                    onClick={() => setRadarInUse(3)}
                  >
                    3km
              </ExtraOptionsButton>
              <ExtraOptionsButton
                    selected={radarInUse === 5}
                    onClick={() => setRadarInUse(5)}
                  >
                    5km
              </ExtraOptionsButton>
              <ExtraOptionsButton
                    selected={radarInUse === 7}
                    onClick={() => setRadarInUse(7)}
                  >
                    7km
              </ExtraOptionsButton>
              <ExtraOptionsButton
                    selected={radarInUse === 10}
                    onClick={() => setRadarInUse(10)}
                  >
                    10km
              </ExtraOptionsButton>
            </ExtraOptions>
          </MenuLineWrapper>
          <MenuLineWrapper>
            <OptionsTooltip title={showDischarges ? 'Esconder descargas' : 'Mostrar descargas'}>
              <Button onClick={toggleDischarges} loading={loadingDescargas} selected={showDischarges}>
                <Icon icon={faBoltLightning} />
              </Button>
            </OptionsTooltip>
            <ExtraOptions>
              <ExtraOptionsButton selected={dischargesTypeToShow[0] || dischargesTypeToShow[1]}>
                Rede SA
                <HorizontalExtraOptions bottom={"12px"}>
                  <ExtraOptionsButton
                    selected={dischargesTypeToShow[0]}
                    onClick={() => toggleDischargeTypeToShow(0)}
                  >
                    Groups
                  </ExtraOptionsButton>
                  <ExtraOptionsButton
                    selected={dischargesTypeToShow[1]}
                    onClick={() => toggleDischargeTypeToShow(1)}
                  >
                    Flashes
                  </ExtraOptionsButton>
                </HorizontalExtraOptions>
              </ExtraOptionsButton>
              <ExtraOptionsButton disabled={true}>
                Rede SO
                <HorizontalExtraOptions bottom={"64px"}>
                  <ExtraOptionsButton
                    selected={dischargesTypeToShow[0]}
                    onClick={() => toggleDischargeTypeToShow(0)}
                    disabled={true}                   
                  >
                    IN
                  </ExtraOptionsButton>
                  <ExtraOptionsButton
                    selected={dischargesTypeToShow[1]}
                    onClick={() => toggleDischargeTypeToShow(1)}
                    disabled={true}
                  >
                    IS
                  </ExtraOptionsButton>
              </HorizontalExtraOptions>
              </ExtraOptionsButton>
              <Divider classes={{ root: styles.divider }}/>

              {dischargesToShow.map((showBool, index) => (
                <ExtraOptionsButton
                  key={index}
                  selected={showBool}
                  type={`Discharges${(index + 1) * 10}`}
                  onClick={() => toggleDischargeToShow(index)}
                >
                  {(index + 1) * 10} min
                </ExtraOptionsButton>
              ))}
            </ExtraOptions>
          </MenuLineWrapper>
            <MenuLineWrapper>
              <OptionsTooltip title={showTargets ? 'Esconder alvos' : 'Mostrar alvos'}>
                <Button onClick={toggleTargets} selected={showTargets}>                    
                    <ImTarget style={{width: "20px", height: "20px"}} />
                </Button>
              </OptionsTooltip>
              <ExtraOptions>
                <ExtraOptionsButton
                  selected={mergeTargetsOption}
                  onClick={toggleTargetMerge}
                >
                  <svg style={{width: '24px', height: '24px', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M640 640v170.666667a85.333333 85.333333 0 0 1-85.333333 85.333333H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333333v-341.333334a85.333333 85.333333 0 0 1 85.333333-85.333333h170.666667V213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h341.333334a85.333333 85.333333 0 0 1 85.333333 85.333333v341.333334a85.333333 85.333333 0 0 1-85.333333 85.333333h-170.666667z m-85.333333-85.333333h256V213.333333h-341.333334v256H213.333333v341.333334h341.333334v-256z" /></svg>
                </ExtraOptionsButton>
              </ExtraOptions>
            </MenuLineWrapper>
            <OptionsTooltip title={buffersState? 'Habilitar buffers personalizados' : 'Desabilitar buffers personalizados'}>
              <Button
                  onClick={() => {
                     toggleBufferState();
                  }}
                  selected={buffersState}
              >
                  <Icon icon={faBullseye} hovercolor="#F00" />
              </Button>
            </OptionsTooltip>
            <OptionsTooltip title={buffersState? 'Habilitar camada de câmeras' : 'Desabilitar camada de câmeras'}>
              <Button
                  onClick={() => {
                     toggleCamerasState();
                  }}
                  selected={camerasState}
              >
                  <BsCameraFill />
              </Button>
            </OptionsTooltip>
            <OptionsTooltip title={'Ferramenta de seleção'}>
              <Button
                  onClick={() => {
                      setToolInUse('selection');
                  }}
                  selected={toolInUse === 'selection'}
              >
                  <Icon
                      icon={faDrawPolygon}
                  />
              </Button>
            </OptionsTooltip>
            <OptionsTooltip title={'Ferramenta de medida'}>
              <Button
                  onClick={() => {
                      setToolInUse('measure');
                  }}
                  selected={toolInUse === 'measure'}
              >
                  <Icon
                      icon={faRulerHorizontal}
                  />
              </Button>
            </OptionsTooltip>
            <OptionsTooltip title={'Apagar medida no mapa'}>
              <Button
                  onClick={() => {
                      setPointHelper([]);
                      setMeasureLine([]);
                  }}
              >
                  <Icon icon={faEraser} hovercolor="#F00" />
              </Button>
            </OptionsTooltip>
        </RightMenu>
    );
};


OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

export default RightMapMenu;
