import {useState, useEffect, useRef} from 'react'

// Libs
import p from 'prop-types';
import { bbox } from '@turf/turf';

// Components
import {Column, Row, ItemContent} from 'components/Flex';
import {TargetItem, Span, ButtonsContainer} from '../PlansTab/styled';
import {Checkbox} from '@material-ui/core';
import {InfoDiv, Property} from '../styled';
import Collapsible from 'components/Collapsible';
import fontStyles from "styles/fonts-jss";
import TooltipComponent from 'components/Modal/Tooltip.js';
import PropTypes from 'prop-types';
// Icons
import {MdLocationSearching} from 'react-icons/md';
import {MdExpandMore} from 'react-icons/md';
// Hooks
import {useMapContext} from 'Context/MapContext';
import useToggle from 'hooks/useToggle';
import { useToast } from 'Context/ToastContext';
// Services
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      position="top-end"
      text={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}
  

const TargetInfo = ({company, plan, target, checkboxes, checked }) => {
  const [show, toggleShow] = useToggle(false);
  const {setGoTo} = useMapContext();
  const [targetData, setTargetData] = useState([]);
  const [status, setStatus] = useState(target.status);
  const [automaticAlert, setAutomaticAlert] = useState(target.alerta_automatico);
  const [activeStatus, setActiveStatus] = useState(target.ativos_ativo);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const openToast = useToast();
  const ref = useRef(null);
  const moment = require('moment');
  moment.locale('pt-br');
  const fonts = fontStyles();
  const [putData, setPutData] = useState({
    nome: target.nome,
    apelido: target.apelido,
    coordenadas: target.coordenadas,
    qntd_sms: target.qntd_sms,
    qntd_email: target.qntd_email,
    qntd_telegram: target.qntd_telegram,
    qntd_wpp: target.qntd_wpp,
    alerta_automatico: target.alerta_automatico,
    status: target.status,
    tipo: target.tipo,
    antecedencia: target.antecedencia,
    unidade_medida: target.unidade_medida,
    ativos: target.ativos,
    ativos_ativo: target.ativos_ativo,
    buffers_padrao: target.buffers_padrao,
    buffers: target.buffers,
    cor: target.cor,
  })

  const handleGoToTarget = (feature) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
    setGoTo([
      [minLng, minLat],
      [maxLng, maxLat]
    ]);
  }

  useEffect(() => {
    if(show && loadingTargetData === 0)  loadTargetData();
  }, [show, loadingTargetData])

  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const setServices = (data) => {
    var today = moment().format('dddd');
    today = today === "sábado"? "sabado" : today;
    var now = moment();
    var stringfyLightningAlert = data? data.filter((alert) => alert.tipo === "Alerta Raio" && alert.status === true) : [];
    var parsedLightningAlert = [];
    stringfyLightningAlert?.length > 0? stringfyLightningAlert.map((alert) => parsedLightningAlert.push({id_service : alert.id_servico, monitoring: alert.monitoramento})) : "";
    parsedLightningAlert?.length > 0?  parsedLightningAlert.map((alert) => {
      alert.monitoring.map((days, index) => {
        var aux = [];
        if(typeof days[0] === "string"){
          aux.push(JSON.parse(days))
          alert.monitoring[index] = aux;
        }
      });
    }) : "";

    var test = parsedLightningAlert?.length > 0? parsedLightningAlert.map((alert) => 
      alert.monitoring.some((days) => 
        days[0].datafunc_dias.includes((today[0].toUpperCase() + today.slice(1, 3) + ", ")) && 
        now.isBetween(
          moment(days[0].datafunc_hora_inicio, 'hh:mm:ss'),
          moment(days[0].datafunc_hora_fim, 'hh:mm:ss')
        )
      )
    ) : [];

    var lightningAlert = test.length > 0 && test.includes(true)? data.filter((alert) => alert.id_servico === parsedLightningAlert[test.indexOf(true)].id_service) : "";

    const setDays = (data) => {
      var output = "";

      for(let i = 0; i < data.length; i++) {
        if(i === data.length - 1 && i != 0){
          output = output + data[i].slice(0, 3);
        }
        else if(i != data.length - 1){
          output = output + data[i];
        }
        else{
          output = data[i].slice(0, 3);
        }
      }
      return output;
    }

    if(data && data[0]){
      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {lightningAlert != ""?
            lightningAlert[0].monitoramento.map((days, index) =>  
              <div key={index} style={{display: "flex", flexDirection: "column"}}>
                <span style={fonts.mapSidebarTargetDescripitionContent}>
                  {`${setDays(days[0].datafunc_dias)} - ${days[0].datafunc_hora_inicio} às ${days[0].datafunc_hora_fim}`}
                </span>
              </div>
            ) :
            parsedLightningAlert.length > 0?
              parsedLightningAlert[0].monitoring.map((days, index) =>
                <div key={index} style={{display: "flex", flexDirection: "column"}}>
                  <span className='overflowText' style={fonts.mapSidebarTargetDescripitionContent}>
                    {`${setDays(days[0].datafunc_dias)} - ${days[0].datafunc_hora_inicio} às ${days[0].datafunc_hora_fim}`}
                  </span>
                </div>
              ) :
                <span style={fonts.mapSidebarTargetDescripitionContent}>
                  Não contemplado
                </span>
          }
        </div>
      return output;
    }
    else{
      return  <span style={fonts.mapSidebarTargetDescripitionContent}>
                Não contemplado
              </span>
    }
  }


  return (
    <Collapsible
      key={`sidebaralvo-${target.id}`}
      isOpen={show}
      main={(
        <TargetItem ml='15px' checked={checked} ref={ref}>
          <Checkbox
            label={target.nome}
            onChange={() => checkboxes.toggle(company.id, plan.id, target.id)}
            checked={checkboxes.get(company.id, plan.id, target.id)}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent>
            <OptionsTooltip title={target.nome}>
              <Span>
                {target.nome}
              </Span>
            </OptionsTooltip>
            <ButtonsContainer>
              <MdLocationSearching style={{ fontSize: "20px"}} onClick={() => handleGoToTarget(target.coordenadas)} />
              <Row isOpen={show}>
                {show?
                  <MdExpandMore style={{fontSize: '28px'}} onClick={toggleShow}/> :
                  <MdExpandMore style={{fontSize: '28px', transform: 'rotateZ(-90deg)', transition: "0.3s"}} onClick={toggleShow}/> 
                }
              </Row>
            </ButtonsContainer>
          </ItemContent>
        </TargetItem>
      )}
    >
      <Column align='flex-start' margin='0px 0px 0px 32px'>
        {loadingTargetData === 0?
          <span style={fonts.mapSidebarTargetDescripitionItem}>Carregando</span> :
            loadingTargetData === 2?
              <span style={fonts.mapSidebarTargetDescripitionItem}>Problema ao carregar informações, recarregue a página e tente novamente!</span> :
                (
                  <InfoDiv checked={checked}>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Início monitoramento: </span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{targetData.plano[0].inicio_contrato.split('-').reverse().join('/')}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Feriados: </span>
                      <div><span style={fonts.mapSidebarTargetDescripitionContent}>{targetData.plano[0].feriados? "Sim" : "Não"}</span></div>
                    </Property>
                    <Property>
                      <span style={fonts.mapSidebarTargetDescripitionItem}>Funcionamento: </span>
                      <div>{setServices(targetData.servicos)}</div>
                    </Property>
                  </InfoDiv>
                )
        }
      </Column>
    </Collapsible>
  )
}

OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

TargetInfo.propTypes = {
  target: p.any,
  plan: p.any,
  company: p.any,
  checkboxes: p.any,
  checked: p.bool,
  mapRef: p.any,
}
export default TargetInfo
