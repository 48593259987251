import React, { useContext, memo } from 'react';
import {useTarget} from 'Context/TargetContext';
import {IntersectionLayer} from './IntersectionLayer';
import {useTargetState} from 'Context/TargetStateContext';
import {Target} from './Target';
import moment from 'moment'
import useLoad from 'hooks/useLoad'
import { getHolidays } from "services/adminDashboard/holidays";

const TargetLayerComponent = () => {
    const { planos, toShow, showTargets, mergeTargetsOption} = useTarget();
    const { targetState } = useTargetState();
    const { data } = useLoad(() => getHolidays(moment().format("YYYY")), [])
    if (!showTargets) return <></>;
    if (planos === undefined || !planos || planos.length === 0) return <></>;
    
    const filterHolidays = () => {
      const today = moment().format('YYYY-MM-DD')
      return [...data].filter(holiday => holiday.date === today).length
    }

    const getFunc = (time, data, check) => {
      const mapDays = [
        'Dom',
        'Seg',
        'Ter',
        'Qua',
        'Qui',
        'Sex',
        'Sab',
      ]

      const dayOfWeek = Number(time.format('d'));
      var result;

      if(check === 'Plan'){
        data?.some((mon) => {
          if(!mon[0].includes(mapDays[dayOfWeek])) return false;
          result = time.isBetween(
            moment(mon[0].slice(mon[0].indexOf(`_inicio":"`) + 10, mon[0].indexOf(`","datafunc`)), 'hh:mm:ss'),
            moment(mon[0].slice(mon[0].indexOf(`hora_fim`) + 11, mon[0].indexOf(`}"`) -1), 'hh:mm:ss')
          )
        })
        
        result = result === true? result : false;
      }
      /*else if(check === true){
        console.log(data)
        result = data.some((service) => {
          if(!service.monitoramento.includes(mapDays[dayOfWeek])) return false;
          return time.isBetween(
            moment(service.monitoramento.slice(service.monitoramento.indexOf(`_inicio":"`) + 10, service.monitoramento.indexOf(`","datafunc`)), 'hh:mm:ss'),
            moment(service.monitoramento.slice(service.monitoramento.indexOf(`hora_fim`) + 11, service.monitoramento.indexOf(`}"`) -1), 'hh:mm:ss')
          ) ?
            service.hora_extra?
              time.isBetween(moment(service.datafunc_dia_inicio), moment(service.datafunc_dia_fim))
                : true
          : false
       })
       result = result != undefined ? result : false;
       result = true;
      }*/
      return result;
    }

    const isHoliday = filterHolidays();
    return (
      <>
        <IntersectionLayer />
          {planos?.map(company => company.planos.map((plan) => {
            const onTimePlan = !plan.feriados && isHoliday > 0 ? false : getFunc(moment(), plan.monitoramento, "Plan");
            return plan.alvos.map((target) => {
              if(!toShow.get(company.id, plan.id, target.id)) return null
                if(mergeTargetsOption) return null
                return (
                  <div key={target.id}>
                    <Target target={target} targetState={targetState[target.id_alvo]} onTime={onTimePlan} />
                  </div>
                )
              }
            )}
            ))}
      </>
    )
};

export const TargetLayer = memo(TargetLayerComponent);
