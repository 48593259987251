import React, { useContext, memo } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { buffer, featureCollection, union } from '@turf/turf';
import { useSelector } from 'react-redux';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';


const IntersectionLayerComponent = () => {
  const { clientTargets, mergeTargetsOption, showTargets, toShow} = useClientTargetsState();
  const buffersState = useSelector(state => state.mapBuffersStateProvider);
  if (clientTargets === undefined || !clientTargets || clientTargets.length === 0) return <></>;

  const mergeTargets = (feature, location2) => {
    const a = {...feature}
    const x =union(a, location2)
    x.properties = {
      nome: `${feature.properties.nome}/${location2.properties.nome}`,
      buffer: feature.properties.buffer,
      targetId: feature.properties.targetId,
      planId: feature.properties.planId,
    }
    return x
  }
 
  const processTargets = () => {
    const mergedTargets = []
    let i = 0;
    let j
    let merged = {};
    let actualMerge;
    let mergingIndexes = {};
    const clientTarget = [];
    clientTargets[0]?.planos.map((plano) => plano.alvos.map((alvo) => {
      if(toShow.get(clientTargets[0].id, plano.id, alvo.id)){
        clientTarget.push(alvo)
      }
    }))

    for(i; i < clientTarget.length; i++){
      if (merged[i]) continue
      j = i + 1
      mergingIndexes = {}
      actualMerge = clientTarget[i].coordenadas.features

      for(j; j < clientTarget.length; j++){
        if (merged[j]) continue
        actualMerge = actualMerge.map((layer, index) => {
          if (index === 0 || !mergeTargetsOption) return layer;
          const x = mergeTargets(layer, clientTarget[j].coordenadas.features[index])
          if(x){
            mergingIndexes[i] = true
            mergingIndexes[j] = true
            return x
          }
          return layer
        })
      }
      mergedTargets.push(actualMerge)
      Object.keys(mergingIndexes).forEach(a =>
        merged[a] = true
        )
      }
      const result = mergedTargets.reduce((sum, target, key) => {
      return [...sum, ...target]
    }, [])


    return { type: 'FeatureCollection', features: result }
  }

  const processedTargets = processTargets()
  
  return (
    <Source
      key={`nonInteractiveLayer`}
      id={`nonInteractiveLayer`}
      type="geojson"
      data={processedTargets}
      generateId={true}
      >
      <Layer
          id={`nonInteractiveLayer`}
          source={`nonInteractiveLayer`}
          type="line"
          layout={{
              visibility: !showTargets
                ? 'none'
                : 'visible',
          }}
          paint={{
            'line-color': [
              'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#FF0',
                !buffersState?
                  [
                    
                      'match',
                      ['get', 'buffer'],
                      '0',
                      '#808080',
                      '1',
                      '#223b53',
                      '4',
                      '#FB1C29',
                      '10',
                      '#FDA731',
                      '20',
                      '#118014',
                      'rgba(255, 0, 0, 0)',
                  //] 
                  ] :
                  [
                      'match',
                      ['get', 'buffer'],
                      '0',
                      '#808080',
                      '1',
                      '#223b53',
                      '4',
                      '#FB1C29',
                      '10',
                      '#FDA731',
                      '20',
                      '#118014',
                      ['get', 'color'],
                  ] 
            ],
            'line-width': 1,
        }}
      />
    </Source>
  )
}

export const IntersectionLayer = memo(IntersectionLayerComponent);


/*'match',
                    ['get', 'bufferCheck'],
                    'checked',
                    'rgba(255, 0, 0, 0)',
                    [*/