import React, {memo} from 'react'
import P from 'prop-types';
import {Layer, Source} from 'react-map-gl';
import { useSelector } from 'react-redux';

const TargetComponent = ({target, targetState, onTime}) => {
  const buffersState = useSelector(state => state.mapBuffersStateProvider);
  return (
    <>
    {target.coordenadas.features[0].geometry.type === "MultiPoint"?
      <Source
        key={`${target.nome}-${target.id_alvo}`}
        id={`${target.nome}-${target.id_alvo}`}
        type="geojson"
        data={target.coordenadas.features[0]}
        generateId={true}
        >
        <Layer
          id={`${target.nome}-${target.id_alvo}`}
          source={`${target.nome}-${target.id_alvo}`}
          type="circle"
          layout={{
            visibility: 'visible',
            // visibility: !showTargets
              // ? 'none'
              // : 'visible',
          }}
          paint={{
            'circle-color': '#808080',
            'circle-radius': {
              stops: [[5,2],[12,10], [16,30]]
            },
          }}
        />
      </Source> :
      <Source
        key={`${target.nome}-${target.id_alvo}`}
        id={`${target.nome}-${target.id_alvo}`}
        type="geojson"
        data={target.coordenadas}
        generateId={true}
        >
        <Layer
            id={`${target.nome}-${target.id_alvo}`}
            source={`${target.nome}-${target.id_alvo}`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
                'line-color': [
                  'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#08F',
                    targetState
                    ?
                      buffersState?
                        [
                          'match',
                          ['get', 'buffer'],
                          '0',
                          '#808080',
                          '1',
                          '#223b53',
                          '4',
                          '#B067E9',
                          '10',
                          'rgba(0,0,0,0)',
                          '20',
                          'rgba(0,0,0,0)',
                          ['get', 'color'],
                        ] :
                        [ 
                        
                          'match',
                          ['get', 'buffer'],
                          '0',
                          '#808080',
                          '1',
                          '#223b53',
                          '4',
                          '#B067E9',
                          '10',
                          '#FDA731',
                          '20',
                          '#118014',
                          'rgba(255, 0, 0, 0)',
                      ]
                    
                    : !onTime
                      ?
                      '#999'
                      :
                      buffersState?
                        [
                          'match',
                          ['get', 'buffer'],
                          '0',
                          '#808080',
                          '1',
                          '#223b53',
                          '4',
                          '#FB1C29',
                          '10',
                          'rgba(0,0,0,0)',
                          '20',
                          'rgba(0,0,0,0)',
                          ['get', 'color'],
                        ] :
                        [ 
                        
                          'match',
                          ['get', 'buffer'],
                          '0',
                          '#808080',
                          '1',
                          '#223b53',
                          '4',
                          '#FB1C29',
                          '10',
                          '#FDA731',
                          '20',
                          '#118014',
                          'rgba(255, 0, 0, 0)',
                        ] 
                ],
                'line-width': 1,
            }}
        />
    </Source>
    }
    {target.coordenadas.features[0].geometry.type === "Point" && 
      (
        <Source
          key={`${target.nome}-${target.id_alvo}-points`}
          id={`${target.nome}-${target.id_alvo}-points`}
          type="geojson"
          data={target.coordenadas.features[0]}
          generateId={true}
          >
          <Layer
            id={`${target.nome}-${target.id_alvo}-points`}
            source={`${target.nome}-${target.id_alvo}`}
            type="circle"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
              'circle-color': '#808080',
              'circle-radius': {
                stops: [[5,2],[12,10], [16,30]]
              },
            }}
          />
        </Source>
      )
    }
  </>
)}

TargetComponent.propTypes = {
  target: P.any.isRequired,
  targetState: P.any,
  onTime: P.bool.isRequired,
}

export const Target = memo(TargetComponent);
